export const Districts = [
  { _id: 1, name: 'Aveiro' },
  { _id: 2, name: 'Beja' },
  { _id: 3, name: 'Braga' },
  { _id: 4, name: 'Bragança' },
  { _id: 5, name: 'Castelo Branco' },
  { _id: 6, name: 'Coimbra' },
  { _id: 7, name: 'Évora' },
  { _id: 8, name: 'Faro' },
  { _id: 9, name: 'Guarda' },
  { _id: 10, name: 'Leiria' },
  { _id: 11, name: 'Lisboa' },
  { _id: 12, name: 'Portalegre' },
  { _id: 13, name: 'Porto' },
  { _id: 14, name: 'Santarém' },
  { _id: 15, name: 'Setúbal' },
  { _id: 16, name: 'Viana do Castelo' },
  { _id: 17, name: 'Vila Real' },
  { _id: 18, name: 'Viseu' },
  { _id: 19, name: 'Região Autónoma da Madeira' },
  { _id: 20, name: 'Região Autónoma dos Açores' }
];

export const getDistrict = (value) => {
  if(Number.isInteger(value) && value > 0 && Districts[value - 1]) return Districts[value - 1].name;
  return null;
};