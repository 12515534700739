export const UserTypes = [
  { _id: 1, name: 'Administrador' },
  { _id: 2, name: 'Aplicador' },
  { _id: 3, name: 'Encarregado de Educação' },
  { _id: 4, name: 'Educando' },
  { _id: 5, name: 'Visitante' }
];

export const getUserType = (value) => {
  if(Number.isInteger(value) && value > 0 && UserTypes[value - 1]) return UserTypes[value - 1].name;
  return null;
};