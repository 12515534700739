import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetClass,
  UpdateClass,
  CreateClass,
  GetSchoolsList
} from '../../infra/requests/SchoolsRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  TabsContainer, 
  Tab
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/generic/inputs/TextInput';
import SelectInput from '../../components/generic/inputs/SelectInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";
import ApplicatorsList from './components/ApplicatorsList';
import StudentsList from './components/StudentsList';

const validations = FormValidator.make({
  name: 'required',
  school: 'required'
});

class ManageClassesPage extends Component {
  state = { 
    isNew: false, 
    loading: true,
    schools: [],
    defaultTab: 'details',
    classInfo: undefined
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu,
      user,
      history
    } = this.props;

    if(user.type === 2) {
      return history.push('/');
    }

    CloseMenu();

    const schools = await GetSchoolsList();

    if(params.id) {
      const result = await GetClass(params.id);
      dispatch(
        initialize('manage_classes_form', {
          ...result.data,
        })
      );

      let defaultTab = 'details';
      if(history?.location?.search?.includes('applicators=true')) {
        defaultTab = 'applicators';
        history.replace(`/classes/${params.id}`);
      } 
      if(history?.location?.search?.includes('students=true')) {
        defaultTab = 'students';
        history.replace(`/classes/${params.id}`);
      } 

      this.setState({ 
        loading: false,
        schools: schools?.data || [],
        defaultTab,
        classInfo: result?.data
      });
    } 
    else {
      this.setState({
        isNew: true,
        loading: false,
        schools: schools?.data || []
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;

      const payload = FlattenToFormData(values);
      const { success } = params.id ? await UpdateClass(params.id, payload) : await CreateClass(payload);

      if (success) return history.push('/classes');
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/classes');
  };

  renderDetails = () => {
    const { handleSubmit } = this.props;
    const { schools } = this.state;

    return (
      <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
        <Row gutter={24}>
          <Col xs={12} offset={6}>
            <Field
              component={TextInput}
              name='name'
              type='text'
              label={'Nome *'}
              placeholder={'Nome'}
            />
            <Field
              component={SelectInput}
              name={'school'}
              label={'Escola *'}
              placeholder={'Escolha a escola'}
              type='select'
              data={schools}
              dataKey={'_id'}
              dataLabel={'name'}
            />
          </Col>
        </Row>
      </BaseForm>
    );
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { isNew, loading, defaultTab, classInfo } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Turmas', title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          {
            isNew ?
            this.renderDetails()
            :
            <TabsContainer defaultActiveKey={defaultTab}>
              <Tab
                tab='Detalhes'
                key='details'
              >
                { this.renderDetails() }
              </Tab>
              <Tab
                tab='Aplicadores'
                key='applicators'
              >
                <ApplicatorsList classID={classInfo?._id}/>
              </Tab>
              <Tab
                tab='Alunos'
                key='students'
              >
                <StudentsList classID={classInfo?._id}/>
              </Tab>
            </TabsContainer>
          }
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageClassesPage = reduxForm({
  form: 'manage_classes_form',
  validate: validations,
})(ManageClassesPage);

const mapStateToProps = (state) => ({
  user: state.user
});


const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageClassesPage));