import React, { Component } from 'react';
import { Popconfirm, Icon } from 'antd';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton,
} from '../../styles/BasicStyles';
import {
  GetNews,
  DeleteNews,
  ToggleNews,
  ToggleFeaturedNews
} from '../../infra/requests/NewsRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import NewsFilters from './NewsFilters';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import { connect } from 'react-redux';
import moment from 'moment';

class NewsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Data',
        dataIndex: 'date',
        render: (value) => moment.utc(value).format('DD-MM-YYYY'),
        width: '15%',
      },
      {
        title: 'Título',
        dataIndex: 'title',
        render: (value) => TranslateValue(value),
        width: '50%',
      },
      {
        title: 'Ações',
        render: (data) => (
          <React.Fragment>
            <TableButton onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement='topRight'
                title={
                  data.featured
                    ? 'Quer remover o destaque nesta Notícia?'
                    : 'Quer destacar esta Notícia?'
                }
                onConfirm={() => this.toggleFeatured(data)}
              >
                <Icon type={data.featured ? 'close-circle' : 'check-circle'} />
                {data.featured ? ' Não destacar' : ' Destacar'}
              </Popconfirm>
            </TableButton>
            <TableButton onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement='topRight'
                title={`Quer ${
                  data.active ? 'desativar' : 'publicar'
                } esta Notícia?`}
                onConfirm={() => this.toggleActive(data)}
              >
                <Icon type={data.active ? 'close-circle' : 'check-circle'} />
                {data.active ? ' Desativar' : ' Publicar'}
              </Popconfirm>
            </TableButton>
            <TableButton error onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement='topRight'
                title='Tem a certeza que quer remover a Notícia?'
                onConfirm={() => this.removeNews(data)}
              >
                <Icon style={{ marginRight: 10 }} type='delete' />
                {'Remover'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        ),
      },
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    const { user, history } = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 

    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/website/news/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  removeNews = async (data) => {
    try {
      await DeleteNews(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetNews(currentPage, pageSize, filters);

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  toggleActive = async (record) => {
    this.setState({ loading: true });
    await ToggleNews(record._id, { active: !record.active });
    await this.updateDataTable();
  };

  toggleFeatured = async (record) => {
    this.setState({ loading: true });
    await ToggleFeaturedNews(record._id, { featured: !record.featured });
    await this.updateDataTable();
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize
    } = this.state;
    const { history } = this.props;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Lista de Notícias</PageTitle>
            {`Notícias`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/website/news/add')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <NewsFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Notícias inseridas!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(NewsPage);
