import React, { Component } from 'react';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  TableButton,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import {
  GetForms,
  RemoveForm
} from '../../infra/requests/FormsRequests';
import { connect } from 'react-redux';
import { getFormUserType } from '../../infra/utils/FormUserTypeTypes';
import { Icon, Popconfirm } from 'antd';
import BaseButton from '../../components/generic/buttons/BaseButton';

class FormsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    const { user } = this.props;
    await this.updateDataTable();

    if(user.type===2) {
      this.setState({
        columns: [
          {
            title: 'Questionário',
            dataIndex: 'name'
          },
          {
            title: 'Tipo de Utilizador',
            dataIndex: 'user_type',
            render: (value) => getFormUserType(value),
            width: '30%'
          }
        ]
      })
    } else {
      this.setState({
        columns: [
          {
            title: 'Questionário',
            dataIndex: 'name'
          },
          {
            title: 'Tipo de Utilizador',
            dataIndex: 'user_type',
            render: (value) => getFormUserType(value),
            width: '30%'
          },
          {
            title: '',
            render: (data) => (
              <div>
                {data?.removable && (
                  <React.Fragment>
                    <TableButton error onClick={(e) => e.stopPropagation()}>
                      <Popconfirm
                        placement='topRight'
                        title='Tem a certeza que quer remover o questionário?'
                        onConfirm={() => this.removeForm(data)}
                      >
                        <Icon style={{ marginRight: 10 }} type='delete' />
                        {'Remover'}
                      </Popconfirm>
                    </TableButton>
                  </React.Fragment>
                )}
              </div>
            ),
            width: '20%'
          },
        ]
      })
    }
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/forms/${record._id}`);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const result = await GetForms();

      this.setState({
        rows: result.data || [],
        total: result.data?.length || 0,
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  removeForm = async (data) => {
    try {
      await RemoveForm(data._id);
      await this.updateDataTable();
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize
    } = this.state;
    const { history, user } = this.props;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Lista de Questionários</PageTitle>
            {`Questionários`}
          </HeaderTitle>
          {user.type!==2 && (
            <HeaderButtonsContainer buttons={2}>
              <BaseButton
                type={'primary'}
                icon={'plus'}
                text={'Criar'}
                onClick={() => history.push('/forms/add')}
              />
              <BaseButton
                type={'default'}
                icon={'sync'}
                text={'Atualizar'}
                onClick={() => this.updateDataTable()}
              />
            </HeaderButtonsContainer>
          )}
        </HeaderContainer>
        <PageContainer>
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Questionários inseridos!'}
            total={total}
            rowKey={'_id'}
            hasPagination={false}
            onPressRow={this.handleOnPressRow}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, null)(FormsPage);