import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { Row, Col } from 'antd';
import { GetUser } from '../../infra/requests/UsersRequests';
import { GetClass, GetSchool } from '../../infra/requests/SchoolsRequests';
import {
  PointsContainer,
  Points,
  TextTitle,
  Image
} from './UserStyles';
import { FormContainer, SpinLoading, Separator, TabsContainer, Tab } from '../../styles/BasicStyles';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import moment from 'moment';
import { getGenderType } from '../../infra/utils/GenderTypes';
import Crown_0 from '../../assets/crowns/0.svg';
import Crown_1 from '../../assets/crowns/1.svg';
import Crown_2 from '../../assets/crowns/2.svg';
import Crown_3 from '../../assets/crowns/3.svg';
import Crown_4 from '../../assets/crowns/4.svg';
import Crown_5 from '../../assets/crowns/5.svg';
import Crown_6 from '../../assets/crowns/6.svg';
import Crown_7 from '../../assets/crowns/7.svg';
import Crown_8 from '../../assets/crowns/8.svg';
import Crown_9 from '../../assets/crowns/9.svg';
import Crown_10 from '../../assets/crowns/10.svg';
import Crown_11 from '../../assets/crowns/11.svg';
import Crown_12 from '../../assets/crowns/12.svg';
import FormsList from './components/FormsList';

const chooseIcon = (crown) => {
  if(crown === 0) return Crown_0;
  if(crown === 1) return Crown_1;
  if(crown === 2) return Crown_2;
  if(crown === 3) return Crown_3;
  if(crown === 4) return Crown_4;
  if(crown === 5) return Crown_5;
  if(crown === 6) return Crown_6;
  if(crown === 7) return Crown_7;
  if(crown === 8) return Crown_8;
  if(crown === 9) return Crown_9;
  if(crown === 10) return Crown_10;
  if(crown === 11) return Crown_11;
  if(crown === 12) return Crown_12;
  return '';
};


class ManageApplicatorParentsPage extends Component {
  state = {
    loading: true,
    userInfo: undefined,
    school: undefined,
    school_class: undefined,
    sessionsList: [],
    defaultTab: 'details'
  };

  componentDidMount = async () => {
    const {
      match: { params },
      CloseMenu,
      user,
      history
    } = this.props;

    CloseMenu();

    const result = await GetUser(params.id);
    
    // const resultSessions = await GetProgram('5fe1d835dc0ba87334bb1710');

    if(user.type === 2 && !result?.data?.valid) {
      return history.push('/users/parents');
    } else if (result?.data?.type === 4) {
      return history.push(`/users/students/${params.id}`);
    } else {
      // const school = await GetSchool(result?.data?.school);
      // const school_class = await GetClass(result?.data?.class);
      
      let defaultTab = 'details';

      this.setState({ 
        loading: false,
        defaultTab,
        userInfo: result?.data,
        // school: school?.data,
        // sessionsList: resultSessions?.data?.sessions || [],
        // school_class: school_class?.data
      });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/users/parents');
  };

  renderDetails = (userInfo) => {

    return(
      <Row gutter={24} style={{ marginTop: '10px' }}>
        <Col xs={12}>
          <TextTitle>Nome: <span>{userInfo?.name}</span></TextTitle>
        </Col>
        <Col xs={12}>
          <TextTitle>Email: <span>{userInfo?.email}</span></TextTitle>
        </Col>
        <Col xs={12}>
          <TextTitle>Género: <span>{getGenderType(userInfo?.gender)}</span></TextTitle>
        </Col>
        <Col xs={12}>
          <TextTitle>Data de Nascimento: <span>{moment(userInfo?.birthday).format('DD-MM-YYYY')}</span></TextTitle>
        </Col>
        {/* <Col xs={12}>
          <TextTitle>Escola: <span>{school?.name}</span></TextTitle>
        </Col>
        <Col xs={12}>
          <TextTitle>Turma: <span>{school_class?.name}</span></TextTitle>
        </Col> */}
        <Col xs={12}>
          <TextTitle>Data de Início do Programa: <span>{moment(userInfo?.program_start).format('DD-MM-YYYY')}</span></TextTitle>
        </Col>
      </Row>
    )
  }

  render() {
    const { loading, userInfo, defaultTab } = this.state;

    if (loading) return <SpinLoading />;
    const title = 'Informações';

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Enc. Educação', title]}
          buttons={[
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
            {
              userInfo &&
              <React.Fragment>
                <Row style={{display: 'flex', alignItems: 'center'}}>
                  <Col xs={8}>
                    <PointsContainer>
                      <Points>Pontos: <span>{userInfo?.points}</span></Points>
                    </PointsContainer>
                  </Col>
                  <Col xs={8}>
                    <PointsContainer>
                      <Points>Total de Coroas: <span>{userInfo?.totalCrowns}</span></Points>
                    </PointsContainer>
                  </Col>
                  <Col xs={8}>
                    <PointsContainer style={{display: 'flex', alignItems: 'center'}}>
                      <Points>Coroa Atual:</Points>
                      <Image src={chooseIcon(userInfo?.crown)} alt='icon'/>
                    </PointsContainer>
                  </Col>
                </Row>
                <Separator/>
                <TabsContainer defaultActiveKey={defaultTab}>
                  <Tab
                    tab='Detalhes'
                    key='details'
                  >
                    {this.renderDetails(userInfo)}
                  </Tab>
                  {/* <Tab
                    tab='Enc. de Educação'
                    key='parents'
                  >
                    <ParentsList applicator userID={userInfo?._id} />
                  </Tab>
                  <Tab
                    tab='Programa'
                    key='program'
                  >
                    <SessionsList applicator userId={userInfo?._id} list={sessionsList} />
                  </Tab> */}
                  <Tab
                    tab='Questionários'
                    key='forms'
                  >
                    <FormsList userID={userInfo?._id} />
                  </Tab>
                </TabsContainer> 
              </React.Fragment>
            }
        </FormContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageApplicatorParentsPage));