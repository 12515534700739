import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetVideo,
  UpdateVideo,
  CreateVideo
} from '../../infra/requests/VideosRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  Separator
} from '../../styles/BasicStyles';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/generic/inputs/TextInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";
import FilesInput from '../../components/generic/inputs/FilesInput';

class ManageVideosPage extends Component {
  state = {
    isNew: false,
    loading: true
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu,
      user,
      history
    } = this.props;

    if(user.type !== 1) return history.push('/');

    CloseMenu();

    if(params.id) {
      const result = await GetVideo(params.id);

      dispatch(
        initialize('manage_videos_form', {
          ...result.data,
        })
      );

      this.setState({ 
        loading: false
      });
    }
    else {
      this.setState({
        isNew: true,
        loading: false
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const { match: { params }, history } = this.props;

      const payload = FlattenToFormData(values);
      const { success } = params.id ? await UpdateVideo(params.id, payload) : await CreateVideo(payload);

      if(success) return history.push('/videos');
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/videos');
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { isNew, loading } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Vídeos', title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel
            }
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24}>
              <Col xs={24} md={8} offset={0}>
                <Field
                  component={TextInput}
                  name='name'
                  type='text'
                  label={'Nome'}
                  placeholder={'Nome'}
                  disabled={isNew ? false : true}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} md={8} offset={0}>
                <Field
                  component={TextInput}
                  name='session'
                  type='text'
                  label={'Sessão'}
                  placeholder={'Sessão'}
                  disabled={isNew ? false : true}
                />
              </Col>
              <Col xs={24} md={8} offset={0}>
                <Field
                  component={TextInput}
                  name='chapter'
                  type='text'
                  label={'Capítulo'}
                  placeholder={'Capítulo'}
                  disabled={isNew ? false : true}
                />
              </Col>
              <Col xs={24} md={8} offset={0}>
                <Field
                  component={TextInput}
                  name='step'
                  type='text'
                  label={'Cena'}
                  placeholder={'Cena'}
                  disabled={isNew ? false : true}
                />
              </Col>
            </Row>
            <Separator/>
            <Row gutter={24}>
              <Col xs={24} md={12} offset={0}>
                <Field
                  component={FilesInput}
                  name='file_pt'
                  label='Ficheiro Vídeo PT *'
                  accept={'video/mp4'}
                  acceptText='Formato(s) permitido(s): .mp4'
                  maxSize={100000000}
                />
              </Col>
              <Col xs={24} md={12} offset={0}>
                <Field
                  component={FilesInput}
                  name='file_en'
                  label='Ficheiro Vídeo EN'
                  accept={'video/mp4'}
                  acceptText='Formato(s) permitido(s): .mp4'
                  maxSize={100000000}
                />
              </Col>
            </Row>
            <Separator/>
            <Row gutter={24}>
              <Col xs={24} md={12} offset={0}>
                <Field
                  component={FilesInput}
                  name='subtitles_pt'
                  label='Legendas Vídeo PT *'
                  acceptText='Formato(s) permitido(s): .vtt'
                  maxSize={100000000}
                />
              </Col>
              <Col xs={24} md={12} offset={0}>
                <Field
                  component={FilesInput}
                  name='subtitles_en'
                  label='Legendas Vídeo EN'
                  acceptText='Formato(s) permitido(s): .vtt'
                  maxSize={100000000}
                />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageVideosPage = reduxForm({
  form: 'manage_videos_form'
})(ManageVideosPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageVideosPage));