import client from '../config/AxiosConfig';

/* INSTRUMENTS - CATEGORIES */
export const GetCategories = async (currentPage, limit, filters) => await client.get(`/about_instruments_categories/${currentPage}/${limit}?filter=${filters}`);

export const GetCategory = async (id) => await client.get(`/about_instruments_categories/${id}`);

export const CreateCategory = async (data) => await client.post(`/about_instruments_categories`, data);

export const UpdateCategory = async (id, data) => await client.put(`/about_instruments_categories/${id}`, data);

export const DeleteCategory = async (id) => await client.delete(`/about_instruments_categories/${id}`);

export const GetCategoriesList = async () => await client.get(`/about_instruments_categories`);

/* INSTRUMENTS */
export const GetInstruments = async (currentPage, limit, filters) => await client.get(`/about_instruments/${currentPage}/${limit}?filter=${filters}`);

export const GetInstrument = async (id) => await client.get(`/about_instruments/id/${id}`);

export const ToggleInstruments = async (id, data) => await client.put(`/about_instruments/${id}/toggle`, data);

export const CreateInstrument = async (data) => await client.post(`/about_instruments`, data);

export const UpdateInstrument = async (id, data) => await client.put(`/about_instruments/${id}`, data);

export const DeleteInstrument = async (id) => await client.delete(`/about_instruments/${id}`);

export const UpdateInstrumentsOrder = async (data) => await client.post(`/about_instruments/order`, data);

/* INSTRUMENTS - BLOCKS */
export const GetBlocks = async (id, currentPage, limit) => await client.get(`/about_instruments_blocks/${id}/${currentPage}/${limit}`);

export const GetBlock = async (id) => await client.get(`/about_instruments_blocks/${id}`);

export const CreateBlock = async (data) => await client.post(`/about_instruments_blocks`, data);

export const UpdateBlock = async (id, data) => await client.put(`/about_instruments_blocks/${id}`, data);

export const ToggleBlock = async (id, data) => await client.put(`/about_instruments_blocks/${id}/toggle`, data);

export const DeleteBlock = async (id) => await client.delete(`/about_instruments_blocks/${id}`);

export const UpdateBlocksOrder = async (data) => await client.post(`/about_instruments_blocks/order`, data);