import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, arraySplice, formValueSelector } from 'redux-form';
import { Modal, Button } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import { OrderContainer, ChapterOrderName } from '../../styles/BasicStyles';

let OrderArticles = ({
  open,
  loading,
  handleSubmit,
  onSubmit,
  closeModal,
  dispatch,
  articles = []
}) => {
  const onDragEnd = (data) => {
    if(data.destination && data.source.index !== data.destination.index) {
      const [removed] = articles.splice(data.source.index, 1);

      dispatch(
        arraySplice(
          'manage_order_articles_form',
          'articles',
          data.destination.index,
          0,
          removed
        )
      );
    }
  };

  return (
    <Modal
      visible={open}
      title="Editar ordem dos Artigos"
      maskClosable={false}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit(onSubmit)}
          disabled={articles.length > 0 ? false : true}
        >
          Gravar
        </Button>
      ]}>
      <OrderContainer>
        <DragDropContext onDragEnd={data => onDragEnd(data)}>
          <Droppable droppableId="articles">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}   
              >
                {
                  articles.length > 0 ?
                  articles.map((option, index) => (
                    <Draggable key={option._id} draggableId={option._id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ChapterOrderName>
                            {TranslateValue(option.title)}
                          </ChapterOrderName>
                        </div>
                      )}
                    </Draggable>
                  ))
                  :
                  <div>Não existem Artigos inseridos!</div>
                }
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </OrderContainer>
    </Modal>
  );
};

const selector = formValueSelector('manage_order_articles_form');

OrderArticles = reduxForm({
  form: 'manage_order_articles_form'
})(OrderArticles);

const mapStateToProps = state => ({
  articles: selector(state, 'articles')
});

export default connect(mapStateToProps)(OrderArticles);