import React, { Component } from 'react';
import Table from '../../../components/generic/table/Table';
import { withRouter } from 'react-router-dom';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import ChaptersList from './ChaptersList';
import { TextTitle } from '../../users/UserStyles';

class SessionsList extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: true,
    columns: [],
    rows: [],
    total: 0
  };

  componentDidMount = () => {
    const { list, admin } = this.props;

    if(admin) {
      this.setState({
        columns: [
          {
            title: 'Título',
            dataIndex: 'title',
            render: (value) => TranslateValue(value),
            width: '50%',
          },
          {
            title: 'Nº Capítulos',
            dataIndex: 'chapters',
            render: (value) => value?.length || 0,
            width: '25%',
          },
          {
            title: 'Sessão Completa?',
            dataIndex: 'completed',
            render: (value) => value === true ? 'Sim' : 'Não',
            width: '25%',
          },
        ]
      });
    } else {
      this.setState({
        columns: [
          {
            title: 'Título',
            dataIndex: 'title',
            render: (value) => TranslateValue(value),
            width: '50%',
          },
          {
            title: 'Nº Capítulos',
            dataIndex: 'chapters',
            render: (value) => value?.length || 0,
            width: '24%',
          },
          {
            title: 'Sessão Completa?',
            dataIndex: 'completed',
            render: (value) => value === true ? 'Sim' : 'Não',
            width: '25%',
          },
        ]
      })
    }
    
    this.setState({
      rows: list || [],
      total: list?.length || 0,
      loading: false
    });
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/programs/sessions/${record._id}`);
  };

  renderExpandedRowRender = (data, index) => {
    const {userId, applicator, admin} = this.props;
    return(
      <div style={{marginTop: 8}}>
        <TextTitle>Capítulos: </TextTitle>
        <ChaptersList applicator={applicator} admin={admin} userId={userId} list={data.chapters} sessionInfo={data} />
      </div>
    )
  }

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
    } = this.state;
    const {applicator, admin} = this.props;

    return (
      <Table
        columns={columns}
        currentPage={currentPage}
        pageSize={pageSize}
        loading={loading}
        rows={rows}
        showHeader={true}
        emptyIcon='warning'
        emptyText={'Não existem Sessões inseridas!'}
        total={total}
        rowKey={'_id'}
        hasPagination={false}
        onPressRow={(!applicator && !admin) && this.handleOnPressRow}
        expandedRowKeys={''}
        expandedRowRender={(applicator || admin) && ((data, index) => this.renderExpandedRowRender(data, index))}
      />
    );
  }
}

export default withRouter(SessionsList);