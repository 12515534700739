import client from '../config/AxiosConfig';

/* PROGRAMS */
export const GetIntro = async (id) => await client.get(`/about_programs/${id}`);

export const UpdateIntro = async (id, data) => await client.put(`/about_programs/${id}`, data);

export const GetMotto = async (id, motto) => await client.get(`/about_programs/motto/${id}/${motto}`);

export const CreateMotto = async (id, data) => await client.post(`/about_programs/motto/${id}`, data);

export const UpdateMotto = async (id, motto, data) => await client.put(`/about_programs/motto/${id}/${motto}`, data);

export const DeleteMotto = async (id, motto) => await client.delete(`/about_programs/motto/${id}/${motto}`);

/* PROJECT AND SCIENTIFIC BASE */
export const GetProject = async (id) => await client.get(`/about_project/${id}`);

export const UpdateProject = async (id, data) => await client.put(`/about_project/${id}`, data);

/* TEAM */
export const GetTeam = async (currentPage, limit, filters) => await client.get(`/about_team/${currentPage}/${limit}?filter=${filters}`);

export const GetMember = async (id) => await client.get(`/about_team/${id}`);

export const CreateMember = async (data) => await client.post(`/about_team`, data);

export const UpdateMember = async (id, data) => await client.put(`/about_team/${id}`, data);

export const DeleteMember = async (id) => await client.delete(`/about_team/${id}`);

export const UpdateTeamOrder = async (data) => await client.post(`/about_team/order`, data);