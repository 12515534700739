import styled from 'styled-components';

export const BlocksContainer = styled.div`
  & > div > div {
    border: 1px solid #cccccc;
    margin-bottom: 10px;
  }
`;

export const ItemBox = styled.div`
  display: block;
  width: 100%;
  min-height: 100px;
  padding: 45px 20px;
  background: #ffffff;
  color: #333333;
  position: relative;

  h1,
  h2,
  h3 {
    color: #333333;
  }
`;

export const ItemVideo = styled.div`
  height: 300px;
`;

export const ItemImage = styled.div`
  & img {
    width: 100%;
  }
`;

export const DraftText = styled.div`
  width: 100%;
  text-align: left;
`;

export const OptionsItem = styled.div`
  margin-top: 30px;
`;

export const OptionButton = styled.div`
  cursor: ${({ number }) => number === 3 ? 'grabbing !important' : 'pointer !important'};
  color: #333333;
  position: absolute;
  background: white;
  padding: 5px 8px;
  top: 12px;
  right: ${({ number }) => 
    number === 0 ? 
    '20px'
    :
    number === 1 ?
    '115px'
    :
    number === 2 ?
    '195px'
    :
    number === 3 ?
    '300px'
    :
    '0px'
  };
  box-shadow: 1px 1px 3px #ccc;
`;
