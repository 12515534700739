import React, { Component } from 'react';
import { Popconfirm, Icon } from 'antd';
import Table from '../../../components/generic/table/Table';
import { withRouter } from 'react-router-dom';
import {
  GetUser,
  DeleteParent
} from '../../../infra/requests/UsersRequests';
import {
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton,
} from '../../../styles/BasicStyles';
import BaseButton from '../../../components/generic/buttons/BaseButton';
import { getParentType } from '../../../infra/utils/ParentTypes';

class ParentsList extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: true,
    columns: [],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    const {applicator} = this.props;
    await this.updateDataTable();
    
    if(applicator) {
      this.setState({
        columns: [
          {
            title: 'Nome',
            key: 'name',
            render: (data) => data.parent?.name,
            width: '40%'
          },
          {
            title: 'Email',
            key: 'email',
            render: (data) => data.parent?.email,
            width: '25%'
          },
          {
            title: 'Tipo',
            key: 'type',
            render: (data) => this.renderType(data.type, data.type_other),
            width: '20%'
          }
        ],
      })
    } else {
      this.setState({
        columns: [
          {
            title: 'Nome',
            key: 'name',
            render: (data) => data.parent?.name,
            width: '40%'
          },
          {
            title: 'Email',
            key: 'email',
            render: (data) => data.parent?.email,
            width: '25%'
          },
          {
            title: 'Tipo',
            key: 'type',
            render: (data) => this.renderType(data.type, data.type_other),
            width: '20%'
          },
          {
            title: '',
            render: (data) => (
              <React.Fragment>
                <TableButton error onClick={(e) => e.stopPropagation()}>
                  <Popconfirm
                    placement='topRight'
                    title='Tem a certeza que quer remover o Encarregado de Educação?'
                    onConfirm={() => this.removeParent(data)}
                  >
                    <Icon style={{ marginRight: 10 }} type='delete' />
                    {'Remover'}
                  </Popconfirm>
                </TableButton>
              </React.Fragment>
            )
          }
        ],
      })
    }
  };

  updateDataTable = async () => {
    try {
      this.setState({ loading: true });
      
      const { userID } = this.props;
      const result = await GetUser(userID);

      this.setState({
        rows: result?.data?.parents || [],
        total: result?.data?.parents?.length || 0,
        loading: false
      });
    } 
    catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  }

  handleOnPressRow = (record) => {
    const { history, userID } = this.props;
    history.push(`/users/${userID}/guardians/${record._id}`);
  };

  removeParent = async (data) => {
    try {
      const { userID } = this.props;
      await DeleteParent(userID, data._id);
      await this.updateDataTable();
    } 
    catch (e) {
      console.error(e);
    }
  };

  renderType = (type, typeOther) => {
    let result = getParentType(type);
    if(type === 3) result = `${result} (${typeOther})`;

    return result;
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
    } = this.state;
    const { history, userID, applicator } = this.props;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}></HeaderTitle>
          {!applicator && (
            <HeaderButtonsContainer buttons={1}>
              <BaseButton
                type={'primary'}
                icon={'plus'}
                text={'Criar'}
                onClick={() => history.push(`/users/${userID}/guardians/add`)}
              />
            </HeaderButtonsContainer>
          )}
        </HeaderContainer>
        <Table
          columns={columns}
          currentPage={currentPage}
          pageSize={pageSize}
          loading={loading}
          rows={rows}
          showHeader={true}
          emptyIcon='warning'
          emptyText={'Não existem Encarregados de Educação inseridos!'}
          total={total}
          rowKey={'_id'}
          hasPagination={false}
          onPressRow={!applicator && this.handleOnPressRow}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(ParentsList);