import React from "react";
import PropTypes from "prop-types";
import { Empty, ConfigProvider, Icon } from "antd";
import { StyledTable } from "./TableStyles";

const Table = ({
  columns,
  rows,
  showHeader,
  alignContentTop,
  title,
  size,
  total,
  pageSize,
  pageSizeOptions,
  currentPage,
  handleChangePage,
  handleChangeRowsPerPage,
  loading,
  emptyText,
  emptyIcon,
  defaultPageSize,
  onPressRow,
  onExpand,
  expandedRowKeys,
  rowKey,
  expandedRowRender,
  hasPagination,
  secondary,
  onSortChange,
}) => {
  const renderEmptyTable = () => (
    <Empty
      description={emptyText ? emptyText : null}
      image={
        emptyIcon ? (
          <Icon type={emptyIcon} style={{ fontSize: "100px" }} />
        ) : null
      }
    />
  );

  return (
    <ConfigProvider renderEmpty={renderEmptyTable}>
      <StyledTable
        alignContentTop={alignContentTop}
        columns={columns}
        showHeader={showHeader}
        title={title}
        dataSource={rows ? rows : []}
        bordered={true}
        size={size ? size : "small"}
        loading={loading}
        rowKey={rowKey}
        expandedRowRender={expandedRowRender}
        expandedRowKeys={expandedRowKeys}
        onExpand={onExpand}
        secondary={secondary}
        onRow={
          onPressRow
            ? (record, rowIndex) => ({
                onClick: (event) => onPressRow(record, rowIndex),
              })
            : () => false
        }
        onChange={onSortChange ? onSortChange : undefined}
        pagination={
          hasPagination
            ? {
                total: total,
                showQuickJumper: false,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} de ${total}`,
                pageSize: pageSize ? pageSize : 15,
                pageSizeOptions: pageSizeOptions
                  ? pageSizeOptions
                  : ["15", "30", "45", "60"],
                current: currentPage,
                onChange: handleChangePage,
                onShowSizeChange: handleChangeRowsPerPage,
                defaultPageSize: defaultPageSize ? defaultPageSize : 15,
              }
            : false
        }
      />
    </ConfigProvider>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  total: PropTypes.number,
  loading: PropTypes.bool,
  showHeader: PropTypes.bool,
  title: PropTypes.func,
  pageSizeOptions: PropTypes.array,
  emptyText: PropTypes.string.isRequired,
  emptyIcon: PropTypes.string,
  defaultPageSize: PropTypes.array,
  rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  size: PropTypes.oneOf(["small", "default", "middle"]),
};

export default Table;
