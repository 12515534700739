import React, { Component } from 'react';
import moment from 'moment';
import { Popconfirm, Icon } from 'antd';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  TableButton,
} from '../../styles/BasicStyles';
import {
  StateLabel
} from './Styles';
import {
  GetThreads,
  DeleteThread,
  ValidateThread
} from '../../infra/requests/ForumRequests';
import {
  GetUsersList
} from '../../infra/requests/UsersRequests';
import ThreadsFilters from './ThreadsFilters';
import { connect } from 'react-redux';
import { getForum } from '../../infra/utils/ForumTypes';

class ThreadsPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      pageSize: 30,
      currentPage: 1,
      filters: '',
      loading: true,
      ready: false,
      columns: [],
      rows: [],
      total: 0,
      forumName: '',
      forumID: '',
      usersList: []
    };
  
  }
  
  componentDidMount = async () => {
    const {user, history} = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 

    await this.updateDataTable();
  };

  componentDidUpdate = async (prevProps) => {
    const { history } = this.props;
    if(history?.location?.pathname !== prevProps?.location?.pathname) {
      await this.updateDataTable();
    }
  }

  handleOnPressRow = (record) => {
    const { history } = this.props;
    const { forumID } = this.state;
    history.push(`/forum/${forumID}/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  removeThread = async (data) => {
    try {
      await DeleteThread(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  validateThread = async (data) => {
    try {
      await ValidateThread(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { history, match: { params } } = this.props;
      let { currentPage, pageSize, filters } = this.state;

      const forumID = params.forum;
      const forum = getForum(params.forum);
      const forumName = forum?.name || '';

      if(!forumName) {
        return history.push('/');
      }

      filters = `${filters}&forum=${forumID}`;
      const result = await GetThreads(currentPage, pageSize, filters);
      const usersList = await GetUsersList();
      
      const cols = (forumID === '603e191d3222800e9d983904' || forumID === '603e192a3222800e9d983905') ?  
      ([
        {
          title: 'Data de Criação',
          dataIndex: 'createdAt',
          render: (value) => `${moment.utc(value).format('DD-MM-YYYY, HH:mm')}h`,
          width: '15%'
        },
        {
          title: 'Autor',
          dataIndex: 'author',
          render: (value) => value?.name,
          width: '15%'
        },
        {
          title: 'Título',
          dataIndex: 'title',
          width: '30%'
        },
        {
          title: 'Nº Comentários',
          dataIndex: 'total_comments_bo',
          width: '10%'
        },
        {
          title: 'Validação do Admin',
          dataIndex: 'pending',
          render: (value) => this.renderState(value),
          width: '10%'
        },
        {
          title: 'Ações',
          render: (data) => (
            <React.Fragment>
              {
                data.pending &&
                <TableButton onClick={(e) => e.stopPropagation()}>
                  <Popconfirm
                    placement='topRight'
                    title='Tem a certeza que pretende validar esta Thread?'
                    onConfirm={() => this.validateThread(data)}
                  >
                    <Icon style={{ marginRight: 5 }} type='close-circle'/>
                    Validar
                  </Popconfirm>
                </TableButton>
              }
              <TableButton error onClick={(e) => e.stopPropagation()}>
                <Popconfirm
                  placement='topRight'
                  title='Tem a certeza que quer remover esta Thread?'
                  onConfirm={() => this.removeThread(data)}
                >
                  <Icon style={{ marginRight: 5 }} type='delete' />
                  Remover
                </Popconfirm>
              </TableButton>
            </React.Fragment>
          ),
        }
      ])
      : ([
        {
          title: 'Data de Criação',
          dataIndex: 'createdAt',
          render: (value) => `${moment.utc(value).format('DD-MM-YYYY, HH:mm')}h`,
          width: '15%'
        },
        {
          title: 'Autor',
          dataIndex: 'author',
          render: (value) => value?.name,
          width: '15%'
        },
        {
          title: 'Título',
          dataIndex: 'title',
          width: '30%'
        },
        {
          title: 'Nº Comentários',
          dataIndex: 'total_comments_bo',
          width: '10%'
        },
        {
          title: 'Validação da conta por parte do autor',
          dataIndex: 'pendingUser',
          render: (value) => this.renderState(value),
          width: '10%'
        },
        {
          title: 'Validação do Admin',
          dataIndex: 'pending',
          render: (value) => this.renderState(value),
          width: '10%'
        },
        {
          title: 'Ações',
          render: (data) => (
            <React.Fragment>
              {
                data.pending &&
                <TableButton onClick={(e) => e.stopPropagation()}>
                  <Popconfirm
                    placement='topRight'
                    title='Tem a certeza que pretende validar esta Thread?'
                    onConfirm={() => this.validateThread(data)}
                  >
                    <Icon style={{ marginRight: 5 }} type='close-circle'/>
                    Validar
                  </Popconfirm>
                </TableButton>
              }
              <TableButton error onClick={(e) => e.stopPropagation()}>
                <Popconfirm
                  placement='topRight'
                  title='Tem a certeza que quer remover esta Thread?'
                  onConfirm={() => this.removeThread(data)}
                >
                  <Icon style={{ marginRight: 5 }} type='delete' />
                  Remover
                </Popconfirm>
              </TableButton>
            </React.Fragment>
          ),
        }
      ])

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
        forumName,
        forumID,
        usersList: usersList?.data || [],
        columns: cols
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  renderState = (value) => {
    return (
      <StateLabel
        bgColor={value ? '#cf1322' : '#389e0d'}
      >
        {value ? 'Não Validado' : 'Validado'}
      </StateLabel>
    );
  }

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
      forumName,
      usersList
    } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>Lista de Assuntos no Fórum</PageTitle>
            {`Fórum ${forumName}`}
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <ThreadsFilters queryChange={this.handleFilterChange} usersList={usersList}/>
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem assuntos inseridos!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, null)(ThreadsPage);