import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetClass,
  CreateClassStudent
} from '../../../infra/requests/SchoolsRequests';
import {
  GetClassStudentsList, 
  GetUserClass
} from '../../../infra/requests/UsersRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  Separator,
  TableFilterSection
} from '../../../styles/BasicStyles';
import {
  InfoTitle,
  InfoValue,
  InfoWarning
} from './Styles';
import FormValidator from '../../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../../components/base/ManageFormHeader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../../redux/Menu/menu.actions';
import SelectInput from '../../../components/generic/inputs/SelectInput';
import CheckboxInput from '../../../components/generic/inputs/CheckboxInput';

const validations = FormValidator.make({
  student: 'required'
});

class ManageStudentsList extends Component {
  state = { 
    loading: true,
    students: [],
    allStudents: [],
    classInfo: undefined,
    studentInfo: undefined,
    showWithoutClass: true,
    showFromSchool: false
  };

  componentDidMount = async () => {
    const { user, history } = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 
      
    await this.loadDetail();
  };

  loadDetail = async () => {
    const {
      match: { params },
      CloseMenu
    } = this.props;
    const { showWithoutClass } = this.state;
    
    CloseMenu();
    const classInfo = await GetClass(params.class);
    const students = await GetClassStudentsList();

    this.updateStudentsList(students?.data || [], showWithoutClass)
    this.setState({ 
      loading: false,
      classInfo: classInfo?.data,
      allStudents: students?.data
    });
  }

  updateStudentsList = (studentsList, showWithoutClass, showFromSchool) => {
    const { classInfo } = this.state;
    let list = studentsList

    if (showWithoutClass)
      list = list.filter(l => {if (!l.class) return l})

    if (showFromSchool)
      list = list.filter(l => {if (l.school?._id === classInfo.school) return l})

    this.setState({ 
      students: list
    });
  }

  onClickShowAll = () => {
    const { allStudents, showFromSchool, showWithoutClass } = this.state;

    this.setState({ 
      showWithoutClass: !showWithoutClass
    }, this.updateStudentsList(allStudents, !showWithoutClass, showFromSchool));
  }

  onClickShowFromSchool = () => {
    const { allStudents, showWithoutClass, showFromSchool } = this.state;

    this.setState({ 
      showFromSchool: !showFromSchool
    }, this.updateStudentsList(allStudents, showWithoutClass, !showFromSchool));
  }

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });

      const { history, match: { params } } = this.props;

      const { success } = await CreateClassStudent(params.class, values);

      if(success) {
        return history.push(`/classes/${params.class}?students=true`);
      }

      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history, match: { params } } = this.props;

    return history.push(`/classes/${params.class}?students=true`);
  };

  checkStudent = async (value) => {
    const student = await GetUserClass(value);
    this.setState({
      studentInfo: student?.data || undefined
    });
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { loading, students, classInfo, studentInfo, showWithoutClass, showFromSchool } = this.state;

    if(loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Turmas', classInfo?.name, 'Alunos', 'Adicionar']}
          titlePosition={2}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <TableFilterSection>
            <Row gutter={24}>
              <Col xs={24} md={8}>
                <CheckboxInput 
                  input={{value: showWithoutClass, onChange: this.onClickShowAll}}
                  meta={{}}
                  label='Mostrar apenas alunos sem turma'
                  inlineCheckbox
                />
              </Col>
              <Col xs={24} md={8}>
                <CheckboxInput 
                  input={{value: showFromSchool, onChange: this.onClickShowFromSchool}}
                  meta={{}}
                  label='Mostrar apenas alunos desta escola'
                  inlineCheckbox
                />
              </Col>
            </Row>
          </TableFilterSection>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24}>
              <Col xs={12} offset={6}>
                <Field
                  component={SelectInput}
                  name={'student'}
                  label={'Aluno *'}
                  placeholder={'Escolha o aluno'}
                  type='select'
                  data={students}
                  dataKey={'_id'}
                  dataLabel={'name'}
                  execAfterChange={this.checkStudent}
                />
              </Col>
            </Row>
            {
              studentInfo &&
              <React.Fragment>
                <Row gutter={24}>
                  <Col xs={12} offset={6}>
                    {
                      studentInfo?.class?._id === classInfo?._id ?
                      <InfoWarning>Nota: O Aluno já se encontra associado à Turma atual!</InfoWarning>
                      :
                      studentInfo?.class?._id && studentInfo?.class?._id !== classInfo?._id ?
                      <InfoWarning error={true}>Atenção: O Aluno já se encontra a outra Turma!</InfoWarning>
                      :
                      <InfoWarning>Nota: O Aluno não se encontra associado a nenhuma Turma!</InfoWarning>
                    }
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={12} offset={6}>
                    <Separator/>
                    <InfoTitle>Informação do Aluno</InfoTitle>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={12} offset={6}>
                    <InfoValue>Nome: <span>{studentInfo?.name}</span></InfoValue>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={12} offset={6}>
                    <InfoValue>Email: <span>{studentInfo?.email}</span></InfoValue>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={12} offset={6}>
                    <InfoValue>Turma atual: <span>{studentInfo?.class?._id ? studentInfo?.class.name : '-'}</span></InfoValue>
                  </Col>
                </Row>
              </React.Fragment>
            }
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageStudentsList = reduxForm({
  form: 'manage_classes_students_form',
  validate: validations,
})(ManageStudentsList);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageStudentsList));