import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import { 
  DeleteUser, 
  ToggleUserState,
  GetParents
} from '../../infra/requests/UsersRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import UsersFilters from './UsersFilters';
import { getUserType } from '../../infra/utils/UserTypes';
import Table from '../../components/generic/table/Table';

class ApplicatorParentsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: false,
    columns: [
      {
        title: 'Tipo',
        dataIndex: 'type',
        render: (value) => getUserType(value),
        width: '18%'
      },
      {
        title: 'Nome',
        dataIndex: 'name',
        width: '25%'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        width: '22%'
      },
      {
        title: 'Contacto',
        dataIndex: 'contact',
        width: '13%'
      },
    ],
    rows: [],
    total: 0,
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/users/parents/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  removeUser = async (data) => {
    try {
      await DeleteUser(data._id);
      await this.updateDataTable();
    } 
    catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const { user } = this.props;

      let result = undefined;
      if(user.type === 2) result = await GetParents(currentPage, pageSize, filters);

      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false,
      });
    } 
    catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  toggleUser = async (record) => {
    this.setState({ loading: true });
    await ToggleUserState(record._id, { active: !record.active });
    await this.updateDataTable();
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize } = this.state;
    const { user } = this.props;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Lista de Enc. Educação</PageTitle>
            {`Enc. Educação`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'default'}
              icon={'sync'}
              text={'Atualizar'}
              onClick={() => this.updateDataTable()}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <UsersFilters applicator queryChange={this.handleFilterChange} user={user}/>
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Utilizadores inseridos!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({ 
  user: state.user
});

export default connect(mapStateToProps)(ApplicatorParentsPage);
