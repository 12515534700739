import { Icon, notification, Popconfirm } from 'antd';
import React, { useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { TableButton } from '../../../styles/BasicStyles';
import CheckboxInput from '../../../components/generic/inputs/CheckboxInput';
import { ToggleAnswered } from '../../../infra/requests/FormsRequests';

let ChangeAnswered = ({answered, id}) => {
  const [newValue, setNewValue] = useState(answered);

  const onChange = (value) => {
    setNewValue(value?.target?.checked);
  };

  const confirmInput = async() => {
    try {
      
      const { success } = await ToggleAnswered(id, {answered: newValue});
     
      if(success) {
        notification.success({
          message: "Resposta confirmada com sucesso!"
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  return(
    <React.Fragment>
      <TableButton onClick={(e) => e.stopPropagation()}>
        <CheckboxInput 
          input={{value: newValue, onChange: value => onChange(value)}}
          meta={{}}
        />
      </TableButton>
      <TableButton onClick={(e) => e.stopPropagation()}>
        <Popconfirm
          placement='topRight'
          title={'Quer confirmar que este utilizador respondeu ao questionário?'}
          onConfirm={() => confirmInput()}
        >
          <Icon type={'check-circle'} />
          {' Confirmar'}
        </Popconfirm>
      </TableButton>
    </React.Fragment>
  )
}

export default withLocalize(ChangeAnswered);