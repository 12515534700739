import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '../../components/generic/table/Table';
import { GetExtraSessions } from '../../infra/requests/ExtraSessionRequests';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import { getFormUserType } from '../../infra/utils/FormUserTypeTypes';
import { HeaderContainer, HeaderTitle, PageContainer, PageTitle } from '../../styles/BasicStyles';

class ExtraSessionsPage extends Component {
  state = {
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Sessões Extra',
        dataIndex: 'title',
        render: (value) => TranslateValue(value)
      },
      {
        title: 'Tipo de Utilizador',
        dataIndex: 'user_type',
        render: (value) => getFormUserType(value),
        width: '30%'
      },
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/extra-session/${record._id}`);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const result = await GetExtraSessions();

      this.setState({
        rows: result.data || []
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  render() {
    const {
      columns,
      rows,
      loading
    } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>Lista de Sessões Extra</PageTitle>
            {`Sessões Extra`}
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <Table
            columns={columns}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Sessões Extra inseridas!'}
            rowKey={'_id'}
            hasPagination={false}
            onPressRow={this.handleOnPressRow}
          />
        </PageContainer>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, null)(ExtraSessionsPage);