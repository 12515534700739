export const BlockTypes = [
  { _id: 1, name: 'Texto' },
  { _id: 2, name: 'Imagem' },
  { _id: 3, name: 'Vídeo' },
  { _id: 4, name: 'Áudio' },
  { _id: 5, name: 'Ficheiro' }
];

export const getBlockTypes = (value) => {
  if(Number.isInteger(value) && value > 0 && BlockTypes[value - 1]) return BlockTypes[value - 1].name;
  return null;
};