import React, { useEffect, useState } from "react";
import { 
  PageContainer, 
  SpinLoading
} from "../../styles/BasicStyles";
import { Col } from "antd";
import { Title, Block, TitleData, RowDashboard, Label, Line, Title2, ColDashboard } from "./DashboardStyles";
import { VictoryPie, VictoryLabel, VictoryChart, VictoryAxis, VictoryLine, VictoryContainer } from "victory";
import { SecondaryColour, MenuBorderColor, SecondaryBackgroundColor } from "../../styles/Colours";
import { getDashboard } from "../../infra/requests/BaseRequests";

const ApplicatorDashboard = () => {
  const [info, setInfo] = useState(undefined)

  useEffect(() => {
    async function getInfo() {
      const result = await getDashboard();
      if (result.success) {
        setInfo(result.data)
      }
    }

    getInfo()
  }, [])

  if(!info) return null;
  const users = []
  let totalUsers = 0
  info.users.map(u => {
    users.push({x: u.count, y: u.count})
    totalUsers += u.count
  })

  return (
    <PageContainer>
      <RowDashboard gutter={[16, 16]}>
        <ColDashboard xs={12} md={6} lg={6}>
          <Block>
            <Title>Escolas</Title>
            <TitleData>{info.schools || 0}</TitleData>
          </Block>
        </ColDashboard>
        <ColDashboard xs={12} md={6} lg={6}>
          <Block>
            <Title>Turmas</Title>
            <TitleData>{info.classes || 0}</TitleData>
          </Block>
        </ColDashboard>
        {/* <ColDashboard xs={12} md={6} lg={6}>
          <Block>
            <Title>Utilizadores</Title>
            <TitleData>32</TitleData>
          </Block>
        </ColDashboard>
        <ColDashboard xs={12} md={6} lg={6}>
          <Block>
            <Title>Sessões Realizadas</Title>
            <TitleData>0</TitleData>
          </Block>
        </ColDashboard> */}
      </RowDashboard>
      <RowDashboard gutter={[16, 16]}>
        <Col xs={24} md={24} lg={12} style={{height: 400}}>
          <Block>
            <svg viewBox="50 50 100 100" style={{width: 250, height: 250, marginBottom: 16}}>
              <VictoryPie
                standalone={false}
                width={200} height={200}
                colorScale={["#FFBF00", "#46329B" ]}
                data={users}
                innerRadius={30} labelRadius={35}
                style={{ labels: { fontSize: 6, fill: "white" } }}
              />
              <VictoryLabel
                textAnchor="middle"
                style={{ fontSize: 8, fill: SecondaryColour }}
                x={100} y={100}
                text={`${totalUsers} utilizadores`}
              />
            </svg>
            <Label>
              <Title2><Line color={"#FFBF00"}/>Educadores</Title2>
              <Title2><Line color={"#46329B"}/>Educandos</Title2>
            </Label>
          </Block>
        </Col>
      </RowDashboard>
    </PageContainer>
  );
}

export default ApplicatorDashboard;