import PropTypes from 'prop-types';
import React from 'react';
import { InputDiv, InputLabelDiv, CheckboxField } from './InputStyles';

const CheckboxInput = ({
  input,
  meta,
  label,
  first,
  positiveLabel,
  disabled,
  importantLabel,
  inlineCheckbox
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;

  return (
    <InputDiv first={first}>
      {label && (
        <InputLabelDiv 
          importantLabel={importantLabel}
          inlineCheckbox={inlineCheckbox}
        >
          {label}
        </InputLabelDiv>
      )}
      <CheckboxField
        disabled={disabled}
        checked={input?.value ? input?.value : false}
        onChange={input.onChange}
        error={showError ? 1 : 0}
        inlineCheckbox={inlineCheckbox}
      >
        {positiveLabel}
      </CheckboxField>
    </InputDiv>
  );
};

CheckboxInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  importantLabel: PropTypes.bool
};

CheckboxInput.defaultProps = {
  importantLabel: false,
  inlineCheckbox: false
};

export default CheckboxInput;
