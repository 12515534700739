import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetArticle,
  UpdateArticle,
  CreateArticle,
  GetCategoriesList
} from '../../infra/requests/AboutArticlesRequests';
import SelectInput from '../../components/generic/inputs/SelectInput';
import DraftInput from '../../components/generic/inputs/DraftInput';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/generic/inputs/TextInput';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  title: 'required|languages',
  description: 'required|languages',
  category: 'required'
});

class ManageArticlesPage extends Component {
  state = { 
    isNew: false, 
    loading: true,
    categories: []
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu,
      user,
      history
    } = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 

    CloseMenu();

    const categories = await GetCategoriesList();

    if(params.id) {
      const result = await GetArticle(params.id);
      dispatch(
        initialize('manage_articles_form', {
          ...result.data,
        })
      );

      this.setState({ 
        loading: false,
        categories: categories?.data || []
      });
    } 
    else {
      this.setState({
        isNew: true,
        loading: false,
        categories: categories?.data || []
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;

      const payload = FlattenToFormData(values);
      const { success } = params.id ? await UpdateArticle(params.id, payload) : await CreateArticle(payload);

      if(success) return history.push('/website/about/articles');
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/website/about/articles');
  };

  render() {
    const { handleSubmit, pristine, activeLanguage, languages } = this.props;
    const { isNew, loading, categories } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Sobre', 'Artigos', title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row>
              <Row gutter={24}>
                <Col xs={12}>
                  <Field
                    component={SelectInput}
                    name={'category'}
                    label={'Categoria *'}
                    placeholder={'Escolha a categoria'}
                    type='select'
                    data={categories}
                    dataKey={'_id'}
                    dataLabel={'name'}
                    translatable={true}
                  />
                </Col>
              </Row>
              <Row gutter={24} style={{ marginTop: '20px' }}>
                <Col xs={12} offset={0}>
                  <DefaultLanguageTab>
                    {GetTranslationDescription(activeLanguage?.code)}
                  </DefaultLanguageTab>
                  <Field
                    component={TextInput}
                    name={`title.${activeLanguage?.code}`}
                    type='text'
                    label={'Título *'}
                    placeholder={'Título'}
                  />
                  <Field
                    component={DraftInput}
                    name={`description.${activeLanguage?.code}`}
                    label={'Descrição *'}
                    placeholder={'Descrição'}
                  />
                </Col>
                <Col xs={12} offset={0}>
                  <Tabs>
                    {languages
                      .filter((x) => !x.active)
                      .map((language) => (
                        <TabPane
                          tab={GetTranslationDescription(language.code)}
                          key={language.code}
                        >
                          <Field
                            component={TextInput}
                            name={`title.${language.code}`}
                            type='text'
                            label={'Título *'}
                            placeholder={'Título'}
                          />
                          <Field
                            component={DraftInput}
                            name={`description.${language.code}`}
                            label={'Descrição *'}
                            placeholder={'Descrição'}
                          />
                        </TabPane>
                      ))}
                  </Tabs>
                </Col>
              </Row>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageArticlesPage = reduxForm({
  form: 'manage_articles_form',
  validate: validations,
})(ManageArticlesPage);

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageArticlesPage));