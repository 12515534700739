import React, { useEffect, useState } from "react";
import { 
  PageContainer, 
  SpinLoading
} from "../../styles/BasicStyles";
import { Col } from "antd";
import { Title, Block, TitleData, RowDashboard, Label, Line, Title2, ColDashboard } from "./DashboardStyles";
import { VictoryPie, VictoryLabel, VictoryChart, VictoryAxis, VictoryLine, VictoryContainer } from "victory";
import { SecondaryColour, MenuBorderColor, SecondaryBackgroundColor } from "../../styles/Colours";
import { getDashboard } from "../../infra/requests/BaseRequests";

const AdminDashboard = () => {
  const [info, setInfo] = useState(undefined)

  useEffect(() => {
    async function getInfo() {
      const result = await getDashboard();
      if (result.success) {
        setInfo(result.data)
      }
    }

    getInfo()
  }, [])

  if(!info) return null;
  const users = []
  let totalUsers = 0
  info.users.map(u => {
    users.push({x: u.count, y: u.count})
    totalUsers += u.count
  })

  const usersChart = []
  const usersChartLabel = []
  info.chartHistory.map((u, i) => {
    usersChart.push({x: i, y: u.Users})
    usersChartLabel.push({label: `${u._id.month}/${u._id.year.toString().slice(-2)}`})
  })

  const forums = [
    {id: '603e191d3222800e9d983904', label: 'Forum Educandos', data: []}, 
    {id: '603e192a3222800e9d983905', label: 'Forum Educadores', data: []}, 
    {id: '603e19313222800e9d983906', label: 'Forum Geral', data: []}
  ]

  const threadsChartLabel = []
  info.threadsChartHistory.map((d, i) => {
    if (d.data && d.data.forums) {
      forums.map((f, j) => {
        const indexForum = d.data.forums.findIndex(forum => f.id === forum.forum._id)
        if (indexForum >= 0) forums[j].data.push({x: i, y: d.data.forums[indexForum].total})
        else forums[j].data.push({x: i, y:0})
      })

    } else {
      forums.map((f, j) => {
        return f.data.push({x: i, y:0})
      })
    }
    threadsChartLabel.push({label: `${d._id.month}/${d._id.year.toString().slice(-2)}`})
  })

  return (
    <PageContainer>
      <RowDashboard gutter={[16, 16]}>
        <ColDashboard xs={12} md={6} lg={6}>
          <Block>
            <Title>Pré-registos</Title>
            <TitleData>{info.register || 0}</TitleData>
          </Block>
        </ColDashboard>
        <ColDashboard xs={12} md={6} lg={6}>
          <Block>
            <Title>Escolas</Title>
            <TitleData>{info.schools || 0}</TitleData>
          </Block>
        </ColDashboard>
        {/* <ColDashboard xs={12} md={6} lg={6}>
          <Block>
            <Title>Utilizadores</Title>
            <TitleData>32</TitleData>
          </Block>
        </ColDashboard>
        <ColDashboard xs={12} md={6} lg={6}>
          <Block>
            <Title>Sessões Realizadas</Title>
            <TitleData>0</TitleData>
          </Block>
        </ColDashboard> */}
        <ColDashboard xs={12} md={6} lg={6}>
          <Block>
            <Title>Temas pendentes no fórum</Title>
            <TitleData>{info.threads || 0}</TitleData>
          </Block>
        </ColDashboard>
        <ColDashboard xs={12} md={6} lg={6}>
          <Block>
            <Title>Comentários pendentes no fórum</Title>
            <TitleData>{info.comments || 0}</TitleData>
          </Block>
        </ColDashboard>
      </RowDashboard>
      <RowDashboard gutter={[16, 16]}>
        <Col xs={24} md={24} lg={12} style={{height: 400}}>
          <Block>
            <svg viewBox="50 50 100 100" style={{width: 250, height: 250, marginBottom: 16}}>
              <VictoryPie
                standalone={false}
                width={200} height={200}
                colorScale={["#FFBF00", "#FFE188", "#F38231", "#46329B", "#B1A1F5" ]}
                data={users}
                innerRadius={30} labelRadius={35}
                style={{ labels: { fontSize: 6, fill: "white" } }}
              />
              <VictoryLabel
                textAnchor="middle"
                style={{ fontSize: 8, fill: SecondaryColour }}
                x={100} y={100}
                text={`${totalUsers} utilizadores`}
              />
            </svg>
            <Label>
              <Title2><Line color={"#FFBF00"}/>Administradores</Title2>
              <Title2><Line color={"#FFE188"}/>Aplicadores</Title2>
              <Title2><Line color={"#F38231"}/>Educadores</Title2>
              <Title2><Line color={"#46329B"}/>Educandos</Title2>
              <Title2><Line color={"#B1A1F5"}/>Visitantes</Title2>
            </Label>
          </Block>
        </Col>
        <Col xs={24} md={24} lg={12} style={{height: 400}}>
          <Block>
            <Title>Novos Utilizadores por mês</Title>
            <VictoryChart height={300} style={{parent: {margin: '-50px 0px 0px' }}}>
              <VictoryAxis
                style={{
                  axis: {stroke: MenuBorderColor},
                  tickLabels: {
                    fill: SecondaryBackgroundColor,
                    fontSize: 12,
                  },
                  grid: {stroke: MenuBorderColor}
                }}
                tickFormat={(value) => usersChartLabel[value].label}
                tickValues={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]}
              />
              <VictoryAxis
                dependentAxis
                style={{
                  axis: {stroke: MenuBorderColor},
                  tickLabels: {
                    fill: SecondaryBackgroundColor,
                    fontSize: 14,
                  },
                  grid: {stroke: MenuBorderColor}
                }}
              />
              <VictoryLine
              style={{
                data: { stroke: "#FFBF00" }
              }}
                data={usersChart}
              />
            </VictoryChart>
          </Block>
        </Col>
      </RowDashboard>
      <RowDashboard gutter={[16, 16]}>
        {/* <Col xs={24} md={24} lg={12} style={{height: 400}}>
          <Block>
            <Title>Logins diários</Title>
            <VictoryChart height={300} style={{parent: {margin: '-50px 0px 0px' }}}>
              <VictoryAxis
                style={{
                  axis: {stroke: MenuBorderColor},
                  tickLabels: {
                    fill: SecondaryBackgroundColor,
                    fontSize: 14,
                  },
                  grid: {stroke: MenuBorderColor}
                }}
                tickFormat={(value) =>
                  value
                    ? value === 1 
                      ? 'Seg'
                      : value === 2 ? 'Ter'
                      : value === 3 ? 'Qua'
                      : value === 4 ? 'Qui'
                      : value === 5 ? 'Sex'
                      : value === 6 ? 'Sab'
                      : 'Dom'
                    : undefined
                }
                tickValues={[1, 2, 3, 4, 5, 6, 7]}
              />
              <VictoryAxis
                dependentAxis
                style={{
                  axis: {stroke: MenuBorderColor},
                  tickLabels: {
                    fill: SecondaryBackgroundColor,
                    fontSize: 14,
                  },
                  grid: {stroke: MenuBorderColor}
                }}
              />
              <VictoryLine
              style={{
                data: { stroke: "#F38231" }
              }}
                data={[
                  { x: 1, y: 4 },
                  { x: 2, y: 3 },
                  { x: 3, y: 5 },
                  { x: 4, y: 6 },
                  { x: 5, y: 3 },
                  { x: 6, y: 2 },
                  { x: 7, y: 0 },
                ]}
              />
            </VictoryChart>
          </Block>
        </Col> */}
        <Col xs={24} md={24} lg={12} style={{height: 400}}>
          <Block>
            <Title>Novos Temas nos Forums</Title>
            <VictoryChart height={300} style={{parent: {margin: '-50px 0px 0px' }}}>
              <VictoryAxis
                style={{
                  axis: {stroke: MenuBorderColor},
                  tickLabels: {
                    fill: SecondaryBackgroundColor,
                    fontSize: 14,
                  },
                  grid: {stroke: MenuBorderColor}
                }}
                tickFormat={(value) => threadsChartLabel[value].label}
                tickValues={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]}
              />
              <VictoryAxis
                dependentAxis
                style={{
                  axis: {stroke: MenuBorderColor},
                  tickLabels: {
                    fill: SecondaryBackgroundColor,
                    fontSize: 14,
                  },
                  grid: {stroke: MenuBorderColor}
                }}
              />
              <VictoryLine
              style={{
                data: { stroke: "#FFBF00" }
              }}
                data={forums[0].data}
              />
              <VictoryLine
              style={{
                data: { stroke: "#F38231" }
              }}
                data={forums[1].data}
              />
              <VictoryLine
              style={{
                data: { stroke: "#46329B" }
              }}
                data={forums[2].data}
              />
            </VictoryChart>
            <Label>
              <Title2><Line color={"#FFBF00"}/>Forum Educandos</Title2>
              <Title2><Line color={"#F38231"}/>Forum Educadores</Title2>
              <Title2><Line color={"#46329B"}/>Forum Geral</Title2>
            </Label>
          </Block>
        </Col>
      </RowDashboard>
    </PageContainer>
  );
}

export default AdminDashboard;