import React, { Component } from 'react';
import Table from '../../../components/generic/table/Table';
import { withRouter } from 'react-router-dom';
import { GetFormsByUser } from '../../../infra/requests/FormsRequests';
import moment from 'moment';
import ChangeAnswered from './ChangeAnswered';

class FormsList extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: true,
    columns: [],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();

    this.setState({
      columns: [
        {
          title: 'Nome',
          key: 'name',
          render: (value) => value.form?.name,
          width: '40%'
        },
        {
          title: 'Data de Disponibilidade',
          key: 'availability_date',
          render: (value) => moment(value.availability_date).format('DD-MM-YYYY'),
          width: '25%'
        },
        {
          title: 'Respondeu ao questionário?',
          key: 'answered',
          render: (value) => {
            return(
              <ChangeAnswered answered={value.answered} id={value?._id} />
            )
          },
          width: '20%'
        }
      ],
    })
  }

  updateDataTable = async () => {
    try {
      this.setState({ loading: true });
      
      const { userID } = this.props;
      const result = await GetFormsByUser(userID);
      
      this.setState({
        rows: result?.data || [],
        total: result?.data?.length || 0,
        loading: false
      });
    } 
    catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  }

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
    } = this.state;

    return(
      <React.Fragment>
        <Table
          columns={columns}
          currentPage={currentPage}
          pageSize={pageSize}
          loading={loading}
          rows={rows}
          showHeader={true}
          emptyIcon='warning'
          emptyText={'Não existem questionários inseridos para este utilizador!'}
          total={total}
          rowKey={'_id'}
          hasPagination={false}
        />
      </React.Fragment>
    )
  }
}

export default withRouter(FormsList);