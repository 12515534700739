export const ChapterTypes = [
  { _id: 1, name: 'Vídeo' },
  { _id: 2, name: 'Texto' },
  { _id: 3, name: 'Banda desenhada' },
  { _id: 4, name: 'Quiz' },
  { _id: 5, name: 'Jogo' },
  { _id: 6, name: 'Formulário' },
  { _id: 7, name: 'Áudio' },
  { _id: 8, name: 'Documento' },
  { _id: 9, name: 'Gráfico' },
  { _id: 10, name: 'Avaliação' },
  { _id: 11, name: 'Vídeo Externo' },
  { _id: 12, name: 'Imagem' }
];

export const getChapterType = (value) => {
  if(Number.isInteger(value) && value > 0 && ChapterTypes[value - 1]) return ChapterTypes[value - 1].name;
  return null;
};