import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetHelpItem,
  UpdateHelp,
  CreateHelp
} from '../../infra/requests/HelpRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab,
  Separator
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/generic/inputs/TextInput';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  title: 'required|languages',
  contact: 'required|languages',
  description: 'required|languages',
  organization: 'required|languages',
  schedule: 'required|languages',
  cost: 'required|languages',
});

class ManageHelpPage extends Component {
  state = { 
    isNew: false, 
    loading: true 
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu,
      user,
      history
    } = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 

    CloseMenu();

    if(params.id) {
      const result = await GetHelpItem(params.id);
      dispatch(
        initialize('manage_help_form', {
          ...result.data,
        })
      );

      this.setState({ loading: false });
    } 
    else {
      this.setState({
        isNew: true,
        loading: false,
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;

      const payload = FlattenToFormData(values);
      const { success } = params.id ? await UpdateHelp(params.id, payload) : await CreateHelp(payload);

      if (success) return history.push('/website/help');
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/website/help');
  };

  render() {
    const { handleSubmit, pristine, activeLanguage, languages } = this.props;
    const { isNew, loading } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Tópicos', title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row>
              <Row gutter={24}>
                <Col xs={12} offset={0}>
                  <DefaultLanguageTab>
                    {GetTranslationDescription(activeLanguage?.code)}
                  </DefaultLanguageTab>
                  <Field
                    component={TextInput}
                    name={`title.${activeLanguage?.code}`}
                    type='text'
                    label={'Título *'}
                    placeholder={'Título'}
                  />
                  <Field
                    component={TextInput}
                    name={`contact.${activeLanguage?.code}`}
                    type='text'
                    label={'Contacto *'}
                    placeholder={'Contacto'}
                  />
                  <Field
                    component={TextInput}
                    name={`description.${activeLanguage?.code}`}
                    type='text'
                    label={'O que é *'}
                    placeholder={'O que é'}
                  />
                  <Field
                    component={TextInput}
                    name={`organization.${activeLanguage?.code}`}
                    type='text'
                    label={'Organização Associada *'}
                    placeholder={'Organização Associada'}
                  />
                  <Field
                    component={TextInput}
                    name={`schedule.${activeLanguage?.code}`}
                    type='text'
                    label={'Horário *'}
                    placeholder={'Horário'}
                  />
                  <Field
                    component={TextInput}
                    name={`cost.${activeLanguage?.code}`}
                    type='text'
                    label={'Custo *'}
                    placeholder={'Custo'}
                  />
                </Col>
                <Col xs={12} offset={0}>
                  <Tabs>
                    {languages
                      .filter((x) => !x.active)
                      .map((language) => (
                        <TabPane
                          tab={GetTranslationDescription(language.code)}
                          key={language.code}
                        >
                          <Field
                            component={TextInput}
                            name={`title.${language.code}`}
                            type='text'
                            label={'Título *'}
                            placeholder={'Título'}
                          />
                          <Field
                            component={TextInput}
                            name={`contact.${language.code}`}
                            type='text'
                            label={'Contacto *'}
                            placeholder={'Contacto'}
                          />
                          <Field
                            component={TextInput}
                            name={`description.${language.code}`}
                            type='text'
                            label={'O que é *'}
                            placeholder={'O que é'}
                          />
                          <Field
                            component={TextInput}
                            name={`organization.${language.code}`}
                            type='text'
                            label={'Organização Associada *'}
                            placeholder={'Organização Associada'}
                          />
                          <Field
                            component={TextInput}
                            name={`schedule.${language.code}`}
                            type='text'
                            label={'Horário *'}
                            placeholder={'Horário'}
                          />
                          <Field
                            component={TextInput}
                            name={`cost.${language.code}`}
                            type='text'
                            label={'Custo *'}
                            placeholder={'Custo'}
                          />
                        </TabPane>
                      ))}
                  </Tabs>
                </Col>
              </Row>
              <Separator/>
              <Row gutter={24}>
                <Col xs={12}>
                  <Field
                    component={TextInput}
                    name={'website'}
                    type='text'
                    label={'Website'}
                    placeholder={'Website'}
                  />
                </Col>
                <Col xs={12}>
                  <Field
                    component={TextInput}
                    name={'email'}
                    type='email'
                    label={'Email'}
                    placeholder={'Email'}
                  />
                </Col>
              </Row>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageHelpPage = reduxForm({
  form: 'manage_help_form',
  validate: validations,
})(ManageHelpPage);

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageHelpPage));