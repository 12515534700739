import styled from "styled-components";
import { Link } from "react-router-dom";
import { Form, Card } from "antd";
import { MenuBorderColor } from "../../styles/Colours";
import { device } from "../../styles/Responsive";

export const LoginContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const LoginForm = styled(Form)`
  display: flex;
  justify-content: center;
`;

export const LoginCard = styled(Card)`
  &&& {
    background-color: ${MenuBorderColor};
    max-width: 450px;
    padding: 30px;
    display: inline-block;
    border-radius: 5px;
  }
`;

export const LoginButtonDiv = styled.div`
  float: right;
  margin-top: 70px;
`;

export const FormTitle = styled.div`
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
`;

export const BaseLink = styled(Link)`
  margin-top: 30px;
  display: inline-block;
`;

export const StyledLogoContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 30px;

  @media ${device.tabletS} {
    margin-left: 0px;
  }
`;

export const StyleLogoImg = styled.img`
  width: 100px;
`;

export const StyleTitleContainer = styled.div`
  position: absolute;
  top: 30px;
  left: 55px;
  text-align: left;
`;

export const StyleHeaderTitle = styled.div`
  color: ${p => p.theme.primaryColor};
  font-size: 15px;
  line-height: 15px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const StyleHeaderSubtitle = styled.div`
  color: ${p => p.theme.primaryColor};
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
`;