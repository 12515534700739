import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { TableFilterSection } from '../../styles/BasicStyles';
import TextInput from '../../components/generic/inputs/TextInput';

class VideosFilters extends Component {
  state = { 
    name: ''
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map((key) => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(JSON.stringify(query));
  };

  render() {
    const { name } = this.state;

    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} md={8}>
            <TextInput
              input={{
                value: name,
                onChange: (event) =>
                  this.onInputChange('name', event.target.value, 1000),
              }}
              meta={{ valid: true }}
              type='text'
              label='Pesquisar'
              placeholder='Procurar por nome'
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

export default VideosFilters;