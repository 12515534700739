import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col, notification } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab,
  TabsContainer,
  Tab
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import TextInput from '../../components/generic/inputs/TextInput';
import DraftInput from '../../components/generic/inputs/DraftInput';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";
import { GetMind, UpdateMind } from '../../infra/requests/MindRequests';
import FormsList from './components/FormsList';
const TabPane = Tabs.TabPane;

const id = '60742168bbfa7016e4da33c4';

const validations = FormValidator.make({
  title: 'required|languages',
  description: 'required|languages'
});

class ManagerTestMindPage extends Component {
  state = {  
    loading: true ,
    defaultTab: 'details',
    formsList: []
  };

  componentDidMount = async () => {
    const {
      dispatch,
      CloseMenu,
      user,
      history
    } = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 

    CloseMenu();

    const result = await GetMind(id);
    dispatch(
      initialize('manage_mind_form', {
        ...result.data,
      })
    );

    this.setState({ 
      loading: false,
      formsList: result?.data?.forms || []
    });
  };

  onSubmit = async (values) => {
    try {
      const { dispatch } = this.props;

      this.setState({ loading: true });
      
      const { data, success } = await UpdateMind(id, values);

      if(success) {
        dispatch(
          initialize('manage_mind_form', {
            ...data,
          })
        );

        notification.success({
          message: "Registo alterado com sucesso!"
        });
      }
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  renderDetails = () => {
    const { handleSubmit, activeLanguage, languages } = this.props;

    return (
      <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
        <Row>
          <Row gutter={24}>
            <Col xs={12} offset={0}>
              <DefaultLanguageTab>
                {GetTranslationDescription(activeLanguage?.code)}
              </DefaultLanguageTab>
              <Field
                component={TextInput}
                name={`title.${activeLanguage?.code}`}
                type='text'
                label={'Título *'}
                placeholder={'Título'}
              />
              <Field
                component={DraftInput}
                name={`description.${activeLanguage?.code}`}
                label={'Descrição *'}
                placeholder={'Descrição'}
                toolbar='basic'
              />
            </Col>
            <Col xs={12} offset={0}>
              <Tabs>
                {languages
                  .filter((x) => !x.active)
                  .map((language) => (
                    <TabPane
                      tab={GetTranslationDescription(language.code)}
                      key={language.code}
                    >
                      <Field
                        component={TextInput}
                        name={`title.${language.code}`}
                        type='text'
                        label={'Título *'}
                        placeholder={'Título'}
                      />
                      <Field
                        component={DraftInput}
                        name={`description.${language.code}`}
                        label={'Descrição *'}
                        placeholder={'Descrição'}
                        toolbar='basic'
                      />
                    </TabPane>
                  ))}
              </Tabs>
            </Col>
          </Row>
        </Row>
      </BaseForm>
    )
  }

  render() {
    const { handleSubmit, pristine } = this.props;
    const { loading, defaultTab, formsList } = this.state;

    if(loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Testa a Tua Mente', 'Editar']}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            }
          ]}
        />
        <FormContainer>
          <TabsContainer defaultActiveKey={defaultTab}>
            <Tab 
              tab='Detalhes'
              key='details'
            >
              {this.renderDetails()}
            </Tab>
            <Tab
              tab='Formulários'
              key='forms'
            >
              <FormsList list={formsList} />
            </Tab>
          </TabsContainer>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManagerTestMindPage = reduxForm({
  form: 'manage_mind_form',
  validate: validations,
})(ManagerTestMindPage);

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManagerTestMindPage));