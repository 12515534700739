import PT from './portugal_flag.svg';
import EN from './uk_flag.svg';

export const GetAvailableLanguages = () => ['pt', 'en'];

export const Languages = [
  { code: 'pt', name: 'Português', icon: PT },
  { code: 'en', name: 'Inglês', icon: EN }
];

export const GetTranslationDescription = (code) =>
  Languages.find((x) => x.code === code)?.name;

export const TranslateFromObject = (object, code) => {
  if (!object) return '';
  if (object[code]) return object[code];
  if (object['pt']) return object['pt'];
  if (Object.keys(object).length) return object[Object.keys(object)[0]];
  return object;
};
