import React from 'react';
import PropTypes from 'prop-types';
import { Icon, notification } from 'antd';
import Dropzone from 'react-dropzone';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import { 
  FileSection,
  InputLabelDiv, 
  ResponsePill, 
  ExtraPill,
  FilesAllowed
} from './InputStyles';

const { REACT_APP_IMAGES_URL } = process.env;

const FilesInput = ({ label, input, accept, maxSize, acceptText }) => {
  const openFile = (id) =>
    window.open(`${REACT_APP_IMAGES_URL}${id}`, '_blank');

  const buildImageObject = (blob) => ({
    preview: URL.createObjectURL(blob),
    size: blob.size,
    type: blob.type,
    blob: blob,
  });

  const handleDrop = (accepted, rejected) => {
    if(accepted.length <= 0 || (rejected && rejected.length > 0)) {
      return notification.error({
        message: 'Ficheiro Inválido',
        description: `O ficheiro é inválido ou tem um tamanho superior a ${maxSize ? maxSize.toString().substr(0, 2) : '16'}Mb`,
      });
    }

    const uploaded = buildImageObject(accepted[0]);
    input.onChange(uploaded);
  };

  const renderDropZone = () => {
    return (
      <Dropzone
        multiple={false}
        onDrop={handleDrop}
        accept={accept}
        maxSize={maxSize ? maxSize : 16000000}
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <React.Fragment>
              <FileSection {...getRootProps()}>
                <input {...getInputProps()} />
                <Icon type='file'/>&nbsp;Clique ou arraste um ficheiro
              </FileSection>
              { acceptText && <FilesAllowed>{acceptText}</FilesAllowed> }
            </React.Fragment>
          );
        }}
      </Dropzone>
    );
  };

  if(input.value) {
    const saved = input.value._id;
    const filename = TranslateValue(
      saved ? input.value?.filename : input.value?.blob?.name
    );
    const size = (
      (saved ? input.value?.length : input.value?.size) /
      (1024 * 1024)
    ).toFixed(2);

    return (
      <div>
        { label && <InputLabelDiv style={{marginBottom: '10px'}}>{label}</InputLabelDiv> }
        <ResponsePill single={false}>{filename}</ResponsePill>
        <ExtraPill>{size}MB</ExtraPill>
        {
          saved &&
          <React.Fragment>
            <ExtraPill last link onClick={() => openFile(input.value._id)}>
              Abrir ficheiro
            </ExtraPill>
          </React.Fragment>
        }
        {renderDropZone()}
      </div>
    );
  }

  return (
    <div>
      { label && <InputLabelDiv style={{marginBottom: '10px'}}>{label}</InputLabelDiv> }
      {renderDropZone()}
    </div>
  )
};

FilesInput.propTypes = {
  input: PropTypes.object.isRequired,
  accept: PropTypes.string.isRequired,
  maxSize: PropTypes.number,
  label: PropTypes.string
};

export default FilesInput;