import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Row, Col, notification } from 'antd';
import { withLocalize } from 'react-localize-redux';
import moment from 'moment';
import {
  GetThread,
  GetSubComment,
  UpdateSubComment
} from '../../../infra/requests/ForumRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading
} from '../../../styles/BasicStyles';
import {
  InfoContainer
} from '../Styles';
import FormValidator from '../../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../../components/base/ManageFormHeader';
import TextAreaInput from '../../../components/generic/inputs/TextAreaInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../../redux/Menu/menu.actions';
import { getForum } from '../../../infra/utils/ForumTypes';

const validations = FormValidator.make({
  description: 'required'
});

class ManageSubCommentsPage extends Component {
  state = { 
    loading: true,
    thread: undefined,
    subcomment: undefined,
    forumName: '',
    forumID: ''
  };

  componentDidMount = async () => {
    const {user, history} = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 
      
    await this.loadDetail();
  };

  loadDetail = async () => {
    const {
      match: { params },
      history,
      dispatch,
      CloseMenu
    } = this.props;

    const forumID = params.forum;
    const forum = getForum(forumID);
    const forumName = forum?.name || '';

    if(!forumName) {
      return history.push('/');
    }
    
    CloseMenu();

    const thread = await GetThread(params.thread);
    const result = await GetSubComment(params.comment, params.id);

    dispatch(
      initialize('manage_subcomments_form', {
        ...result.data,
      })
    );

    this.setState({ 
      loading: false,
      thread: thread?.data,
      subcomment: result?.data,
      forumName,
      forumID
    });
  }

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const { match: { params } } = this.props;

      const { success } = await UpdateSubComment(params.comment, params.id, values);

      if(success) {
        notification.success({
          message: "Sucesso!",
          description: "Alterações guardadas com sucesso."
        });
        return await this.loadDetail();
      }

      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history, match: { params } } = this.props;
    const { forumID, thread } = this.state;
    return history.push(`/forum/${forumID}/${thread?._id}/${params.comment}?comments=true`);
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { loading, thread, forumName, subcomment } = this.state;

    if(loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={[`Fórum ${forumName}`, thread?.title, 'Comentários', 'Respostas', 'Editar']}
          titlePosition={1}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row>
              <Row gutter={24} style={{ marginTop: '10px' }}>
                <Col xs={18} offset={3}>
                  <InfoContainer>
                    Autor: <span>{subcomment?.author.name}</span>
                  </InfoContainer>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={18} offset={3}>
                <InfoContainer>
                    Data Criação: <span>{moment.utc(subcomment?.createdAt).format('DD/MM/YYYY, HH:mm')}h</span>
                  </InfoContainer>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={18} offset={3}>
                  <Field
                    component={TextAreaInput}
                    name='description'
                    label={'Descrição *'}
                    placeholder={'Descrição'}
                    minRows={6}
                  />
                </Col>
              </Row>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageSubCommentsPage = reduxForm({
  form: 'manage_subcomments_form',
  validate: validations,
})(ManageSubCommentsPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageSubCommentsPage));