export default [
  {
    type: 'menu',
    name: 'Informações Gerais',
    to: '/website',
    icon: 'read',
    exact: true,
  },
  {
    type: 'submenu',
    name: 'Home',
    baseUrl: '/website/home',
    to: [
      {
        type: 'menu',
        name: 'Banner',
        to: '/website/home/banner',
        icon: 'picture',
        exact: true,
      },
      {
        type: 'menu',
        name: 'Plataforma',
        to: '/website/home/platform',
        icon: 'desktop',
        exact: false,
      },
      {
        type: 'menu',
        name: 'Programas',
        to: '/website/home/programs',
        icon: 'folder',
        exact: false,
      },
      {
        type: 'menu',
        name: 'Testar a Mente',
        to: '/website/home/mind',
        external_icon: true,
        icon: 'mind.svg',
        exact: true,
      },
      {
        type: 'menu',
        name: 'Testemunhos',
        to: '/website/home/testimonials',
        external_icon: true,
        icon: 'testimonials.svg',
        exact: false,
      }
    ],
    icon: 'home',
    exact: false,
  },
  {
    type: 'submenu',
    name: 'Sobre',
    baseUrl: '/website/about',
    to: [
      {
        type: 'menu',
        name: 'Projecto',
        to: '/website/about/project',
        icon: 'file-text',
        exact: false,
      },
      {
        type: 'subsubmenu',
        name: 'Programas',
        baseUrl: '/website/about/programs',
        to: [
          {
            type: 'menu',
            name: 'Introdução',
            to: '/website/about/programs/intro',
            icon: 'file-text',
            exact: true,
          },
          {
            type: 'menu',
            name: 'Programas',
            to: '/website/about/programs/programs',
            icon: 'folder-open',
            exact: true,
          },
          {
            type: 'menu',
            name: 'Lemas',
            to: '/website/about/programs/mottos',
            icon: 'star',
            exact: false,
          },
          {
            type: 'menu',
            name: 'Critérios',
            to: '/website/about/programs/criteria',
            icon: 'file-text',
            exact: true,
          }
        ],
        icon: 'folder',
        exact: true,
      },
      {
        type: 'menu',
        name: 'Base Científica',
        to: '/website/about/base',
        icon: 'file-text',
        exact: false,
      },
      {
        type: 'subsubmenu',
        name: 'Artigos',
        baseUrl: '/website/about/articles',
        to: [
          {
            type: 'menu',
            name: 'Introdução',
            to: '/website/about/articles/intro',
            icon: 'file-text',
            exact: true,
          },
          {
            type: 'menu',
            name: 'Categorias',
            to: '/website/about/articles/categories',
            icon: 'folder-open',
            exact: true,
          },
          {
            type: 'menu',
            name: 'Artigos',
            to: '/website/about/articles',
            icon: 'bars',
            exact: true,
          }
        ],
        icon: 'folder',
        exact: true,
      },
      {
        type: 'subsubmenu',
        name: 'Instrumentos',
        baseUrl: '/website/about/instruments',
        to: [
          {
            type: 'menu',
            name: 'Introdução',
            to: '/website/about/instruments/intro',
            icon: 'file-text',
            exact: true,
          },
          {
            type: 'menu',
            name: 'Categorias',
            to: '/website/about/instruments/categories',
            icon: 'folder-open',
            exact: true,
          },
          {
            type: 'menu',
            name: 'Instrumentos',
            to: '/website/about/instruments',
            icon: 'bars',
            exact: true,
          }
        ],
        icon: 'folder',
        exact: true,
      },
      {
        type: 'subsubmenu',
        name: 'Temas',
        baseUrl: '/website/about/themes',
        to: [
          {
            type: 'menu',
            name: 'Introdução',
            to: '/website/about/themes/intro',
            icon: 'file-text',
            exact: true,
          },
          {
            type: 'menu',
            name: 'Categorias',
            to: '/website/about/themes/categories',
            icon: 'folder-open',
            exact: true,
          },
          {
            type: 'menu',
            name: 'Temas',
            to: '/website/about/themes',
            icon: 'bars',
            exact: true,
          }
        ],
        icon: 'folder',
        exact: true,
      },
      {
        type: 'menu',
        name: 'Equipa',
        to: '/website/about/team',
        icon: 'team',
        exact: false,
      }
    ],
    icon: 'about.svg',
    external_icon: true,
    exact: false,
  },
  {
    type: 'menu',
    name: 'Notícias',
    to: '/website/news',
    icon: 'news.svg',
    external_icon: true,
    exact: false,
  },
  {
    type: 'menu',
    name: 'Teste a sua Mente',
    to: '/website/mind',
    external_icon: true,
    icon: 'mind.svg',
    exact: true,
  },
  {
    type: 'menu',
    name: 'Ajuda',
    to: '/website/help',
    icon: 'help.svg',
    external_icon: true,
    exact: false,
  },
  {
    type: 'menu',
    name: 'Faqs',
    to: '/website/faqs',
    icon: 'question-circle',
    exact: false,
  },
  {
    type: 'menu',
    name: 'Páginas Dinâmicas',
    to: '/website/dynamic-pages',
    icon: 'copy',
    exact: false,
  },
  {
    type: 'menu',
    name: 'Metatags',
    to: '/website/metatags',
    icon: 'tags',
    exact: false,
  },
  {
    type: 'menu',
    name: 'Traduções',
    to: '/website/translations',
    icon: 'global',
    exact: true,
  }
];