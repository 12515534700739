import React, { Component } from 'react';
import { Popconfirm, Icon } from 'antd';
import { connect } from 'react-redux';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton,
} from '../../styles/BasicStyles';
import BaseButton from '../../components/generic/buttons/BaseButton';
import UsersRegisterFilters from './UsersRegisterFilters';
import Table from '../../components/generic/table/Table';
import { CompleteRegister, GetUsersRegister, DeleteUserRegister } from '../../infra/requests/UsersRegisterRequests';
import moment from 'moment';
import { getUserRegisterTypes } from '../../infra/utils/UserRegisterTypes';

class UsersRegisterPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: false,
    columns: [
      {
        title: 'Tipo',
        dataIndex: 'type',
        render: (value) => getUserRegisterTypes(value),
        width: '18%'
      },
      {
        title: 'Nome',
        dataIndex: 'name',
        width: '20%'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        width: '20%'
      },
      {
        title: 'Data do Pedido',
        dataIndex: 'request_date',
        render: (value) => moment(value).format('DD-MM-YYYY HH:mm'),
      },
      {
        title: 'Completo',
        dataIndex: 'completed',
        render: (value) => value ? 'Sim' : 'Não',
      },
      {
        title: '',
        render: (data) => (
          <React.Fragment>
            <TableButton onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement='topRight'
                title={
                  !data.completed
                    && 'Quer adicionar este utilizador?'
                }
                onConfirm={() => this.completeRegister(data)}
              >
                <Icon type={!data.completed && 'check-circle'} />
                {!data.completed && ' Adicionar utilizador'}
              </Popconfirm>
            </TableButton>
            <TableButton error onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement='topRight'
                title='Tem a certeza que quer remover o utilizador?'
                onConfirm={() => this.removeUser(data)}
              >
                <Icon style={{ marginRight: 10 }} type='delete' />
                {'Remover'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        ),
      },
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    const {user, history} = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 

    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/users-register/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  removeUser = async (data) => {
    try {
      await DeleteUserRegister(data._id);
      await this.updateDataTable();
    } 
    catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;

      const result = await GetUsersRegister(currentPage, pageSize, filters);

      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false
      });
    } 
    catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  completeRegister = async (record) => {
    this.setState({ loading: true });
    await CompleteRegister(record._id, { completed: !record.completed, email: record.email });
    await this.updateDataTable();
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Lista de Pedidos de Registo</PageTitle>
            {`Pedidos de Registo`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'default'}
              icon={'sync'}
              text={'Atualizar'}
              onClick={() => this.updateDataTable()}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <UsersRegisterFilters queryChange={this.handleFilterChange}/>
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Pedidos de Registo inseridos!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({ 
  user: state.user
});

export default connect(mapStateToProps, null)(UsersRegisterPage);
