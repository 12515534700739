import React, { Component } from 'react';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle
} from '../../styles/BasicStyles';
import Table from '../../components/generic/table/Table';
import { GetAudio } from '../../infra/requests/AudioRequests';
import { getStepType } from '../../infra/utils/StepTypes';
import { connect } from 'react-redux';

class AudioPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: true,
    columns: [
      {
        title: 'Ordem',
        dataIndex: 'order',
        render: (value) => value,
        width: '30%',
      },
      {
        title: 'Tipo',
        dataIndex: 'type',
        render: (value) => getStepType(value),
        width: '70%',
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    const {user, history} = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 
    
    await this.updateDataTable();
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const result = await GetAudio();

      let rowsA = []
      let rowsB = []
      if (result.data) {
        rowsA = result.data.filter(d => d.user_type === 4)
        rowsB = result.data.filter(d => d.user_type === 3)
      }
      this.setState({
        rows: [rowsA, rowsB]
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/audio/${record._id}`);
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
    } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Lista de Counteúdos dos Audios</PageTitle>
            {`Counteúdos dos Audios`}
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <div style={{marginBottom: 12, textAlign: 'left', fontSize: 16}}>
            Áudios Educandos
          </div>
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows[0]}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem conteúdos para os Áudios Educandos!'}
            total={total}
            rowKey={'_id'}
            hasPagination={false}
            onPressRow={this.handleOnPressRow}
          /> 
          <div style={{marginBottom: 12, marginTop: 32, textAlign: 'left', fontSize: 16}}>
            Áudios Educadores
          </div>
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows[1]}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem conteúdos para os Áudios Educadores!'}
            total={total}
            rowKey={'_id'}
            hasPagination={false}
            onPressRow={this.handleOnPressRow}
          />           
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, null)(AudioPage);