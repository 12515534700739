import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { TableFilterSection } from '../../styles/BasicStyles';
import TextInput from '../../components/generic/inputs/TextInput';
import SelectInput from '../../components/generic/inputs/SelectInput';

class ClassesFilters extends Component {
  state = { 
    search: '',
    school: ''
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map((key) => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(JSON.stringify(query));
  };

  render() {
    const { search, school } = this.state;
    const { schools } = this.props;

    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} md={8}>
            <TextInput
              input={{
                value: search,
                onChange: (event) =>
                  this.onInputChange('search', event.target.value, 1000),
              }}
              meta={{ valid: true }}
              type='text'
              label='Pesquisar'
              placeholder='Procurar por nome'
            />
          </Col>
          <Col xs={24} md={8}>
            <SelectInput
              label='Escola'
              allowClear
              placeholder='Escolher escola'
              data={schools}
              dataKey='_id'
              dataLabel='name'
              input={{
                value: school,
                onChange: value => this.onInputChange('school', value)
              }}
              meta={{valid: true}}
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

export default ClassesFilters;