import PropTypes from 'prop-types';
import React from 'react';
import { InputDiv, InputLabelDiv, DateInputField } from './InputStyles';
import moment from 'moment';

const DateInput = ({
  input,
  meta,
  label,
  first,
  placeholder,
  disabled,
  dateFormat,
  hasDefaultValue,
  picker
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed ? 1 : 0;

  return (
    <InputDiv first={first}>
      {label && (
        <InputLabelDiv>{label}</InputLabelDiv>
      )}
      <DateInputField
        disabled={disabled}
        error={showError}
        placeholder={placeholder}
        format={dateFormat || 'DD-MM-YYYY'}
        defaultValue={input.value ? moment(input.value) : null}
        onChange={(m, string) => input.onChange && input.onChange(m, string)}
        picker={picker}
        size={52}
        locale='pt'
      />
    </InputDiv>
  );
};

DateInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  picker: PropTypes.string,
};

export default DateInput;
