import React, { Component } from 'react';
import Table from '../../../components/generic/table/Table';
import { withRouter } from 'react-router-dom';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import { getChapterType } from '../../../infra/utils/ChapterTypes';

class ChaptersList extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: true,
    columns: [],
    rows: [],
    total: 0
  };

  componentDidMount = () => {
    const { list, admin } = this.props;

    this.setState({
      columns: [
        {
          title: 'Título',
          dataIndex: 'title',
          render: (value) => TranslateValue(value),
          width: '50%',
        },
        {
          title: 'Tipo',
          dataIndex: 'type',
          render: (value) => getChapterType(value),
          width: '25%',
        },
        {
          title: 'Capítulo Completo?',
          dataIndex: 'completed',
          render: (value) => value === true ? 'Sim' : 'Não',
          width: '25%',
        },
      ],
    })

    this.setState({
      rows: list || [],
      total: list?.length || 0,
      loading: false
    });
  };

  handleOnPressRow = (record) => {
    const { history, applicator, userId, sessionInfo, admin } = this.props;
    
    if(applicator) {
      history.push({pathname: `/users/students/${userId}/chapters/${record._id}`, state: {sessionInfo: sessionInfo, chapterInfo: record}});
    } else if(admin) {
      history.push({pathname: `/users/${userId}/chapters/${record._id}`, state: {sessionInfo: sessionInfo, chapterInfo: record, admin: admin}});
    } else {
      history.push(`/programs/chapters/${record._id}`);
    }
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
    } = this.state;
    const {applicator, admin} = this.props;

    return (
      <Table
        secondary={applicator || admin}
        columns={columns}
        currentPage={currentPage}
        pageSize={pageSize}
        loading={loading}
        rows={rows}
        showHeader={true}
        emptyIcon='warning'
        emptyText={'Não existem Capítulos inseridos!'}
        total={total}
        rowKey={'_id'}
        hasPagination={false}
        onPressRow={this.handleOnPressRow}
      />
    );
  }
}

export default withRouter(ChaptersList);