import styled from 'styled-components';

export const Answer = styled.div`
  font-size: 15px;
  color: ${(p) => p.theme.inputBorderColor};
  font-weight: 500;
  padding: 8px;

  & span {
    color: ${(p) => p.color ? p.color : '#000000'};
    font-weight: 700;
  }
`;