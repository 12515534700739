import React, { Component } from 'react';
import { connect } from 'react-redux';
import BaseButton from '../../components/generic/buttons/BaseButton';
import { HeaderButtonsContainer, HeaderContainer, HeaderTitle, PageTitle, SpinLoading } from '../../styles/BasicStyles';
import { GetAnswers, GetAnswersWithDate, DownloadUserInfo } from '../../infra/requests/UsersRequests.js';
import { Col, Row, Slider } from 'antd';
import { AnswersContainer, QuestionBox, QuestionTitle, QuestionAnswer, BoxDate, BoxCol1, BoxCol2, CalendarContainer, ActivityBox, ActivitiesContainer, ActivitiesBox, ObjectiveCol, AnswerBox } from './UserStyles';
import { ErrorBorderColor, InputBorderColour, SecondaryColour, SuccessBorderColor, ThirdColour } from '../../styles/Colours';
import moment from 'moment';
import WeekInput from '../../components/generic/inputs/WeekInput';
import { getGoalType } from '../../infra/utils/GoalTypes';

const daysOfWeek = [
  'DOMINGO',
  'SEGUNDA',
  'TERÇA',
  'QUARTA',
  'QUINTA',
  'SEXTA',
  'SABADO'
]

class ApplicatorChaptersPage extends Component {
  state = {
    sessionName: '',
    chapterName: '',
    loading: true,
    info: undefined,
    infoItem: {},
    week: moment(),
    days: [],
    date: 'DD-MM-YYYY',
    indexSelect: 0
  };

  componentDidMount = async () => {
    this.setState({
      sessionName: this.props.location.state.sessionInfo?.title?.pt || this.props.location.state.sessionInfo?.title?.en,
      chapterName: this.props.location.state.chapterInfo?.title?.pt || this.props.location.state.chapterInfo?.title?.en,
      admin: this.props.location.state.admin
    });
    this.onChangeWeek();
    await this.updateData();
  };

  handleOnCancel = async () => {
    const {
      match: { params }, 
      history 
    } = this.props;
    const { admin } = this.state;

    if(admin) {
      return history.push(`/users/${params.id}?program=true`);
    } else {
      return history.push(`/users/students/${params.id}?program=true`);
    }
  };

  updateData = async () => {
    this.setState({ loading: true });
    try {
      const {match: { params }} = this.props;
      const {days} = this.state;
      
      let result = undefined;
      if(days?.length > 0) {
        result = await GetAnswersWithDate(params.id, params.chapterId, days[0].date, days[6].date);
      } else {
        result = await GetAnswers(params.id, params.chapterId);
      }
      
      this.setState({
        info: result?.data,
        infoItem: result?.data?.data[0] || {},
        loading: false,
      });
    } 
    catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  downloadReport = async (allData) => {
    const { chapterName, sessionName, days, info } = this.state;
    const {
      match: { params }, 
      history 
    } = this.props;

    try {
      if(params.id && params.chapterId) {

        const data = allData 
          ? {id: params.id, sessionName, chapterName, chapterId: params.chapterId, type: info.type}
          : days && days.length > 0 
          ? {id: params.id, sessionName, chapterName, chapterId: params.chapterId, startDate: days[0].date, endDate: days[6].date, type: info.type}
          : {id: params.id, sessionName, chapterName, chapterId: params.chapterId, type: info.type}
        
         console.log('data', data)
        const result = await DownloadUserInfo(data);

        if(result) {
          let filename = `Utilizador_${params.id}_${sessionName}_${chapterName}.xlsx`;
          if(result.headers) {
            const contentDisposition = result.headers.get('content-disposition');
            filename = contentDisposition.split('filename="')[1].split('";')[0];
          }

          //Create blob link to download
          const url = window.URL.createObjectURL(new Blob([result.blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);

          //Append to html link element page
          document.body.appendChild(link);
          
          //Start download
          link.click();
          
          //Clean up and remove the link
          link.parentNode.removeChild(link);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  clickDate = (item, index) => {

    this.setState({
      infoItem: item,
      indexSelect: index
    })
  }

  onChangeWeek = () => {
    const {week} = this.state
    const weekStart = week.clone().startOf('week')
    const d = []
    for (let i = 1; i <= 7; i++) {
      const day = moment(weekStart).add(i, 'days')
      d.push({
        dayOfWeek: daysOfWeek[day.format('d')],
        dayOfWeekSmall: day.format('ddd'),
        day: day.format('D'),
        date: day.format('YYYY-MM-DD')
      })
    }
    this.setState({days: d}, () => this.updateData())
  }

  onchangeDate = (date) => {
    if (!date) return
    this.setState(
      {
        week: date, 
        date: this.customFormat(date)
      },
      () => this.onChangeWeek()
    )
  }

  customFormat = (value) => {
    const dateWeekStart = value.clone().startOf('week')
    const dateWeekEnd = value.clone().endOf('week')
    return `${dateWeekStart.format('ddd, D MMM')} - ${dateWeekEnd.format(
      'ddd, D MMM'
    )}`
  }

  disabledDate = (current) => {
    const weekEnd = moment()
      .clone()
      .endOf('week')
    return current && current > weekEnd
  }

  render() {
    const { sessionName, chapterName, info, infoItem, days, week, loading, indexSelect } = this.state;

    if(loading) return <SpinLoading />
console.log(info)
    return(
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Respostas</PageTitle>
            {`${sessionName} - ${chapterName}`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={4}>
            <BaseButton 
              type={'default'}
              icon={'close'}
              text={'Cancelar'}
              onClick={() => this.handleOnCancel()}
            />
            {info?.type === 'activities_calendar' || info?.type === 'diary_objectives' ? (
              <>
                <BaseButton
                  type={'secondary'}
                  icon={'download'}
                  text={'Exportar por data'}
                  onClick={() => this.downloadReport()}
                />
                <BaseButton
                  type={'secondary'}
                  icon={'download'}
                  text={'Exportar tudo'}
                  onClick={() => this.downloadReport(true)}
              />
              </>
            ) : info?.data?.length > 0 ? (
              <BaseButton
                type={'secondary'}
                icon={'download'}
                text={'Exportar'}
                onClick={() => this.downloadReport(true)}
              />
            ) : null}
          </HeaderButtonsContainer>
        </HeaderContainer>
        <AnswersContainer>
          {info?.type === 'question' && (
            <>
              {info?.data?.length > 0 ? (
                <Row gutter={[16,16]} display='flex'>
                  {info?.data.map((item, index) => (
                    <div key={index}>
                    {item?.answerType === 'slider' ? (
                      <Col xs={24}>
                        <QuestionBox>
                          <QuestionTitle style={{marginBottom: 32}}>{item?.question}</QuestionTitle>
                          <Slider 
                            tooltipVisible={true}
                            trackStyle={{
                              backgroundColor: ThirdColour
                            }}
                            dotStyle={{display: 'none'}}
                            // min={item?.sliderMin} 
                            // max={item?.sliderMax}
                            min={0} 
                            max={10}
                            value={item?.answer}  
                          />
                          <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div style={{maxWidth: 100}}>
                            {item?.sliderMinText ? item?.sliderMinText : item?.sliderMin}
                            </div>
                            <div style={{maxWidth: 100}}>
                            {item?.sliderMaxText ? item?.sliderMaxText : item?.sliderMax}
                            </div>
                          </div>
                        </QuestionBox>
                      </Col>
                    ) : (
                      <Col xs={24} xl={12}>
                        <QuestionBox color={(item?.hasCorrect && item?.answer) ? item?.isCorrect ? SuccessBorderColor : ErrorBorderColor : !item?.answer ? SecondaryColour : InputBorderColour}>
                          <QuestionTitle>{item?.question}</QuestionTitle>
                          {(item?.hasCorrect && item?.answer) ? (
                            <div>
                              {item?.isCorrect ? (
                                <QuestionAnswer color={item?.isCorrect ? SuccessBorderColor : ErrorBorderColor}>Resposta do utilizador: <span>{item?.answer}</span></QuestionAnswer>
                              ) : (
                                <div>
                                  <QuestionAnswer color={item?.isCorrect ? SuccessBorderColor : ErrorBorderColor}>Resposta do utilizador: <span>{item?.answer}</span></QuestionAnswer>
                                  <QuestionAnswer >Resposta correta: <span>{item?.correctAnswer}</span></QuestionAnswer>
                                </div>
                              )}
                            </div>
                          ) : (
                            <>
                              { item?.selectedAnswerText?.length > 0 ? (
                                 <>
                                  <QuestionAnswer color={!item?.answer ? SecondaryColour : '#000000'} >
                                    Resposta do utilizador:
                                  </QuestionAnswer>
                                  { item.selectedAnswerText.map((selectedAnswer,index) => (
                                     <QuestionAnswer color={!item?.answer ? SecondaryColour : '#000000'} >
                                     <span key={index}>{selectedAnswer} </span>
                                     </QuestionAnswer>
                                     
                                    ))
                                  }
                                  </>
                               ) 
                              : 
                               (
                                <QuestionAnswer color={!item?.answer ? SecondaryColour : '#000000'} >
                                  Resposta do utilizador:
                                 <span>{item?.answer ? item?.answer : 'Sem Resposta!'}</span>
                                </QuestionAnswer>
                               )
                             } 
                             { item?.answerDescription &&
                               <QuestionAnswer>
                                Descrição:  
                                <span> {item?.answerDescription} </span>
                                </QuestionAnswer>
                             }
                            </>
                          )}
                        </QuestionBox>
                      </Col>
                    )}
                    </div>
                  ))}
                </Row>
              ) : (
                <AnswersContainer>
                  Não existem respostas para este capítulo!
                </AnswersContainer>
              )}
            </>
          )}
          {info?.type === 'diary' && (
            <>
              {info?.data?.length > 0 ? (
                <QuestionBox row padding='0'>
                  <Col sm={5} xl={5} style={{overflowY: 'auto'}}>
                    {info?.data.map((item, index) => (
                      <BoxCol1 key={index}>
                        <BoxDate index={indexSelect === index} onClick={() => this.clickDate(item, index)}>{moment(item?.date).format('DD-MM-YYYY HH:mm')}</BoxDate>
                      </BoxCol1>
                    ))}
                  </Col>
                  <BoxCol2 sm={19} xl={19}>
                    <QuestionAnswer marginB marginT>Data e hora: <span>{moment(infoItem?.date).format('DD-MM-YYYY HH:mm')}</span></QuestionAnswer>
                    {infoItem?.answers && infoItem?.answers.length > 0 && infoItem?.answers.map((item, index) =>  (
                        <div key={index} style={{marginBottom: 24}}>
                          <QuestionTitle>{item?.question}</QuestionTitle>
                          {item?.answerType === 'text' && (
                            <QuestionAnswer>Resposta do utilizador: <span>{item?.answer}</span></QuestionAnswer>
                          )}
                          {item?.answerType === 'array' && (
                            <div style={{display: 'flex'}}>
                              <QuestionAnswer>Resposta do utilizador:</QuestionAnswer>
                              <div style={{marginLeft: 5}}>
                                {item?.answer.map((answer, i) => (
                                  <QuestionAnswer key={i}><span>{answer}</span></QuestionAnswer>
                                ))}
                              </div>
                            </div>
                          )}
                          {item?.answerType === 'slider' && (
                            <div style={{marginTop: 32}}>
                              <Slider 
                                // tooltipVisible={true}
                                trackStyle={{
                                  backgroundColor: ThirdColour
                                }}
                                dotStyle={{display: 'none'}}
                                min={item?.sliderMin} 
                                max={item?.sliderMax}
                                value={item?.answer}
                                marks={item.answer ? {
                                  [item.answer]: item.answer
                                } : {}} 
                              />
                              <div style={{display: 'flex', justifyContent: 'space-between', marginTop: -15}}>
                                <div style={{maxWidth: 100}}>
                                {item?.sliderMinText ? item?.sliderMinText : item?.sliderMin}
                                </div>
                                <div style={{maxWidth: 100}}>
                                {item?.sliderMaxText ? item?.sliderMaxText : item?.sliderMax}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                    ))}
                    {(infoItem?.week1 || infoItem?.week2 || infoItem?.week3) && (
                      <Row>
                        <Col xs={24}>
                          <QuestionTitle style={{textAlign: 'center'}}>Semana 1</QuestionTitle>
                          <ObjectiveCol>
                            {infoItem?.week1 && infoItem?.week1.map((item, index) => (
                              <AnswerBox last={index === infoItem?.week2?.length - 1}>
                                <QuestionAnswer>Objetivo: <span>{item?.objective}</span></QuestionAnswer>
                                <QuestionAnswer>Desafio: <span>{item?.challenge}</span></QuestionAnswer>
                                <QuestionAnswer 
                                  color={
                                    item?.goal === 1 
                                      ? SuccessBorderColor 
                                      : item?.goal === 2 
                                      ? '#FFD60F' 
                                      : item?.goal === 3 
                                      ? ErrorBorderColor 
                                      : SecondaryColour
                                  }
                                ><span>{getGoalType(item?.goal)}</span></QuestionAnswer>
                              </AnswerBox>
                            ))}
                          </ObjectiveCol>
                        </Col>
                        <Col xs={24}>
                          <QuestionTitle style={{textAlign: 'center'}}>Semana 2</QuestionTitle>
                          <ObjectiveCol>
                            {infoItem?.week2 && infoItem?.week2.map((item, index) => (
                              <AnswerBox last={index === infoItem?.week2?.length - 1}>
                                <QuestionAnswer>Objetivo: <span>{item?.objective}</span></QuestionAnswer>
                                <QuestionAnswer>Desafio: <span>{item?.challenge}</span></QuestionAnswer>
                                <QuestionAnswer
                                  color={
                                    item?.goal === 1 
                                      ? SuccessBorderColor 
                                      : item?.goal === 2 
                                      ? '#FFD60F' 
                                      : item?.goal === 3 
                                      ? ErrorBorderColor 
                                      : SecondaryColour
                                  }
                                ><span>{getGoalType(item?.goal)}</span></QuestionAnswer>
                              </AnswerBox>
                            ))}
                          </ObjectiveCol>
                        </Col>
                        <Col xs={24}>
                          <QuestionTitle style={{textAlign: 'center'}}>Semana 3</QuestionTitle>
                          <ObjectiveCol>
                            {infoItem?.week3 && infoItem?.week3.map((item, index) => (
                              <AnswerBox last={index === infoItem?.week2?.length - 1}>
                                <QuestionAnswer>Objetivo: <span>{item?.objective}</span></QuestionAnswer>
                                <QuestionAnswer>Desafio: <span>{item?.challenge}</span></QuestionAnswer>
                                <QuestionAnswer
                                  color={
                                    item?.goal === 1 
                                      ? SuccessBorderColor 
                                      : item?.goal === 2 
                                      ? '#FFD60F' 
                                      : item?.goal === 3 
                                      ? ErrorBorderColor 
                                      : SecondaryColour
                                  }
                                ><span>{getGoalType(item?.goal)}</span></QuestionAnswer>
                              </AnswerBox>
                            ))}
                          </ObjectiveCol>
                        </Col>
                      </Row>
                    )}
                  </BoxCol2>
                </QuestionBox>
              ) : (
                <AnswersContainer>
                  Não existem respostas para este capítulo!
                </AnswersContainer>
              )}
            </>
          )}
          {info?.type === 'activities_calendar' && (
            <Row gutter={[16, 24]}>
              <Col sm={24} xl={4}>
                <WeekInput 
                  meta={{}}
                  picker='week'
                  //dateFormat={this.customFormat}
                  input={{
                    value: week,
                    onChange: this.onchangeDate
                  }}
                  disabledDate={this.disabledDate}
                />
                <div style={{marginTop: 16, textAlign:'left'}}>Agenda semanal do dia {days[0]?.day} ao dia {days[days?.length - 1]?.day}</div>
              </Col>
              <Col sm={24} xl={20}>
                <ActivitiesBox>
                  {days.map((day, i) => (
                    <CalendarContainer 
                      key={i} 
                      first={i === 0}
                    >
                      <ActivitiesContainer sm={24} md={4} xl={4}>
                        <div>
                          {day?.dayOfWeek}
                        </div>
                        <div>
                          {day?.day}
                        </div>
                      </ActivitiesContainer>
                      <Col sm={24} md={20} xl={20}>
                        <Row style={{display: 'flex', padding: '8px 16px'}}>
                          {info?.data.find(x => moment(x.date).format('DD-MM-YYYY') === moment(day.date).format('DD-MM-YYYY'))?.activities.map((activity, idx) => (
                            <ActivityBox key={idx} completed={activity.completed}>{activity?.title}</ActivityBox>
                          ))}
                        </Row>
                      </Col>
                    </CalendarContainer>                      
                  ))}
                </ActivitiesBox>
              </Col>
            </Row>
          )}
          {info?.type === 'diary_objectives' && (
            <Row gutter={[16, 24]}>
              <Col sm={24} xl={4}>
                <WeekInput 
                  meta={{}}
                  picker='week'
                  //dateFormat={this.customFormat}
                  input={{
                    value: week,
                    onChange: this.onchangeDate
                  }}
                  disabledDate={this.disabledDate}
                />
              </Col>
              <Col sm={24} xl={20}>
              {info?.data?.length > 0 ? (info?.data.map((item, index) => (
                <Col sm={24} xl={20} key={index}>
                  <QuestionBox>
                    <QuestionAnswer>Data: <span>{moment(item?.date).format('DD-MM-YYYY')}</span></QuestionAnswer>
                    <QuestionAnswer>Atividade: <span>{item?.activity}</span></QuestionAnswer>
                    <QuestionAnswer>Média: <span>{item?.media}</span></QuestionAnswer>
                  </QuestionBox>
                </Col>
              ))) : (
                <AnswersContainer>
                  Não existem respostas para esta semana!
                </AnswersContainer>
              )}
              </Col>
            </Row>
          )}
        </AnswersContainer>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({ 
  user: state.user
});

export default connect(mapStateToProps)(ApplicatorChaptersPage);