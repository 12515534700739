import client from '../config/AxiosConfig';

/* ARTICLES - CATEGORIES */
export const GetCategories = async (currentPage, limit, filters) => await client.get(`/about_articles_categories/${currentPage}/${limit}?filter=${filters}`);

export const GetCategory = async (id) => await client.get(`/about_articles_categories/${id}`);

export const CreateCategory = async (data) => await client.post(`/about_articles_categories`, data);

export const UpdateCategory = async (id, data) => await client.put(`/about_articles_categories/${id}`, data);

export const DeleteCategory = async (id) => await client.delete(`/about_articles_categories/${id}`);

export const GetCategoriesList = async () => await client.get(`/about_articles_categories`);

/* ARTICLES */
export const GetArticles = async (currentPage, limit, filters) => await client.get(`/about_articles/${currentPage}/${limit}?filter=${filters}`);

export const GetArticle = async (id) => await client.get(`/about_articles/${id}`);

export const ToggleArticles = async (id, data) => await client.put(`/about_articles/${id}/toggle`, data);

export const CreateArticle = async (data) => await client.post(`/about_articles`, data);

export const UpdateArticle = async (id, data) => await client.put(`/about_articles/${id}`, data);

export const DeleteArticle = async (id) => await client.delete(`/about_articles/${id}`);

export const UpdateArticlesOrder = async (data) => await client.post(`/about_articles/order`, data);