import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  PageContainer,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
} from '../../../styles/BasicStyles';
import {
  GetBlocks,
  DeleteBlock,
  ToggleBlock,
  UpdateBlocksOrder,
} from '../../../infra/requests/ExtraSessionRequests';
import BaseButton from '../../../components/generic/buttons/BaseButton';
import { initialize } from 'redux-form';
import { withRouter } from 'react-router-dom';
import BlockComponent from './BlockComponent';

class BlocksPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: true,
    ready: false,
    rows: [],
    total: 0,
    orderModal: false
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/extra-session/blocks/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  removeBlock = async (data) => {
    try {
      await DeleteBlock(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { info } = this.props;
      const { currentPage, pageSize } = this.state;
      const result = await GetBlocks(info?._id, currentPage, pageSize);

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  toggleActive = async (record) => {
    this.setState({ loading: true });
    await ToggleBlock(record._id, { active: !record.active });
    await this.updateDataTable();
  };

  closeOrderModal = () => {
    this.setState({ orderModal: false });
  };

  openOrderModal = (blocks) => {
    const { dispatch } = this.props;
    dispatch(
      initialize(
        'manage_order_blocks_form',
        { blocks: [...blocks] },
        false
      )
    );

    this.setState({ orderModal: true });
  };

  onSubmitOrder = async (values) => {
    try {
      this.setState({ loading: true });

      if (values.blocks.length > 0) {
        let orderedList = [];
        Object.keys(values.blocks).forEach(function (key) {
          let obj = { _id: values.blocks[key]._id, order: key };
          orderedList.push(obj);
        });

        if (orderedList.length > 0) {
          const res = await UpdateBlocksOrder({ order: orderedList });

          if (res.success) {
            this.updateDataTable();
          }
        }
      }

      this.setState({ loading: false });
      this.closeOrderModal();
    } catch (e) {
      this.setState({ loading: false });
    }
  };

   render() {
    const { rows } = this.state;
    const { history, info } = this.props;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}></HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Criar'}
              onClick={() => history.push(`/extra-session/${info?._id}/blocks/add`)}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          {
            rows?.length > 0 ?
            <BlockComponent 
              items={rows} 
              toggleActive={(data) => this.toggleActive(data)} 
              removeBlock={(data) => this.removeBlock(data)} 
              editBlock={(data) => this.handleOnPressRow(data)}
              onSubmit={this.onSubmitOrder}
              updateDataTable={this.updateDataTable}
            /> 
            :
            <div style={{ textAlign: 'center' }}>Não existem Blocos inseridos!</div>
          }
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default withRouter(connect()(BlocksPage));