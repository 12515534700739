export const PrimaryColour = '#46329B';
export const SecondaryColour = '#F38231';
export const ThirdColour = '#FFBF00';
export const PrimaryCTA = '#CACACA';
export const SecondaryCTA = '#A5A5A5';
export const AlertColour = '#ED1D24';
export const InputBorderColour = '#979797';
export const PrimaryBorderColor = '#F3F3F3';
export const SecondaryBorderColor = '#ED1D24';
export const MenuBorderColor = '#e8e8e8';
export const PrimaryBackgroundColor = '#ffffff';
export const SecondaryBackgroundColor = '#8c8c8c';
export const ThirdBackgroundColor = '#cccccc';
export const FourthBackgroundColor = '#D2E0E5';
export const HeaderBackgroundColor = '#e8e8e8';
export const ErrorBorderColor = '#FF1105';
export const SuccessBorderColor = '#25AD2B';
export const HoverBorderColor = '#656565'