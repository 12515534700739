import fileBrowser from 'suneditor/src/plugins/modules/fileBrowser';
import { DeleteImage } from '../../../infra/requests/SunEditorImagesRequests';
import { notification } from 'antd';

const pluginGallery = {
	name: 'pluginGallery',
	/**
	* @description Constructor
	* @param {Object} core Core object 
	*/
	add: function (core) {
		core.addModule([fileBrowser]);
		
		const context = core.context;
		context.pluginGallery = {
			title: 'Galeria', // @Required @Override fileBrowser - File browser window title.
			url: context.options.imageCustomGalleryUrl, // @Required @Override fileBrowser - File server url.
			listClass: 'se-image-list', // @Required @Override fileBrowser - Class name of list div.
			itemTemplateHandler: this.drawItems.bind(core), // @Required @Override fileBrowser - Function that defines the HTML of an file item.
			selectorHandler: this.setImage.bind(core), // @Required @Override fileBrowser - Function that action when item click.
			columnSize: 4 // @Option @Override fileBrowser - Number of "div.se-file-item-column" to be created (default: 4)
		};
	},
	
	/**
	* @Required @Override fileBrowser
	* @description Open a file browser.
	* @param {Function|null} selectorHandler When the function comes as an argument value, it substitutes "context.selectorHandler".
	*/
	open: function (selectorHandler) {
		this.plugins.fileBrowser.open.call(this, 'pluginGallery', selectorHandler);
		this.plugins.pluginGallery._drawFileList.call(this, this.context['pluginGallery'].url);
	},

	_drawFileList: function (url) {
		const fileBrowserPlugin = this.plugins.fileBrowser;

		const xmlHttp = fileBrowserPlugin._xmlHttp = this.util.getXMLHttpRequest();
		xmlHttp.onreadystatechange = this.plugins.pluginGallery._callBackGet.bind(this, xmlHttp);
		xmlHttp.open('get', url, true);
		xmlHttp.send(null);

		this.plugins.fileBrowser.showBrowserLoading();
	},

	_callBackGet: function (xmlHttp) {
		if(xmlHttp.readyState === 4) {
			this.plugins.fileBrowser._xmlHttp = null;
			if(xmlHttp.status === 200) {
				try {
					this.plugins.fileBrowser._drawListItem.call(this, JSON.parse(xmlHttp.responseText).result, true);
					
					const imgDelete = document.getElementsByClassName('se-file-img-remove');
					if(imgDelete?.length > 0) {
						for(let i = 0; i < imgDelete.length; i++) {
							imgDelete[i].addEventListener('click', this.plugins.pluginGallery.removeImg.bind(this));
						}
					}
				} 
				catch (e) {
					throw Error('[SUNEDITOR.fileBrowser.drawList.fail] cause : "' + e.message + '"');
				} 
				finally {
					this.plugins.fileBrowser.closeBrowserLoading();
					this.context.fileBrowser.body.style.maxHeight = (this._w.innerHeight - this.context.fileBrowser.header.offsetHeight - 50) + 'px';
				}
			} 
			else { // exception
				this.plugins.fileBrowser.closeBrowserLoading();
				if(xmlHttp.status !== 0) {
					const res = !xmlHttp.responseText ? xmlHttp : JSON.parse(xmlHttp.responseText);
					const err = '[SUNEDITOR.fileBrowser.get.serverException] status: ' + xmlHttp.status + ', response: ' + (res.errorMessage || xmlHttp.responseText);
					throw Error(err);
				}
			}
		}
	},

	/**
	* @Required @Override fileBrowser
	* @description Define the HTML of the item to be put in "div.se-file-item-column".
	* Format: [
	*      { url: "image url", name: "name(@option)", alt: "image alt(@option)", tag: "tag name(@option)" }
	* ]
	* @param {Object} item Item of the response data's array
	*/
	drawItems: function (item) {
		const srcName = item.url.split('/').pop();

		return `<div class="se-file-container">
			<div class="se-file-img-remove" title="Delete image">
				<span data-id-img="${item._id}">x</span>
			</div>
			<div class="se-file-item-img">
				<img src="${item.url}" alt="${(item.alt || srcName)}" data-command="pick">
				<div class="se-file-img-name se-file-name-back"></div>
				<div class="se-file-img-name __se__img_name">${(item.name || srcName)}</div>
			</div>
		</div>`;
	},

	removeImg: async function (e) {
		try {
			const res = window.confirm("Tem a certeza que pretenda eliminar esta imagem?");

			if(res) {
				const id = e.target.dataset.idImg;
				const result = await DeleteImage(id);
			
				if(result?.success) {
					this.plugins.pluginGallery._drawFileList.call(this, this.context['pluginGallery'].url);

					return notification.success({
						message: 'Sucesso!',
						description: 'A imagem foi removida com sucesso!',
						duration: 2
					});
				}

				return notification.error({
					message: 'Erro!',
					description: 'Ocorreu um erro ao apagar a imagem! Por favor tente mais tarde.',
					duration: 2
				});
			}
			return true;
		} 
		catch (e) {
      console.error(e);
    }
	},
		
	setImage: function (target) {
		this.callPlugin('image', function () {
			const file = {name: target.parentNode.querySelector('.__se__img_name').textContent, size: 0};
			this.context.image._altText = target.alt;
			this.plugins.image.create_image.call(this, target.src, '', false, this.context.image._origin_w, this.context.image._origin_h, 'none', file);
		}.bind(this), null);
	}
};

export default pluginGallery;