import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { HeaderButtonsContainer, HeaderContainer } from '../../../styles/BasicStyles';
import RadioButtons from '../../../components/generic/inputs/RadioButtons';
import Table from '../../../components/generic/table/Table';
import { GetStudents, GetStudentsBirthday } from '../../../infra/requests/UsersRequests';
import moment from 'moment';
import CheckboxInput from '../../../components/generic/inputs/CheckboxInput';
import UserRegisterDetailFilters from '../UserRegisterDetailFilters';

class StudentsList extends Component {
  state= {
    value: 2,
    arrayStudents: [],
    pageSize: 30,
    currentPage: 1,
    loading: true,
    columns: [],
    rows: [],
    total: 0,
    filters: ''
  }

  componentDidMount = async () => {
    await this.updateDataTable();
  }

  onChange = (value, data) => {
    const { arrayStudents } = this.state;
    const { changeStudents } = this.props;

    if(value.target.checked) {
      arrayStudents.push(data._id);
    } else {
      const index = arrayStudents.findIndex(x => x === data._id.toString());
      arrayStudents.splice(index, 1);
    }
  
    this.setState(
      {arrayStudents}, 
      async () => 
        await this.updateDataTable(),
        changeStudents(arrayStudents)
    );
  }

  updateDataTable = async () => {
    const { arrayStudents, value, filters } = this.state;
    const { code } = this.props;
    try {
      this.setState({ loading: true });
      
      let result = [];

      if(value === 1) {
        result = await GetStudents(filters);
      } else if (value === 2) {
        result = await GetStudentsBirthday(code, filters); 
      }
      

      this.setState({
        columns: [
          {
            key: '_id',
            render: (data) => {
              const checked = arrayStudents.filter(x => x === data._id).length > 0 ? true : false;
              return (
              <CheckboxInput 
                input={{value: checked, onChange: value => this.onChange(value, data)}}
                meta={{}}
              />
            )},
            width: '5%'
          },
          {
            title: 'Nome',
            key: 'name',
            render: (data) => data?.name
          },
          {
            title: 'Email',
            key: 'email',
            render: (data) => data?.email
          },
          {
            title: 'Escola',
            key: 'school',
            render: (data) => data?.school?.name
          },
          {
            title: 'Turma',
            key: 'class',
            render: (data) => data?.class?.name
          },
          {
            title: 'Email',
            key: 'email',
            render: (data) => data?.email
          },
          {
            title: 'Data de Nascimento',
            key: 'birthday',
            render: (data) => data?.birthday && moment(data?.birthday).format('DD-MM-YYYY')
          }
        ],
        rows: result?.data || [],
        total: result?.data?.length || 0,
        loading: false
      });
    } 
    catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  }

  changeRadio = e => {
    const { changeStudents } = this.props;

    this.setState(
      {value: e.target.value, arrayStudents: []}, 
      async () => 
        await this.updateDataTable(),
        changeStudents([])
    );
    
  }

  handleFilterChange = (filters) => {
    this.setState({ filters }, this.updateDataTable);
  };

  render() {
    const { code } = this.props;
    const { 
      value,
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
    } = this.state;

    return(
      <React.Fragment>
        <UserRegisterDetailFilters queryChange={this.handleFilterChange}/>
        <HeaderContainer buttons={2}>
          <HeaderButtonsContainer buttons={2}>
            <RadioButtons 
              input={{
                value: value,
                onChange: this.changeRadio
              }}
              options={[
                {
                  value: 1,
                  label: 'Ver todos'
                },
                {
                  value: 2,
                  label: `Filtrar por código: ${code}`
                }
              ]}
              meta={{}}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <Table
          columns={columns}
          currentPage={currentPage}
          pageSize={pageSize}
          loading={loading}
          rows={rows}
          showHeader={true}
          emptyIcon='warning'
          emptyText={'Não existem Alunos!'}
          total={total}
          rowKey={'_id'}
          hasPagination={false}
        />
      </React.Fragment>
    )
  }
}

export default withRouter(StudentsList);