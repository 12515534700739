import styled from 'styled-components';

export const Title = styled.div`
  font-size: 16px;
  margin-bottom: 30px;
  margin-top: 10px;

  & span {
    font-weight: bold;
  }
`;