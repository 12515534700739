import React, { Component } from 'react';
import { Row, Col, notification } from 'antd';
import { Field, reduxForm, initialize } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import {
  GetTemplate,
  UpdateTemplate
} from '../../infra/requests/EmailTemplatesRequests';
import {
  FormContainer,
  SpinLoading,
  Separator,
  BaseForm
} from '../../styles/BasicStyles';
import {
  InputContainer,
  HtmlPreview,
  ExpandWindowContainer,
  NoteContainer
} from './Styles';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/generic/inputs/TextInput';
import CheckboxInput from '../../components/generic/inputs/CheckboxInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';
import BaseButton from '../../components/generic/buttons/BaseButton';
import FormValidator from '../../infra/services/validations/FormValidator';

const validations = FormValidator.make({
  subject: 'required'
});

class ManageEmailTemplatesPage extends Component {
  state = { 
    loading: true,
    info: undefined,
    viewSize: 1
  };

  componentDidMount = async () => {
    const {
      match: { params },
      CloseMenu,
      user,
      history,
      dispatch
    } = this.props;

    if(user.type === 2) {
      return history.push('/');
    }

    //The "Base Template" we edit in a different area
    if(params.id === '6081511c53953835fc93f6ec') {
      return history.push('/email-templates');
    }
    
    CloseMenu();

    let templateID = params.id;
    if(params.id === 'base-template') templateID = '6081511c53953835fc93f6ec';

    const result = await GetTemplate(templateID);

    dispatch(
      initialize('manage_email_templates_form', {
        ...result.data,
      })
    );

    this.setState({ 
      loading: false,
      info: result.data
    });
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const { match: { params }, history } = this.props;
      const { info } = this.state;

      if(!info?.html) {
        notification.error({
          message: 'Erro!',
          description: 'Deverá introduzir algum contéudo no email.'
        });
        return this.setState({ loading: false });
      }
      

      let templateID = params.id;
      let payload = FlattenToFormData({ includeHeaderFooter: values?.includeHeaderFooter, subject: values?.subject, html: info?.html });
      if(params.id === 'base-template') {
        if(!info?.html.includes('{{TEMPLATE}}')) {
          notification.error({
            message: 'Erro!',
            description: 'Não pode remover a tag "{{TEMPLATE}}" deste template, caso contrário os emails serão enviados sem contéudo.'
          });
          return this.setState({ loading: false });
        }

        templateID = '6081511c53953835fc93f6ec';
        payload = FlattenToFormData({ html: info?.html });
      }

      const { success } = await UpdateTemplate(templateID, payload);

      if (success) return history.push('/email-templates');
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/email-templates');
  };

  onChange = (value) => {
    const { info } = this.state;
    info.html = value;

    this.setState({
      info
    });
  }

  changeView = () => {
    const { viewSize } = this.state;
    const size = viewSize === 1 ? 2 : 1;

    this.setState({
      viewSize: size
    });
  }

  render() {
    const { match: { params }, handleSubmit } = this.props;
    const { loading, info, viewSize } = this.state;

    if(loading) return <SpinLoading />;

    let extraTitle = '';
    if(params.id === 'base-template') extraTitle = 'Editar Header & Footer';

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Templates de Email', extraTitle, 'Editar']}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values))
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24}>
              <Col xs={12} offset={0}>
                <TextInput
                  type='text'
                  label={'Nome'}
                  disabled={true}
                  meta={{}}
                  input={{
                    value: info?.name,
                    onChange: () => null
                  }}
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginBottom: '15px' }}>
              <Col xs={12} offset={0}>
                <TextInput
                  type='tag'
                  label={'Tag'}
                  disabled={true}
                  meta={{}}
                  input={{
                    value: info?.tag,
                    onChange: () => null
                  }}
                />
              </Col>
            </Row>
            {
              params.id !== 'base-template' &&
              <>
                <Row gutter={24}>
                  <Col xs={12} offset={0}>
                    <Field
                      component={CheckboxInput}
                      name={'includeHeaderFooter'}
                      label={'Incluir Header & Footer neste template?'}
                      importantLabel={true}
                      inlineCheckbox={true}
                    />
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col xs={12} offset={0}>
                    <Field
                      component={TextInput}
                      name='subject'
                      type='text'
                      label='Assunto *'
                      placeholder='Assunto do email'
                    />
                  </Col>
                </Row>
              </>
            }
            <Separator/>
            {
              params.id !== 'base-template' &&
              <Row gutter={24}>
                <Col xs={24} offset={0}>
                  <NoteContainer>
                    <span>Nota: </span>Deverá ter atenção às TAGS disponíveis no template. Não pode modificar ou adicionar novas, mas pode usar ou não as TAGS disponíveis consoante pretender. 
                  </NoteContainer>
                </Col>
              </Row>
            }
            <ExpandWindowContainer>
              <Row gutter={24} style={{ marginBottom: '20px' }}>
                <Col xs={24} offset={0}>
                  <BaseButton
                    type='primary'
                    icon='fullscreen'
                    text={viewSize === 1 ? 'Expandir Janela' : 'Diminuir Janela' }
                    onClick={() => this.changeView()}
                  />
                </Col>
              </Row>
            </ExpandWindowContainer>
            <Row gutter={24}>
              <Col md={24} lg={viewSize === 1 ? 12 : 24} offset={0}>
                <InputContainer hasMargin={ viewSize === 1 ? false : true }>
                  <AceEditor
                    placeholder='Introduza o conteúdo do email'
                    mode='html'
                    theme='monokai'
                    name='html'
                    onLoad={editorInstance => {
                      editorInstance.container.style.resize = 'both';
                      document.addEventListener('mouseup', e => (
                        editorInstance.resize()
                      ));
                    }}
                    onChange={this.onChange}
                    fontSize={14}
                    showPrintMargin={false}
                    showGutter={true}
                    highlightActiveLine={true}
                    value={info?.html}
                    setOptions={{
                      enableBasicAutocompletion: false,
                      enableLiveAutocompletion: false,
                      enableSnippets: false,
                      showLineNumbers: true,
                      tabSize: 2
                    }}
                  />
                </InputContainer>
              </Col>
              <Col md={24} lg={viewSize === 1 ? 12 : 24} offset={0}>
                <HtmlPreview
                  dangerouslySetInnerHTML={{
                  __html: info?.html
                  }}
                />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageEmailTemplatesPage = reduxForm({
  form: 'manage_email_templates_form',
  validate: validations
})(ManageEmailTemplatesPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageEmailTemplatesPage));