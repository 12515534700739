import ConfigsPage from '../../website/configs/ConfigsPage';
import ManageConfigsPage from '../../website/configs/ManageConfigsPage';
import DynamicPages from '../../website/dynamicPages/DynamicPages';
import ManageDynamicPages from '../../website/dynamicPages/ManageDynamicPages';
import Metatags from '../../website/metatags/Metatags';
import TranslationsPage from '../../website/translations/TranslationsPage';
import ManageMetatags from '../../website/metatags/ManageMetatags';
import FaqsPage from '../../website/faqs/FaqsPage';
import ManageFaqsPage from '../../website/faqs/ManageFaqsPage';
import NewsPage from '../../website/news/NewsPage';
import ManageNewsPage from '../../website/news/ManageNewsPage';
import ManageNewsBlocksPage from '../../website/news/components/ManageBlocksPage';
import HelpPage from '../../website/help/HelpPage';
import ManageHelpPage from '../../website/help/ManageHelpPage';
import ManageBannerPage from '../../website/home_banner/ManageBannerPage';
import PlatformPage from '../../website/home_platform/PlatformPage';
import ManagePlatformPage from '../../website/home_platform/ManagePlatformPage';
import ProgramsPage from '../../website/home_programs/ProgramsPage';
import ManageProgramsPage from '../../website/home_programs/ManageProgramsPage';
import TestimonialsPage from '../../website/home_testimonials/TestimonialsPage';
import ManageTestimonialsPage from '../../website/home_testimonials/ManageTestimonialsPage';
import ManageMindPage from '../../website/home_banner/ManageMindPage';
import TeamPage from '../../website/about_team/TeamPage';
import ManageTeamPage from '../../website/about_team/ManageTeamPage';
import ManageProjectPage from '../../website/about_project/ManageProjectPage';
import ManageBasePage from '../../website/about_project/ManageBasePage';
import ManageIntroPage from '../../website/about_programs/ManageIntroPage';
import ManageAboutProgramsPage from '../../website/about_programs/ManageProgramsPage';
import ManageMottosPage from '../../website/about_programs/ManageMottosPage';
import ManageMottos from '../../website/about_programs/components/ManageMottosList';
import ManageCriteriaPage from '../../website/about_programs/ManageCriteriaPage';
import ManageThemesIntroPage from '../../website/about_project/ManageThemesIntroPage';
import ThemesCategoriesPage from '../../website/about_themes_categories/CategoriesPage';
import ManageThemesCategoriesPage from '../../website/about_themes_categories/ManageCategoriesPage';
import ThemesPage from '../../website/about_themes/ThemesPage';
import ManageThemesPage from '../../website/about_themes/ManageThemesPage';
import ManageBlocksPage from '../../website/about_themes/components/ManageBlocksPage';
import ManageArticlesIntroPage from '../../website/about_project/ManageArticlesIntroPage';
import ArticlesCategoriesPage from '../../website/about_articles_categories/CategoriesPage';
import ManageArticlesCategoriesPage from '../../website/about_articles_categories/ManageCategoriesPage';
import ArticlesPage from '../../website/about_articles/ArticlesPage';
import ManageArticlesPage from '../../website/about_articles/ManageArticlesPage';
import InstrumentsCategoriesPage from '../../website/about_instruments_categories/CategoriesPage';
import ManageInstrumentsCategoriesPage from '../../website/about_instruments_categories/ManageCategoriesPage';
import InstrumentsPage from '../../website/about_instruments/InstrumentsPage';
import ManageInstrumentsPage from '../../website/about_instruments/ManageInstrumentsPage';
import ManageInstrumentsBlocksPage from '../../website/about_instruments/components/ManageBlocksPage';
import ManageInstrumentsIntroPage from '../../website/about_project/ManageInstrumentsIntroPage';
import ManagerTestMindPage from '../../website/mind/ManagerTestMindPage';
import ManagerMindFormPage from '../../website/mind/ManagerMindFormPage';

export default [
  {
    path: '/website',
    component: ConfigsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/configs/edit',
    component: ManageConfigsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/dynamic-pages',
    component: DynamicPages,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/dynamic-pages/:id',
    component: ManageDynamicPages,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/metatags',
    component: Metatags,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/metatags/:id',
    component: ManageMetatags,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/translations',
    component: TranslationsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/faqs',
    component: FaqsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/faqs/add',
    component: ManageFaqsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/faqs/:id',
    component: ManageFaqsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/news',
    component: NewsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/news/add',
    component: ManageNewsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/news/:id',
    component: ManageNewsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/news/:news/blocks/add',
    component: ManageNewsBlocksPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/news/blocks/:id',
    component: ManageNewsBlocksPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/help',
    component: HelpPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/help/add',
    component: ManageHelpPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/help/:id',
    component: ManageHelpPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/home/banner',
    component: ManageBannerPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/home/platform',
    component: PlatformPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/home/platform/add',
    component: ManagePlatformPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/home/platform/:id',
    component: ManagePlatformPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/home/programs',
    component: ProgramsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/home/programs/add',
    component: ManageProgramsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/home/programs/:id',
    component: ManageProgramsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/home/mind',
    component: ManageMindPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/home/testimonials',
    component: TestimonialsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/home/testimonials/add',
    component: ManageTestimonialsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/home/testimonials/:id',
    component: ManageTestimonialsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/team',
    component: TeamPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/team/add',
    component: ManageTeamPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/team/:id',
    component: ManageTeamPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/project',
    component: ManageProjectPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/base',
    component: ManageBasePage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/programs/intro',
    component: ManageIntroPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/programs/programs',
    component: ManageAboutProgramsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/programs/mottos',
    component: ManageMottosPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/programs/mottos/add',
    component: ManageMottos,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/programs/mottos/:id',
    component: ManageMottos,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/programs/criteria',
    component: ManageCriteriaPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/themes/intro',
    component: ManageThemesIntroPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/themes/categories',
    component: ThemesCategoriesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/themes/categories/add',
    component: ManageThemesCategoriesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/themes/categories/:id',
    component: ManageThemesCategoriesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/themes',
    component: ThemesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/themes/add',
    component: ManageThemesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/themes/:id',
    component: ManageThemesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/themes/:theme/blocks/add',
    component: ManageBlocksPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/themes/blocks/:id',
    component: ManageBlocksPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/articles/intro',
    component: ManageArticlesIntroPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/articles/categories',
    component: ArticlesCategoriesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/articles/categories/add',
    component: ManageArticlesCategoriesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/articles/categories/:id',
    component: ManageArticlesCategoriesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/articles',
    component: ArticlesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/articles/add',
    component: ManageArticlesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/articles/:id',
    component: ManageArticlesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/instruments/intro',
    component: ManageInstrumentsIntroPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/instruments/categories',
    component: InstrumentsCategoriesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/instruments/categories/add',
    component: ManageInstrumentsCategoriesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/instruments/categories/:id',
    component: ManageInstrumentsCategoriesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/instruments',
    component: InstrumentsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/instruments/add',
    component: ManageInstrumentsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/instruments/:id',
    component: ManageInstrumentsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/instruments/:instrument/blocks/add',
    component: ManageInstrumentsBlocksPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/about/instruments/blocks/:id',
    component: ManageInstrumentsBlocksPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/mind',
    component: ManagerTestMindPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/website/mind/form/:id',
    component: ManagerMindFormPage,
    isAuthenticated: true,
    role: 1,
  },
];