import client from '../config/AxiosConfig';
import { auth_token_key } from '../../infra/config/LocalStorageKeys';
const authToken = localStorage.getItem(auth_token_key);

export const GetPrograms = async (currentPage, limit) => await client.get(`/programs/${currentPage}/${limit}`);

export const GetProgram = async (id) => await client.get(`/programs/id/${id}`);

export const GetUserProgram = async (id, user) => await client.get(`/programs/id/${id}/${user}`);

export const UpdateProgram = async (id, data) => await client.put(`/programs/${id}`, data);

export const UpdateProgramDetails = async (id, data) => await client.put(`/programs/details/${id}`, data);

export const GetSession = async (id) => await client.get(`/sessions/id/${id}`);

export const UpdateSession = async (id, data) => await client.put(`/sessions/${id}`, data);

export const GetChapter = async (id) => await client.get(`/chapters/id/${id}`);

export const UpdateChapter = async (id, data) => await client.put(`/chapters/${id}`, data);

export const GetStep = async (id) => await client.get(`/steps/id/${id}`);

export const UpdateStep = async (id, data) => await client.put(`/steps/${id}`, data);

export const DownloadChapter = data => { 
    return new Promise((accept, reject) => {
      fetch(`${process.env.REACT_APP_API}/reports/chapter`, { 
        method: 'POST', 
        body: JSON.stringify(data), 
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` } 
      })
      .then(response => {
        return response.blob().then(blob => {
          return {
            headers: response.headers,
            blob
          }
        })
      })
      .then(data => accept({ headers: data.headers, blob: data.blob }))
      .catch(error => reject(error))
    });
  }

export const ImportProgram = async (id, data) => await client.put(`/programs/import/${id}`, data);
export const ImportChapter = async (id, data) => await client.put(`/sessions/import/${id}`, data);