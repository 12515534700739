import React, { Component } from 'react';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle
} from '../../styles/BasicStyles';
import { GetMetatags } from '../../infra/requests/BaseRequests';
import { connect } from 'react-redux';

class Metatags extends Component {
  state = {
    loading: false,
    columns: [{ title: 'Metatags', dataIndex: 'name' }],
    rows: []
  };

  componentDidMount = async () => {
    const { user, history } = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 

    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/website/metatags/${record._id}`);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const result = await GetMetatags();
      this.setState({
        rows: result.data,
        loading: false,
      });
    } 
    catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  render() {
    const { columns, rows, total, loading } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle>
            <PageTitle>Lista de Metatags</PageTitle>
            {`Metatags`}
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <Table
            columns={columns}
            currentPage={1}
            pageSize={50}
            loading={loading}
            onPressRow={this.handleOnPressRow}
            rows={rows}
            showHeader={true}
            emptyIcon='copy'
            emptyText={'No Metatags'}
            total={total}
            rowKey={'_id'}
            hasPagination={false}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(Metatags);