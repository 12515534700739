import React, { Component } from 'react';
import Table from '../../../components/generic/table/Table';
import { withRouter } from 'react-router-dom';
import {
  GetStudentsApplicators
} from '../../../infra/requests/UsersRequests';
import {
  Title
} from '../UserStyles';

class StudentsApplicatorsList extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: true,
    columns: [
      {
        title: 'Nome',
        key: 'name',
        render: (data) => data.applicator?.name,
        width: '65%'
      },
      {
        title: 'Email',
        key: 'email',
        render: (data) => data.applicator?.email,
        width: '35%'
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  updateDataTable = async () => {
    try {
      this.setState({ loading: true });
      
      const { userID } = this.props;
      const result = await GetStudentsApplicators(userID);

      this.setState({
        rows: result?.data?.applicators || [],
        total: result?.data?.applicators?.length || 0,
        loading: false
      });
    } 
    catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  }

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
    } = this.state;

    return (
      <React.Fragment>
        <Title>
          <span>Nota:</span> A gestão dos Aplicadores dos Alunos é feita na secção "Turmas"!
        </Title>
        <Table
          columns={columns}
          currentPage={currentPage}
          pageSize={pageSize}
          loading={loading}
          rows={rows}
          showHeader={true}
          emptyIcon='warning'
          emptyText={'Não existem Aplicadores inseridos!'}
          total={total}
          rowKey={'_id'}
          hasPagination={false}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(StudentsApplicatorsList);