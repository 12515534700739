import React, { Component } from "react";

import { connect } from 'react-redux';
import { 
  PageContainer, 
  SpinLoading
} from "../../styles/BasicStyles";
import AdminDashboard from "./AdminDashboard";
import ApplicatorDashboard from "./ApplicatorDashboard";

class DashboardPage extends Component {
  state = {
    ready: false
  };

  componentDidMount() {
    this.setState({
      ready: true
    });
  }

  render() {
    const { ready } = this.state;
    const { user } = this.props;

    if(!ready) return <SpinLoading />;

    return (
      <PageContainer>
        {user.type === 2 ? (<ApplicatorDashboard />) : (<AdminDashboard />)}
      </PageContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(DashboardPage);