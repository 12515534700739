import React, { Component } from 'react';
import Table from '../../../components/generic/table/Table';
import { withRouter } from 'react-router-dom';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import { getChapterType } from '../../../infra/utils/ChapterTypes';
import { getStepType } from '../../../infra/utils/StepTypes';

class FormsList extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: true,
    columns: [
      {
        title: 'Título',
        dataIndex: 'title',
        render: (value) => TranslateValue(value),
        width: '35%',
      },
      {
        title: 'Tipo',
        dataIndex: 'type',
        render: (value) => getStepType(value),
        width: '35%',
      },
      {
        title: 'Formulário vísivel para resposta?',
        dataIndex: 'enabled',
        render: (value) => value ? 'Sim' : 'Não',
        width: '30%',
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = () => {
    const { list } = this.props;

    this.setState({
      rows: list || [],
      total: list?.length || 0,
      loading: false
    });
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    
    history.push(`/website/mind/form/${record._id}`);
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
    } = this.state;
    const {applicator, admin} = this.props;

    return (
      <Table
        secondary={applicator || admin}
        columns={columns}
        currentPage={currentPage}
        pageSize={pageSize}
        loading={loading}
        rows={rows}
        showHeader={true}
        emptyIcon='warning'
        emptyText={'Não existem Formulários inseridos!'}
        total={total}
        rowKey={'_id'}
        hasPagination={false}
        onPressRow={this.handleOnPressRow}
      />
    );
  }
}

export default withRouter(FormsList);