import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { TableFilterSection } from '../../styles/BasicStyles';
import TextInput from '../../components/generic/inputs/TextInput';

class UserRegisterDetailFilters extends Component {
  state = {
    search: ''
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map((key) => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(JSON.stringify(query));
  };

  render() {
    const { search } = this.state;

    return (
      <TableFilterSection style={{marginBottom: 0}}>
        <Row gutter={24}>
          <Col xs={24} md={6}>
            <TextInput
              input={{
                value: search,
                onChange: (event) =>
                  this.onInputChange('search', event.target.value, 1000),
              }}
              meta={{ valid: true }}
              type='text'
              label='Pesquisar'
              placeholder='Procurar por nome'
            />
          </Col>
          {/* <Col xs={24} md={6}>
            <DateInput
              label='Data de Nascimento'
              placeholder='Escolher data de nascimento'
              input={{
                value: birthday,
                onChange: value => this.onInputChange('birthday', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          {!applicator && (
            <Col xs={24} md={6}>
              <SelectInput
                label='Tipo'
                allowClear
                placeholder='Escolher tipo'
                data={UserTypes}
                dataKey='_id'
                dataLabel='name'
                input={{
                  value: type,
                  onChange: value => this.onInputChange('type', value)
                }}
                meta={{valid: true}}
              />
            </Col>
          )} */}
        </Row>
      </TableFilterSection>
    );
  }
}

export default UserRegisterDetailFilters;