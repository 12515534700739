import React, { Component } from 'react';
import { Popconfirm, Icon, notification } from 'antd';
import { connect } from 'react-redux';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton,
} from '../../styles/BasicStyles';
import { 
  GetUsers, 
  DeleteUser, 
  ToggleUserState,
  DownloadUsers,
  resendEmails,
  resendUserEmail
} from '../../infra/requests/UsersRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import UsersFilters from './UsersFilters';
import { getUserType } from '../../infra/utils/UserTypes';
import Table from '../../components/generic/table/Table';
import { GetSchoolsList, GetClassesList } from '../../infra/requests/SchoolsRequests';
import Cookies from 'js-cookie';

class UsersPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: false,
    columns: [
      {
        title: 'Tipo',
        dataIndex: 'type',
        render: (value) => getUserType(value),
        width: '12%'
      },
      {
        title: 'Nome',
        dataIndex: 'name',
        width: '20%'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        width: '15%'
      },
      {
        title: 'Escola',
        dataIndex: 'school.name',
        width: '12%'
      },
      {
        title: 'Turma',
        dataIndex: 'class.name',
        width: '12%'
      },
      {
        title: '',
        render: (data) => (
          <React.Fragment>
            {
              data.type === 4 &&
              <TableButton onClick={(e) => e.stopPropagation()}>
                <Popconfirm
                  placement='topRight'
                  title='Quer reenviar um email a este utilizador para definir a password?'
                  onConfirm={() => this.resendUserEmail(data._id)}
                >
                  <Icon type='mail'/>
                  {' Reenviar email'}
                </Popconfirm>
              </TableButton>
            }
            <TableButton onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement='topRight'
                title={
                  data.active
                    ? 'Quer desativar este utilizador?'
                    : 'Quer ativar este utilizador?'
                }
                onConfirm={() => this.toggleUser(data)}
              >
                <Icon type={data.active ? 'close-circle' : 'check-circle'} />
                {data.active ? ' Desativar' : ' Ativar'}
              </Popconfirm>
            </TableButton>
            <TableButton error onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement='topRight'
                title='Tem a certeza que quer remover o utilizador?'
                onConfirm={() => this.removeUser(data)}
              >
                <Icon style={{ marginRight: 10 }} type='delete' />
                {'Remover'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        ),
      },
    ],
    rows: [],
    total: 0,
    Schools: [],
    Classes: []
  };

  componentDidMount = async () => {
    const {user, history} = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 

    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/users/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    const { user } = this.props;
    const { pageSize, filters } = this.state;
    
    //When changing pages, maintain the current pageSize
    //Cookie timeout: 1 hour
    const timeout = new Date(new Date().getTime() + 60 * 60 * 1000);
    const cookie = JSON.stringify({
      user: user?._id,
      filters: filters ? JSON.parse(filters) : '',
      currentPage,
      pageSize: pageSize || 30
    });
    Cookies.set('userFilters', cookie, { expires: timeout });

    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    const { user } = this.props;
    const { filters } = this.state;

    //Changing the number of rows per page, always reset the page to 1
    //Cookie timeout: 1 hour
    const timeout = new Date(new Date().getTime() + 60 * 60 * 1000);
    const cookie = JSON.stringify({
      user: user?._id,
      filters: filters ? JSON.parse(filters) : '',
      currentPage: 1,
      pageSize
    });
    Cookies.set('userFilters', cookie, { expires: timeout });

    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  removeUser = async (data) => {
    try {
      await DeleteUser(data._id);
      await this.updateDataTable();
    } 
    catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { user } = this.props;
      let { currentPage, pageSize, filters } = this.state;

      const cookieJson = Cookies.get('userFilters');

      if(cookieJson) {
        const cookie = JSON.parse(cookieJson);

        if(cookie?.user === user._id) {
          filters = cookie.filters ? JSON.stringify(cookie.filters) : '';
          currentPage = cookie.currentPage || 1;
          pageSize = cookie.pageSize || 30;
        }
      }

      const result = await GetUsers(currentPage, pageSize, filters);
      const schools = await GetSchoolsList();

      let classes = []
      const filtersObject = filters ? JSON.parse(filters) : undefined
      if(filtersObject?.school) classes = await GetClassesList(filtersObject?.school);

      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false,
        Schools: schools?.data || [],
        Classes: classes?.data || [],
      });
    } 
    catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  toggleUser = async (record) => {
    this.setState({ loading: true });
    await ToggleUserState(record._id, { active: !record.active });
    await this.updateDataTable();
  };

  downloadReport = async () => {
    const { rows, filters } = this.state;

    try {
      if(rows?.length) {
        const result = await DownloadUsers({data: rows, filters: filters});

        if(result) {
          let filename = `Utilizadores.xlsx`;
          if(result.headers) {
            const contentDisposition = result.headers.get('content-disposition');
            filename = contentDisposition.split('filename="')[1].split('";')[0];
          }

          //Create blob link to download
          const url = window.URL.createObjectURL(new Blob([result.blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);

          //Append to html link element page
          document.body.appendChild(link);
          
          //Start download
          link.click();
          
          //Clean up and remove the link
          link.parentNode.removeChild(link);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  resendEmail = async () => {
    this.setState({ loading: true });
    try {
      const result = await resendEmails();
      if(result.success) {
        notification.success({
          message: "Emails enviados com sucesso"
        });
      }
      this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      notification.error({
        message: 'Erro!',
        description: 'Ocorreu um erro'
      });
      this.setState({ loading: false });
    }
  }

  resendUserEmail = async (id) => {
    this.setState({ loading: true });
    try {
      const result = await resendUserEmail(id);
      if(result.success) {
        notification.success({
          message: "Email enviado com sucesso"
        });
      }
      this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      notification.error({
        message: 'Erro!',
        description: 'Ocorreu um erro'
      });
      this.setState({ loading: false });
    }
  }

  render() {
    const { columns, rows, total, loading, currentPage, pageSize, Schools, Classes } = this.state;
    const { history, user } = this.props;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={3}>
            <PageTitle>Lista de Utilizadores</PageTitle>
            {`Utilizadores`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={3}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/users/add')}
            />
            <BaseButton
              type={'default'}
              icon={'sync'}
              text={'Atualizar'}
              onClick={() => this.updateDataTable()}
            />
            <BaseButton
              type={'secondary'}
              icon={'download'}
              text={'Exportar'}
              onClick={() => this.downloadReport()}
            />
            <Popconfirm
              placement='topRight'
              title={'Quer reenviar emails aos alunos para definir password?'}
              onConfirm={() => this.resendEmail()}
            >
              <BaseButton 
                onClick={(e) => e.stopPropagation()}
                type={'default'}
                icon={'mail'}
                text={'Reenviar Emails'}
              />
            </Popconfirm>
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <UsersFilters queryChange={this.handleFilterChange} Schools={Schools} Classes={Classes} user={user} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Utilizadores inseridos!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({ 
  user: state.user
});

export default connect(mapStateToProps, null)(UsersPage);
