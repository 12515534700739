import React, { Component } from 'react';
import { Field, reduxForm, initialize, FieldArray } from 'redux-form';
import { Tabs, Row, Col, notification } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab,
  TabsContainer,
  Tab,
  SectionTitle,
  InputNote
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import TextInput from '../../components/generic/inputs/TextInput';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import DraftInput from '../../components/generic/inputs/DraftInput';
import SunDraftInput from '../../components/generic/inputs/SunDraftInput';
import { GetAnswersByForm, GetFormMind, UpdateFormMind, DownloadMindForm } from '../../infra/requests/MindRequests';
import CheckboxInput from '../../components/generic/inputs/CheckboxInput';
import TextAreaInput from '../../components/generic/inputs/TextAreaInput';
import AnswersList from './components/AnswersList';
import DashboardComponent from './components/DashboardComponent';
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  url: 'noSpaces|noSpecialCharacter',
  title: 'required|languages',
  description: 'required|languages'
});

class ManagerMindFormPage extends Component {
  state = { 
    loading: true,
    form: undefined,
    answers: undefined
  };

  componentDidMount = async () => {
    const {user, history} = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 

    await this.loadDetail();
  };

  loadDetail = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu
    } = this.props;
    
    CloseMenu();

    const result = await GetFormMind(params.id);
    const answers = await GetAnswersByForm(params.id);

    dispatch(
      initialize('manage_mind_form', {
        ...result.data,
      })
    );

    this.setState({ 
      loading: false,
      form: result?.data,
      answers: answers?.data[0]?.items
    });
  }

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const { match: { params } } = this.props;

      const { success } = await UpdateFormMind(params.id, values);

      if(success) {
        notification.success({
          message: "Sucesso!",
          description: "Alterações guardadas com sucesso."
        });
        return await this.loadDetail();
      }

      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;

    return history.push('/website/mind');
  };

  renderQuestions = ({ fields, language }) => {
    const {activeLanguage, languages} = this.props;
    const questions = fields.getAll();

    return(
      <Row gutter={24}>
        <Col xs={12} offset={0}>
          <DefaultLanguageTab>
            {GetTranslationDescription(activeLanguage?.code)}
          </DefaultLanguageTab>
          {fields.map((question, index) => {
            return (
              <div key={index}>
                {questions[index].title && (
                  <Field
                  type='text'
                    component={TextInput}
                    name={`${question}.title.${activeLanguage?.code}`}
                    label={'Pergunta'}
                  />
                )}
                {questions[index].options && questions[index].options.length > 0 && (
                  <div style={{marginBottom: 15, padding: '0px 15px 15px', background: '#f0f0f0'}}>
                  {questions[index].options.map((option, i) => (
                    <Row key={i} gutter={24}>
                      <Col xs={16}>
                        <Field
                          type='text'
                          component={TextInput}
                          name={`${question}.options[${i}].title.${activeLanguage?.code}`}
                          label={'Opção de Resposta'}
                        />
                      </Col>
                      <Col xs={8}>
                        {questions[index].options[i].pointing && (
                          <Field
                            type='text'
                            disabled={true}
                            component={TextInput}
                            name={`${question}.options[${i}].pointing`}
                            label={'Pontuação'}
                          />
                        )}
                      </Col>
                    </Row>
                  ))}
                  </div>
                )}
              </div>
            )
          })}
        </Col>
        <Col xs={12} offset={0}>
          <Tabs>
            {languages
              .filter((x) => !x.active)
              .map((language) => (
                <TabPane
                  tab={GetTranslationDescription(language.code)}
                  key={language.code}
                >
                  {fields.map((question, index) => {
                    return (
                      <div key={index}>
                        {questions[index].title && (
                          <Field
                            key={index}
                            type='text'
                            component={TextInput}
                            name={`${question}.title.${language.code}`}
                            label={'Pergunta'}
                          />
                        )}
                        {questions[index].options && questions[index].options.length > 0 && (
                          <div style={{marginBottom: 15, padding: '0px 15px 15px', background: '#f0f0f0'}}>
                          {questions[index].options.map((option, i) => (
                            <Row key={i} gutter={24}>
                              <Col xs={16}>
                                <Field
                                  type='text'
                                  component={TextInput}
                                  name={`${question}.options[${i}].title.${language.code}`}
                                  label={'Opção de Resposta'}
                                />
                              </Col>
                              <Col xs={8}>
                                {questions[index].options[i].pointing && (
                                  <Field
                                    type='text'
                                    disabled={true}
                                    component={TextInput}
                                    name={`${question}.options[${i}].pointing`}
                                    label={'Pontuação'}
                                  />
                                )}
                              </Col>
                            </Row>
                          ))}
                          </div>
                        )}
                      </div>
                    )
                  })}
                </TabPane>
              ))}
          </Tabs>
        </Col>
      </Row>
    )
  }
    
  renderFeedbacks = ({ fields, language }) => {
    const {activeLanguage, languages} = this.props;
    const feedbacks = fields.getAll();

    return (
      <Row gutter={24}>
        <Col xs={12} offset={0}>
          <DefaultLanguageTab>
            {GetTranslationDescription(activeLanguage?.code)}
          </DefaultLanguageTab>
          {fields.map((feedback, index) => {
            return (
              <div key={index}>
                {feedbacks[index].description && (
                  <Field
                    type='text'
                    component={SunDraftInput}
                    toolbar='full'
                    name={`${feedback}.description.${activeLanguage?.code}`}
                    label={
                      feedbacks[index].min === 0 ?
                        `Se tiver <= ${feedbacks[index].max} pontos:`
                      : feedbacks[index].max === 100 ?
                        `Se tiver >= ${feedbacks[index].min} pontos:`
                      : `Se tiver entre ${feedbacks[index].min} e ${feedbacks[index].max} pontos:`
                    }
                  />
                )}
              </div>
            )
          })}
        </Col>
        <Col xs={12} offset={0}>
          <Tabs>
            {languages
              .filter((x) => !x.active)
              .map((language) => (
                <TabPane
                  tab={GetTranslationDescription(language.code)}
                  key={language.code}
                >
                  {fields.map((feedback, index) => {
                    return (
                      <div key={index}>
                        {feedbacks[index].description && (
                          <Field
                            key={index}
                            type='text'
                            component={SunDraftInput}
                            toolbar='full'
                            name={`${feedback}.description.${language.code}`}
                            label={
                              feedbacks[index].min === 0 ?
                                `Se tiver menos de ${feedbacks[index].max} pontos:`
                              : feedbacks[index].max === 100 ?
                                `Se tiver mais de ${feedbacks[index].min} pontos:`
                              : `Se tiver entre ${feedbacks[index].min} e ${feedbacks[index].max} pontos:`
                            }
                          />
                        )}
                      </div>
                    )
                  })}
                </TabPane>
              ))}
          </Tabs>
        </Col>
      </Row>
    )
  }

  renderDetails = () => {
    const { handleSubmit, activeLanguage, languages, translate } = this.props;
    const { form } = this.state;

    return (
      <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
        <Row>
          <Row gutter={24} style={{marginBottom: 30}}>
            <Col xs={12} offset={0}>
              <Field
                component={TextInput}
                name={`url`}
                type='text'
                label={'URL *'}
                placeholder={'URL do Tema'}
              />
              <InputNote>
                <span>NOTA:</span> Deverá introduzir o URL sem espaços, sem
                acentos, letras minúsculas e apenas com letras, números e
                hífens.
              </InputNote>
              <InputNote>
                <span>EXEMPLO:</span> Título do Formulário: "Novo Formulário 2021"
                / URL: "novo-form-2021"
              </InputNote>
            </Col>
            <Col xs={12} offset={0}>
              <Field
                component={CheckboxInput}
                name={'enabled'}
                label={'Formulário vísivel para resposta?'}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12} offset={0}>
              <DefaultLanguageTab>
                {GetTranslationDescription(activeLanguage?.code)}
              </DefaultLanguageTab>
              <Field
                component={TextInput}
                name={`title.${activeLanguage?.code}`}
                type='text'
                label={'Título *'}
                placeholder={'Título'}
              />
              <Field
                component={DraftInput}
                name={`description.${activeLanguage?.code}`}
                label={'Descrição *'}
                placeholder={'Descrição'}
              />
            </Col>
            <Col xs={12} offset={0}>
              <Tabs>
                {languages
                  .filter((x) => !x.active)
                  .map((language) => (
                    <TabPane
                      tab={GetTranslationDescription(language.code)}
                      key={language.code}
                    >
                      <Field
                        component={TextInput}
                        name={`title.${language.code}`}
                        type='text'
                        label={'Título *'}
                        placeholder={'Título'}
                      />
                      <Field
                        component={DraftInput}
                        name={`description.${language.code}`}
                        label={'Descrição *'}
                        placeholder={'Descrição'}
                      />
                    </TabPane>
                  ))}
              </Tabs>
            </Col>
          </Row>
        </Row>
        {form.type === 'question' && (
          <>
            <SectionTitle>Perguntas</SectionTitle>
            <FieldArray 
              component={this.renderQuestions}
              name="questions"
              type="text"
              label={translate("BO_LANGUAGES")}
              placeholder={translate("BO_LANGUAGES_PLACEHOLDER")}
            />
          </>
        )}
        <SectionTitle>Feedback</SectionTitle>
        <FieldArray 
          component={this.renderFeedbacks}
          name="feedbacks"
          type="text"
          label={translate("BO_LANGUAGES")}
          placeholder={translate("BO_LANGUAGES_PLACEHOLDER")}
        />
        <SectionTitle>Metatags</SectionTitle>
          <Row gutter={24}>
            <Col xs={12}>
              <DefaultLanguageTab>
                {GetTranslationDescription(activeLanguage?.code)}
              </DefaultLanguageTab>
              <Field
                component={TextInput}
                name={`meta_title.${activeLanguage?.code}`}
                label={'Meta Title'}
                type={'text'}
              />
              <Field
                component={TextAreaInput}
                name={`meta_description.${activeLanguage?.code}`}
                label={'Meta Description'}
              />
              <Field
                component={TextAreaInput}
                name={`meta_keywords.${activeLanguage?.code}`}
                label={'Meta Keywords'}
              />
            </Col>
            <Col xs={12}>
              <Tabs>
                {languages
                  .filter((x) => !x.active)
                  .map((language) => (
                    <TabPane
                      tab={GetTranslationDescription(language.code)}
                      key={language.code}
                    >
                      <Field
                        component={TextInput}
                        name={`meta_title.${language.code}`}
                        label={'Meta Title'}
                        type={'text'}
                      />
                      <Field
                        component={TextAreaInput}
                        name={`meta_description.${language?.code}`}
                        label={'Meta Description'}
                      />
                      <Field
                        component={TextAreaInput}
                        name={`meta_keywords.${language?.code}`}
                        label={'Meta Keywords'}
                      />
                    </TabPane>
                  ))}
              </Tabs>
            </Col>
          </Row>
      </BaseForm>
    )
  }

  downloadReport = async () => {
    const {  match: { params } } = this.props;
    const { answers, form } = this.state;

    try {
      if(params.id && answers?.length) {
        const name = TranslateValue(form?.title) || 'Teste a sua mente'
        const result = await DownloadMindForm({id: params.id, answers: answers, filename: name});

        if(result) {
          let filename = `${name.replace(/ /g,"_")}.xlsx`;
          if(result.headers) {
            const contentDisposition = result.headers.get('content-disposition');
            filename = contentDisposition.split('filename="')[1].split('";')[0];
          }

          //Create blob link to download
          const url = window.URL.createObjectURL(new Blob([result.blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);

          //Append to html link element page
          document.body.appendChild(link);
          
          //Start download
          link.click();
          
          //Clean up and remove the link
          link.parentNode.removeChild(link);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const {  match: { params }, handleSubmit, pristine, activeLanguage, languages, translate } = this.props;
    const { loading, form, answers } = this.state;
    
    if(loading) return <SpinLoading />;

    return( 
      <React.Fragment>
        <ManageFormHeader 
          titles={['Formulário', TranslateValue(form?.title), 'Editar']}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
            {
              type: 'secondary',
              icon: 'download',
              text: 'Exportar',
              onClick: this.downloadReport
            }
          ]}
        />
        <FormContainer>
          <TabsContainer style={{marginTop: 0}}>
            <Tab 
              tab='Dashboard'
              key='dashboard'
            >
              <DashboardComponent id={params.id} />
            </Tab>
            <Tab 
              tab='Detalhes'
              key='details'
            >
              {this.renderDetails()}
            </Tab>
            <Tab
              tab='Respostas'
              key='answers'
            >
              <AnswersList list={answers}/>
            </Tab>
          </TabsContainer>
        </FormContainer>
      </React.Fragment>
    )
  }
}

ManagerMindFormPage = reduxForm({
  form: 'manage_mind_form',
  validate: validations,
})(ManagerMindFormPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManagerMindFormPage));
