import client from '../config/AxiosConfig';

/* THEMES - CATEGORIES */
export const GetCategories = async (currentPage, limit, filters) => await client.get(`/about_themes_categories/${currentPage}/${limit}?filter=${filters}`);

export const GetCategory = async (id) => await client.get(`/about_themes_categories/${id}`);

export const CreateCategory = async (data) => await client.post(`/about_themes_categories`, data);

export const UpdateCategory = async (id, data) => await client.put(`/about_themes_categories/${id}`, data);

export const DeleteCategory = async (id) => await client.delete(`/about_themes_categories/${id}`);

export const GetCategoriesList = async () => await client.get(`/about_themes_categories`);

/* THEMES */
export const GetThemes = async (currentPage, limit, filters) => await client.get(`/about_themes/${currentPage}/${limit}?filter=${filters}`);

export const GetTheme = async (id) => await client.get(`/about_themes/id/${id}`);

export const ToggleThemes = async (id, data) => await client.put(`/about_themes/${id}/toggle`, data);

export const CreateTheme = async (data) => await client.post(`/about_themes`, data);

export const UpdateTheme = async (id, data) => await client.put(`/about_themes/${id}`, data);

export const DeleteTheme = async (id) => await client.delete(`/about_themes/${id}`);

export const UpdateThemesOrder = async (data) => await client.post(`/about_themes/order`, data);

/* THEMES - BLOCKS */
export const GetBlocks = async (id, currentPage, limit) => await client.get(`/about_themes_blocks/${id}/${currentPage}/${limit}`);

export const GetBlock = async (id) => await client.get(`/about_themes_blocks/${id}`);

export const CreateBlock = async (data) => await client.post(`/about_themes_blocks`, data);

export const UpdateBlock = async (id, data) => await client.put(`/about_themes_blocks/${id}`, data);

export const ToggleBlock = async (id, data) => await client.put(`/about_themes_blocks/${id}/toggle`, data);

export const DeleteBlock = async (id) => await client.delete(`/about_themes_blocks/${id}`);

export const UpdateBlocksOrder = async (data) => await client.post(`/about_themes_blocks/order`, data);