import React, { Component } from 'react';
import { Row, Col, Popconfirm } from 'antd';
import moment from 'moment'
import { withRouter } from 'react-router-dom';
import Crown_0 from '../../../assets/crowns/0.svg';
import Crown_1 from '../../../assets/crowns/1.svg';
import Crown_2 from '../../../assets/crowns/2.svg';
import Crown_3 from '../../../assets/crowns/3.svg';
import Crown_4 from '../../../assets/crowns/4.svg';
import Crown_5 from '../../../assets/crowns/5.svg';
import Crown_6 from '../../../assets/crowns/6.svg';
import Crown_7 from '../../../assets/crowns/7.svg';
import Crown_8 from '../../../assets/crowns/8.svg';
import Crown_9 from '../../../assets/crowns/9.svg';
import Crown_10 from '../../../assets/crowns/10.svg';
import Crown_11 from '../../../assets/crowns/11.svg';
import Crown_12 from '../../../assets/crowns/12.svg';
import {
  GetStudentDashboard
} from '../../../infra/requests/UsersRequests';
import {
  Separator, SpinLoading,
} from '../../../styles/BasicStyles';
import { PointsContainer, Points, Image } from '../UserStyles';
import { TranslateFromObject } from '../../../infra/services/translations/AvailableTranslations';
import { withLocalize } from 'react-localize-redux';
import WeekInput from '../../../components/generic/inputs/WeekInput';
import ChartActivities from './ChartActivities';
import ChartMeditation from './ChartMeditation';
import SwitchInput from '../../../components/generic/inputs/SwitchInput';
import BaseButton from '../../../components/generic/buttons/BaseButton';
import {
  CheckCircleTwoTone
} from '@ant-design/icons';

const chooseIcon = (crown) => {
  if(crown === 0) return Crown_0;
  if(crown === 1) return Crown_1;
  if(crown === 2) return Crown_2;
  if(crown === 3) return Crown_3;
  if(crown === 4) return Crown_4;
  if(crown === 5) return Crown_5;
  if(crown === 6) return Crown_6;
  if(crown === 7) return Crown_7;
  if(crown === 8) return Crown_8;
  if(crown === 9) return Crown_9;
  if(crown === 10) return Crown_10;
  if(crown === 11) return Crown_11;
  if(crown === 12) return Crown_12;
  return '';
};

class Dashboard extends Component {
  state = {
    info: undefined,
    week: moment.utc()
  };

  componentDidMount = async () => {
    this.updateData()
  };

  updateData = async () => {
    const { userInfo } = this.props;
    const { week } = this.state;
    try {
      const dateWeekStart = moment
        .utc(week)
        .clone()
        .startOf('isoWeek')
        .format('YYYY-MM-DD')
      const dateWeekEnd = moment
        .utc(week)
        .clone()
        .endOf('isoWeek')
        .format('YYYY-MM-DD')
      const result = await GetStudentDashboard(userInfo._id, dateWeekStart, dateWeekEnd)
      if (result.success) {
        const info = result.data
        this.setState({
          info: result.data
        })
      }
    } catch (error) {
      console.warn(error)
    }
  };

  onchangeDate = (date) => {
    if (!date) return
    this.setState(
      {
        week: date
      },
      () => this.updateData()
    )
  }

  disabledDate = current => {
    const weekEnd = moment
      .utc()
      .clone()
      .endOf('isoWeek')
    return current && current > weekEnd
  }

  render() {
    const { userInfo, isNew, activeLanguage, handleBlockUserProgram, handleCertificate, isLoading } = this.props;
    const { week, info } = this.state;

    return (
      <React.Fragment>
        {
          !isNew && (userInfo?.type === 3 || userInfo?.type === 4) &&
          <React.Fragment>
            {userInfo.type === 4 && (
            <Row style={{display: 'flex', alignItems: 'center'}}>
              <Col xs={8}>
                <PointsContainer>
                  <Points>Pontos: <span>{userInfo?.points}</span></Points>
                </PointsContainer>
              </Col>
              <Col xs={8}>
                <PointsContainer>
                  <Points>Total de Coroas: <span>{userInfo?.totalCrowns}</span></Points>
                </PointsContainer>
              </Col>
              <Col xs={8}>
                <PointsContainer style={{display: 'flex', alignItems: 'center'}}>
                  <Points>Coroa Atual:</Points>
                  <Image src={chooseIcon(userInfo?.crown)} alt='icon'/>
                </PointsContainer>
              </Col>
            </Row>
            )}
            <Row style={{display: 'flex', alignItems: 'center'}}>
              <Col xs={8}>
                <PointsContainer>
                  <Points>Sessão atual: <span>{TranslateFromObject(userInfo?.current_chapter?.session?.title, activeLanguage?.code)}</span></Points>
                </PointsContainer>
              </Col>
              <Col xs={8}>
                <PointsContainer>
                  <Points>Capítulo atual: <span>{TranslateFromObject(userInfo?.current_chapter?.title, activeLanguage?.code)}</span></Points>
                </PointsContainer>
              </Col>
            </Row>
            <Row style={{display: 'flex', alignItems: 'center'}}>
              <Col xs={8}>
                <PointsContainer>
                  <Points>Temas no fórum: <span>{info?.threads || 0}</span></Points>
                </PointsContainer>
              </Col>
              <Col xs={8}>
                <PointsContainer>
                  <Points>Comentários no fórum: <span>{info?.comments || 0}</span></Points>
                </PointsContainer>
              </Col>
            </Row>
            <Separator/>
            { userInfo?.type === 4 && (
              <>
                {isLoading ? (
                  <Row style={{display: 'flex', alignItems: 'center'}}>
                    <SpinLoading style={{minHeight: 80}} />
                  </Row>
                ) : (
                  <Row style={{display: 'flex', alignItems: 'center'}}> 
                    <Col xs={8}>
                      <SwitchInput
                        input={{value: userInfo?.block_program, onChange: handleBlockUserProgram}}
                        meta={{}}
                        name='block_program'
                        label='Programa desbloqueado'
                      />
                    </Col>
                    <Col xs={8}>
                      <PointsContainer>
                        <Points>Programa já foi desbloqueado: <br /><span>{userInfo?.already_block_program ? 'Sim' : 'Não'}</span></Points>
                      </PointsContainer>
                    </Col>
                    <Col xs={8}>
                      {userInfo?.programCompleted ? 
                      (
                        <Points>Certificado Emitido <span><CheckCircleTwoTone style={{fontSize: 20}} twoToneColor="#52c41a"/></span></Points>
                      )
                      : (<Popconfirm
                          placement='topRight'
                          title={'Tem a certeza que quer emitir o certificado?'}
                          onConfirm={() => handleCertificate()}
                        >
                          <BaseButton 
                            onClick={(e) => e.stopPropagation()}
                            type={'primary'}
                            icon={'file-done'}
                            text={'Emitir Certificado'}
                          />
                        </Popconfirm>
                      )}
                    </Col>
                  </Row>
                  )}
                <Separator/>
              </>
            )}
            { userInfo?.type === 4 &&
            <>
              <Row style={{display: 'flex', alignItems: 'center'}}>
                <Col xs={24} md={8}>
                  <WeekInput 
                    meta={{}}
                    picker='week'
                    input={{
                      value: week,
                      onChange: this.onchangeDate
                    }}
                    disabledDate={this.disabledDate}
                  />
                </Col>
              </Row>  
              <Row style={{display: 'flex', alignItems: 'center'}}>
                <Col xs={24} xl={16}>
                  <ChartActivities 
                    info={info?.activities}
                    isEducator={userInfo?.type === 3}
                  />
                </Col>
              </Row>  
              <Row style={{display: 'flex', alignItems: 'center'}}>
                <Col xs={24} xl={16}>
                  <ChartMeditation 
                    info={info?.meditation}
                    isEducator={userInfo?.type === 3}
                  />
                </Col>
              </Row> 
            </>
            } 
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

export default withLocalize(withRouter(Dashboard));