import client from '../config/AxiosConfig';

export const GetHelp = async (currentPage, limit, filters) => await client.get(`/help/${currentPage}/${limit}?filter=${filters}`);

export const GetHelpItem = async (id) => await client.get(`/help/${id}`);

export const CreateHelp = async (data) => await client.post(`/help`, data);

export const UpdateHelp = async (id, data) => await client.put(`/help/${id}`, data);

export const DeleteHelp = async (id) => await client.delete(`/help/${id}`);

export const UpdateHelpOrder = async (data) => await client.post(`/help/order`, data);