import React from 'react';
import PropTypes from 'prop-types';
import { Modal, notification } from 'antd';
import { reduxForm, Field } from 'redux-form';
import { BaseForm, FieldTitle } from '../../../styles/BasicStyles';
import DateInput from '../../../components/generic/inputs/DateInput';
import { withLocalize } from 'react-localize-redux';
import { UpdateDateClass } from '../../../infra/requests/FormsRequests';

let ManageAvailabilityDate = ({
  isOpen,
  onClose,
  handleSubmit,
  reset,
  classId,
  formId,
  className
}) => {
  const onConfirm = async(values) => {
    try {
      const { success } = await UpdateDateClass(formId, classId, values);

      if (success) {
        onClose();
        reset();
        
        notification.success({
          message: "Data de Disponibilidade alterada com sucesso!"
        });
      };
    } 
    catch (e) {
      console.error(e);
    }
  }
  return (
    <Modal
      title='Definir Data de Disponibilidade'
      visible={isOpen}
      onOk={handleSubmit(onConfirm)}
      onCancel={() => {
        onClose();
        reset();
      }}
      maskClosable={false}
    >
      <FieldTitle>Escolha uma Data de Disponibilidade para associar à {className}.</FieldTitle>
      <BaseForm onSubmit={handleSubmit(onConfirm)}>
        <Field 
          component={DateInput}
          name={'availability_date'}
        />
      </BaseForm>
    </Modal>
  );
};

ManageAvailabilityDate = reduxForm({
  form: 'availability_date_form',
  enableReinitialize: true,
})(ManageAvailabilityDate);

ManageAvailabilityDate.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default withLocalize(ManageAvailabilityDate);
