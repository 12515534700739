import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import { Row, Col, notification } from 'antd';
import {
  GetProfile,
  UpdateProfile
} from '../../infra/requests/UsersRequests';
import TextInput from '../../components/generic/inputs/TextInput';
import SelectInput from '../../components/generic/inputs/SelectInput';
import DateInput from '../../components/generic/inputs/DateInput';
import { 
  FormContainer, 
  BaseForm, 
  SpinLoading,
  FieldTitle
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import { getUserType } from '../../infra/utils/UserTypes';
import { SaveUser } from '../../redux/User/user.actions';
import { GenderTypes } from '../../infra/utils/GenderTypes';
import { getProgramType } from '../../infra/utils/ProgramsTypes';
import { getCategoryType } from '../../infra/utils/CategoriesTypes';

const validations = FormValidator.make({
  name: 'required',
  email: 'required|email'
});

class ManageProfilePage extends Component {
  state = {
    loading: true
  };

  componentDidMount = async () => {
    await this.loadDetail();
  };

  loadDetail = async () => {
    const { dispatch, CloseMenu } = this.props;

    CloseMenu();

    const result = await GetProfile();
    dispatch(
      initialize('manage_profile_form', {
        ...result.data,
      })
    );

    this.setState({ loading: false });
  }

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const { user, dispatch } = this.props;

      const request = await UpdateProfile(user._id, values);

      this.setState({ loading: false });

      if(request.success) {
        dispatch(SaveUser(request.data));

        notification.success({
          message: "Sucesso!",
          description: "Perfil atualizado com sucesso."
        });

        return await this.loadDetail();
      }
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  renderCategory = (value, other) => {
    let result = '-';

    if(value > 0) {
      result = getCategoryType(value);
      if(value === 3) result = `${result} (${other})`;
    }

    return result;
  }

  renderProgram = (value) => {
    return value > 0 ? getProgramType(value) : '-';
  }

  render() {
    const { handleSubmit, pristine, user } = this.props;
    const { loading } = this.state;

    if(loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['O Meu Perfil', 'Editar']}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            }
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24}>
              <Col xs={12}>
                <FieldTitle>Tipo: <span>{ getUserType(user?.type) }</span></FieldTitle>
              </Col>
            </Row>
            {
              user?.type === 2 &&
              <React.Fragment>
                <Row gutter={24} style={{ marginTop: '15px' }}>
                  <Col xs={12}>
                    <FieldTitle>Categoria: <span>{ this.renderCategory(user?.applicator_category, user?.applicator_category_other) }</span></FieldTitle>
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginTop: '15px' }}>
                  <Col xs={12}>
                    <FieldTitle>Programa aplicado: <span>{ this.renderProgram(user?.applicator_program) }</span></FieldTitle>
                  </Col>
                </Row>
              </React.Fragment>
            }
            <Row gutter={24}>
              <Col xs={12}>
                <Field
                  component={TextInput}
                  name='name'
                  type='text'
                  label='Nome *'
                  placeholder='Primeiro e último nome'
                />
              </Col>
              <Col xs={12}>
                <Field
                  component={TextInput}
                  name='email'
                  type='email'
                  label='Email *'
                  placeholder='Email do utilizador'
                />
              </Col>
            </Row>
            {
              user?.type === 2 &&
              <React.Fragment>
                <Row gutter={24} style={{ marginTop: '15px' }}>
                  <Col xs={12}>
                    <Field
                      component={DateInput}
                      name='birthday'
                      label='Data de Nascimento'
                      placeholder='Data de nascimento'
                    />
                  </Col>
                  <Col xs={12}>
                    <Field
                      component={SelectInput}
                      name={'gender'}
                      label={'Sexo'}
                      placeholder={'Escolha o sexo'}
                      type='select'
                      data={GenderTypes}
                      dataKey={'_id'}
                      dataLabel={'name'}
                      allowClear={true}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            }
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageProfilePage = reduxForm({
  form: 'manage_profile_form',
  validate: validations,
})(ManageProfilePage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu, SaveUser }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageProfilePage));