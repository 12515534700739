import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Row, Col, notification } from 'antd';
import { withLocalize } from 'react-localize-redux';
import moment from 'moment';
import {
  GetThread,
  UpdateThread
} from '../../infra/requests/ForumRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  TabsContainer,
  Tab
} from '../../styles/BasicStyles';
import {
  InfoContainer
} from './Styles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import TextInput from '../../components/generic/inputs/TextInput';
import TextAreaInput from '../../components/generic/inputs/TextAreaInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import CommentsList from './components/CommentsList';
import { getForum } from '../../infra/utils/ForumTypes';

const validations = FormValidator.make({
  title: 'required',
  description: 'required'
});

class ManageThreadsPage extends Component {
  state = { 
    loading: true,
    thread: undefined,
    defaultTab: 'details',
    forumName: '',
    forumID: ''
  };

  componentDidMount = async () => {
    const {user, history} = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 
    
    await this.loadDetail();
  };

  loadDetail = async () => {
    const {
      match: { params },
      history,
      dispatch,
      CloseMenu
    } = this.props;

    const forumID = params.forum;
    const forum = getForum(forumID);
    const forumName = forum?.name || '';

    if(!forumName) {
      return history.push('/');
    }
    
    CloseMenu();

    const result = await GetThread(params.id);

    dispatch(
      initialize('manage_threads_form', {
        ...result.data,
      })
    );

    let defaultTab = 'details';
    if(history?.location?.search?.includes('comments=true')) {
      defaultTab = 'comments';
      history.replace(`/forum/${forumID}/${result?.data?._id}`);
    } 

    this.setState({ 
      loading: false,
      thread: result?.data,
      defaultTab,
      forumName,
      forumID
    });
  }

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const { match: { params } } = this.props;

      const { success } = await UpdateThread(params.id, values);

      if(success) {
        notification.success({
          message: "Sucesso!",
          description: "Alterações guardadas com sucesso."
        });
        return await this.loadDetail();
      }

      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    const { forumID } = this.state;
    return history.push(`/forum/${forumID}`);
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { loading, thread, defaultTab, forumName, forumID } = this.state;

    if(loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={[`Fórum ${forumName}`, thread?.title, 'Editar']}
          titlePosition={1}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <TabsContainer defaultActiveKey={defaultTab}>
            <Tab
              tab='Detalhes'
              key='details'
            >
              <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
                <Row>
                  <Row gutter={24} style={{ marginTop: '10px' }}>
                    <Col xs={18} offset={3}>
                      <InfoContainer>
                        Autor: <span>{thread?.author?.name}</span>
                      </InfoContainer>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={18} offset={3}>
                    <InfoContainer>
                        Data Criação: <span>{moment.utc(thread?.createdAt).format('DD/MM/YYYY, HH:mm')}h</span>
                      </InfoContainer>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginTop: '20px' }}>
                    <Col xs={18} offset={3}>
                      <Field
                        component={TextInput}
                        name='title'
                        type='text'
                        label={'Título *'}
                        placeholder={'Título'}
                      />
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={18} offset={3}>
                      <Field
                        component={TextAreaInput}
                        name='description'
                        label={'Descrição *'}
                        placeholder={'Descrição'}
                        minRows={6}
                      />
                    </Col>
                  </Row>
                </Row>
              </BaseForm>
            </Tab>
            <Tab
              tab='Comentários'
              key='comments'
            >
              <CommentsList 
                threadID={thread?._id}
                forumID={forumID}
              />
            </Tab>
          </TabsContainer>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageThreadsPage = reduxForm({
  form: 'manage_threads_form',
  validate: validations,
})(ManageThreadsPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageThreadsPage));