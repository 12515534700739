import React, { Component } from 'react';
import Table from '../../../components/generic/table/Table';
import { withRouter } from 'react-router-dom';
import { getStepType } from '../../../infra/utils/StepTypes';

class StepsList extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: true,
    columns: [
      {
        title: 'Ordem',
        dataIndex: 'order',
        render: (value) => value,
        width: '30%',
      },
      {
        title: 'Tipo',
        dataIndex: 'type',
        render: (value) => getStepType(value),
        width: '70%',
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = () => {
    const { list } = this.props;

    this.setState({
      rows: list || [],
      total: list?.length || 0,
      loading: false
    });
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/programs/steps/${record._id}`);
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
    } = this.state;

    return (
      <Table
        columns={columns}
        currentPage={currentPage}
        pageSize={pageSize}
        loading={loading}
        rows={rows}
        showHeader={true}
        emptyIcon='warning'
        emptyText={'Não existem Cenas inseridas!'}
        total={total}
        rowKey={'_id'}
        hasPagination={false}
        onPressRow={this.handleOnPressRow}
      />
    );
  }
}

export default withRouter(StepsList); 