import React, { Component } from 'react';
import Table from '../../../components/generic/table/Table';
import { withRouter } from 'react-router-dom';
import { Answer } from '../MindStyles';
import moment from 'moment';
import { getGenderType } from '../../../infra/utils/GenderTypes';

class AnswersList extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: true,
    expandedRowKeys: '',
    columns: [
      {
        title: 'Data de Nascimento',
        dataIndex: 'birthday',
        render: (value) => moment(value).format('DD-MM-YYYY'),
      },
      {
        title: 'Idade quando respondeu ao formulário',
        dataIndex: 'age',
      },
      {
        title: 'Género',
        dataIndex: 'gender',
        render: (value) => getGenderType(value)
      },
      {
        title: 'Pontuação Total',
        dataIndex: 'total'
      },
      {
        title: 'Data de Resposta',
        dataIndex: 'createdAt',
        render: (value) => moment(value).format('DD-MM-YYYY'),
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = () => {
    const { list } = this.props;

    this.setState({
      rows: list || [],
      total: list?.length || 0,
      loading: false
    });
  };

  onExpand = (exp, data) => {
    var keys = [];

    if(exp){
        keys.push(data._id); // I have set my record.id as row key.
    }

    this.setState({
      expandedRowKeys: keys
    });
  }

  renderExpandedRowRender = (data, index) => {
    return(
      <div>
        {data?.answers.map((answer, index) => (
          <Answer key={index}>{answer?.question} <span>{answer?.answer}</span></Answer>
        ))}
      </div>
    )
  }

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
      expandedRowKeys
    } = this.state;
    const {applicator, admin} = this.props;

    return (
      <Table
        secondary={applicator || admin}
        columns={columns}
        currentPage={currentPage}
        pageSize={pageSize}
        loading={loading}
        rows={rows}
        showHeader={true}
        emptyIcon='warning'
        emptyText={'Não existem Formulários inseridos!'}
        total={total}
        rowKey={'_id'}
        hasPagination={false}
        expandedRowRender={((data, index) => this.renderExpandedRowRender(data, index))}
        expandedRowKeys={expandedRowKeys}
        onExpand={(exp, data) => this.onExpand(exp, data)}
      />
    );
  }
}

export default withRouter(AnswersList);