import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetUser,
  GetApplicator,
  CreateApplicator,
  UpdateApplicator,
  GetApplicatorsList
} from '../../../infra/requests/UsersRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading
} from '../../../styles/BasicStyles';
import FormValidator from '../../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../../components/base/ManageFormHeader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../../redux/Menu/menu.actions';
import SelectInput from '../../../components/generic/inputs/SelectInput';

const validations = FormValidator.make({
  applicator: 'required'
});

class ManageParentsApplicatorsList extends Component {
  state = { 
    isNew: false,
    loading: true,
    applicators: [],
    userInfo: undefined
  };

  componentDidMount = async () => {
    const { user, history } = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 
      
    await this.loadDetail();
  };

  loadDetail = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu
    } = this.props;
    
    CloseMenu();

    const applicators = await GetApplicatorsList();
    const userInfo = await GetUser(params.user);

    if(params.id) {
      const result = await GetApplicator(params.user, params.id);
    
      dispatch(
        initialize('manage_applicators_form', {
          ...result.data,
        })
      );

      this.setState({ 
        isNew: false,
        loading: false,
        applicators: applicators?.data || [],
        userInfo: userInfo?.data
      });
    }
    else {
      this.setState({ 
        isNew: true,
        loading: false,
        applicators: applicators?.data || [],
        userInfo: userInfo?.data
      });
    }
  }

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const { history, match: { params } } = this.props;
      const { isNew } = this.state;

      const { success } = isNew ? await CreateApplicator(params.user, values) : await UpdateApplicator(params.user, params.id, values);

      if(success) {
        return history.push(`/users/${params.user}?applicators=true`);
      }

      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history, match: { params } } = this.props;

    return history.push(`/users/${params.user}?applicators=true`);
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { isNew, loading, applicators, userInfo } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Utilizadores', userInfo?.name, 'Aplicadores', title]}
          titlePosition={2}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24}>
              <Col xs={12} offset={6}>
                <Field
                  component={SelectInput}
                  name={'applicator'}
                  label={'Aplicador *'}
                  placeholder={'Escolha o aplicador'}
                  type='select'
                  data={applicators}
                  dataKey={'_id'}
                  dataLabel={'name'}
                />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageParentsApplicatorsList = reduxForm({
  form: 'manage_applicators_form',
  validate: validations,
})(ManageParentsApplicatorsList);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageParentsApplicatorsList));