import client from '../config/AxiosConfig';

/* SCHOOLS */
export const GetSchools = async (currentPage, limit, filters) => await client.get(`/schools/${currentPage}/${limit}?filter=${filters}`);

export const GetSchool = async (id) => await client.get(`/schools/${id}`);

export const CreateSchool = async (data) => await client.post(`/schools`, data);

export const UpdateSchool = async (id, data) => await client.put(`/schools/${id}`, data);

export const DeleteSchool = async (id) => await client.delete(`/schools/${id}`);

export const GetSchoolsList = async () => await client.get(`/schools`);

/* CLASSES */
export const GetClasses = async (currentPage, limit, filters) => await client.get(`/schools_classes/${currentPage}/${limit}?filter=${filters}`);

export const GetClass = async (id) => await client.get(`/schools_classes/${id}`);

export const CreateClass = async (data) => await client.post(`/schools_classes`, data);

export const UpdateClass = async (id, data) => await client.put(`/schools_classes/${id}`, data);

export const DeleteClass = async (id) => await client.delete(`/schools_classes/${id}`);

export const GetClassesList = async (id) => await client.get(`/schools_classes/school/${id}`);

/* APPLICATORS */
export const GetClassApplicator = async (classID, id) => await client.get(`/schools_classes/applicator/${classID}/${id}`);

export const CreateClassApplicator = async (classID, data) => await client.post(`/schools_classes/applicator/${classID}`, data);

export const UpdateClassApplicator = async (classID, id, data) => await client.put(`/schools_classes/applicator/${classID}/${id}`, data);

export const DeleteClassApplicator = async (classID, id) => await client.delete(`/schools_classes/applicator/${classID}/${id}`);

/* STUDENTS */

export const CreateClassStudent = async (classID, data) => await client.post(`/schools_classes/student/${classID}`, data);

export const DeleteClassStudent = async (classID, id) => await client.delete(`/schools_classes/student/${classID}/${id}`);