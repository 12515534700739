import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import {VictoryChart, VictoryLine, VictoryAxis} from 'victory'
import { ChartBox, QuestionTitle, ChartBoxInfo, Title, LegendMeditationContainer, BoxColor, LegendLine, TextTitle } from '../UserStyles';
import { SecondaryColour, PrimaryColour, MenuBorderColor, HoverBorderColor } from '../../../styles/Colours';
import { withLocalize } from 'react-localize-redux';

const daysOfWeek = [
  'SEGUNDA',
  'TERÇA',
  'QUARTA',
  'QUINTA',
  'SEXTA',
  'SABADO',
  'DOMINGO'
]

const xOffsets = [50, 400]
const tickValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
const tickPadding = [0, -35]
const anchors = ['end', 'end', 'start']

const ChartActivities = ({
  info,
  week,
  translate,
  isEducator
}) => {
  const colors = isEducator
    ? [SecondaryColour]
    : [PrimaryColour, SecondaryColour]
  const [data, setData] = useState(undefined)
  const [maxima, setMaxima] = useState(0)
  const [averageA, setAverageA] = useState(0)
  const [averageB, setAverageB] = useState(0)


  useEffect(() => {
    if (info) {
      setAverageA(isEducator ? undefined : info.mediaActivities)
      setAverageB(info.mediaEmotions)
      const newDataA = []
      const newDataB = []
      info.values.forEach((value, i) => {
        newDataA.push({x: i + 1, y: value.activities})
        newDataB.push({x: i + 1, y: value.emotions})
      })
      if (isEducator) {
        setData([newDataB])
        const mx = [newDataB].map((dataset, i) => {
          const m = Math.max(...dataset.map((d) => d.y))
          let max = m
          if (i === 0) max = Math.max(m, 10)
          else max = Math.max(m, 100)
          return max
        })
        setMaxima(mx)
      } else {
        setData([newDataA, newDataB])
        const mx = [newDataA, newDataB].map((dataset, i) => {
          const m = Math.max(...dataset.map((d) => d.y))
          let max = m
          if (i === 0) max = Math.max(m, 10)
          else max = Math.max(m, 100)
          return max
        })
        setMaxima(mx)
      }
    }
  }, [info, isEducator])

  const renderChart = () => (
    <VictoryChart
      style={{width: '100%', height: 375}}
      domain={{y: [0, 1], x: [0, 8]}}
    >
      <VictoryAxis
        style={{
          axis: {stroke: MenuBorderColor},
          tickLabels: {
            fill: HoverBorderColor,
            fontSize: 14,
            fontFamily: 'Poppins'
          },
          grid: {stroke: MenuBorderColor}
        }}
        tickFormat={(value) =>
          value
            ? value === 0 || value === 8
              ? ''
              : daysOfWeek[value - 1].slice(0, 3)
            : undefined
        }
        tickValues={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
      />
      {data?.map((d, i) => (
        <VictoryAxis
          dependentAxis
          key={i}
          offsetX={xOffsets[i]}
          style={{
            axis: {stroke: MenuBorderColor},
            ticks: {padding: tickPadding[i]},
            tickLabels: {
              fill: HoverBorderColor,
              textAnchor: anchors[i],
              fontSize: 14,
              fontFamily: 'Poppins'
            },
            grid: {stroke: MenuBorderColor}
          }}
          // Use normalized tickValues (0 - 1)
          tickValues={tickValues * maxima[i]}
          // Re-scale ticks by multiplying by correct maxima
          tickFormat={(t) => t * maxima[i]}
        />
      ))}
      {data?.map((d, i) => (
        <VictoryLine
          key={i}
          data={d}
          style={{data: {stroke: colors[i]}}}
          y={(datum) => datum.y / maxima[i]}
        />
      ))}
    </VictoryChart>
  )

  return (
    <ChartBox>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={16} lg={18}>
          <QuestionTitle margin={-44}>
            {
              isEducator
                ? 'Gráfico do diário de humor'
                : 'Gráfico da frequência de atividades de lazer e diário de humor'
            }
          </QuestionTitle>
          {renderChart()}
        </Col>
        <Col sm={24} md={8} lg={6}>
          <div>
            {!isEducator && (
              <ChartBoxInfo isLeft>
                <Title>Media de Atividades</Title>
                <TextTitle style={{color: PrimaryColour}}>
                  {averageA}
                </TextTitle>
              </ChartBoxInfo>
            )}
            <ChartBoxInfo>
              <Title>Media de Emoções</Title>
              <TextTitle style={{color: SecondaryColour}}>
                {averageB}
              </TextTitle>
            </ChartBoxInfo>
          </div>
          <LegendMeditationContainer>
            <Row gutter={[16]}>
              {!isEducator && (
                  <LegendLine>
                    <BoxColor color={PrimaryColour} />
                    <TextTitle>
                      Número de Atividades de Lazer
                    </TextTitle>
                  </LegendLine>
              )}
                <LegendLine>
                  <BoxColor />
                  <TextTitle>
                    Intensidade das Emoções
                  </TextTitle>
                </LegendLine>
            </Row>
          </LegendMeditationContainer>
        </Col>
      </Row>
    </ChartBox>
  )
}

export default withLocalize(ChartActivities);