
import React, { Component } from 'react';
import { Popconfirm, Icon } from 'antd';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton,
} from '../../styles/BasicStyles';
import {
  GetSchools,
  DeleteSchool
} from '../../infra/requests/SchoolsRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import SchoolsFilters from './SchoolsFilters';
import { connect } from 'react-redux';
import { getDistrict } from '../../infra/utils/Districts';

class SchoolsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    const {user} = this.props;

    if (user?.type === 2 ) {
      this.setState({
        columns: [
          {
            title: 'Nome',
            dataIndex: 'name',
            width: '35%'
          },
          {
            title: 'Distrito',
            dataIndex: 'district',
            render: (value) => getDistrict(value),
            width: '35%'
          }
        ]
      })
    } else {
      this.setState({
        columns: [
          {
            title: 'Nome',
            dataIndex: 'name',
            width: '35%'
          },
          {
            title: 'Distrito',
            dataIndex: 'district',
            render: (value) => getDistrict(value),
            width: '35%'
          },
          {
            title: 'Ações',
            render: (data) => (
              <React.Fragment>
                <TableButton error onClick={(e) => e.stopPropagation()}>
                  <Popconfirm
                    placement='topRight'
                    title='Tem a certeza que quer remover esta Escola?'
                    onConfirm={() => this.removeSchool(data)}
                  >
                    <Icon style={{ marginRight: 10 }} type='delete' />
                    {'Remover'}
                  </Popconfirm>
                </TableButton>
              </React.Fragment>
            ),
          }
        ]
      })
    }

    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/schools/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  removeSchool = async (data) => {
    try {
      await DeleteSchool(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetSchools(currentPage, pageSize, filters);

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize
    } = this.state;
    const { user, history } = this.props;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Lista de Escolas</PageTitle>
            {`Escolas`}
          </HeaderTitle>
          {user?.type !== 2 && (
            <HeaderButtonsContainer buttons={1}>
              <BaseButton
                type={'primary'}
                icon={'plus'}
                text={'Criar'}
                onClick={() => history.push('/schools/add')}
              />
            </HeaderButtonsContainer>
          )}
        </HeaderContainer>
        <PageContainer>
          <SchoolsFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Escolas inseridas!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={user?.type !== 2 && this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, null)(SchoolsPage);