import React, { Component } from 'react';
import { Popconfirm, Icon } from 'antd';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton,
} from '../../styles/BasicStyles';
import {
  GetFaqs,
  DeleteFaq,
  UpdateFaqsOrder,
} from '../../infra/requests/FaqsRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import FaqsFilters from './FaqsFilters';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import OrderFaqs from './OrderFaqs';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';

class FaqsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Título',
        dataIndex: 'title',
        render: (value) => TranslateValue(value),
      },
      {
        title: 'Ações',
        render: (data) => (
          <React.Fragment>
            <TableButton error onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement='topRight'
                title='Tem a certeza que quer remover esta FAQ?'
                onConfirm={() => this.removeFaq(data)}
              >
                <Icon style={{ marginRight: 10 }} type='delete' />
                {'Remover'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        ),
      },
    ],
    rows: [],
    total: 0,
    orderModal: false,
  };

  componentDidMount = async () => {
    const { user, history } = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 

    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/website/faqs/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  removeFaq = async (data) => {
    try {
      await DeleteFaq(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetFaqs(currentPage, pageSize, filters);

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  closeOrderModal = () => {
    this.setState({ orderModal: false });
  };

  openOrderModal = (faqs) => {
    const { dispatch } = this.props;
    dispatch(initialize('manage_order_faqs_form', { faqs: [...faqs] }, false));

    this.setState({ orderModal: true });
  };

  onSubmitOrder = async (values) => {
    try {
      this.setState({ loading: true });

      if (values.faqs.length > 0) {
        let orderedList = [];
        Object.keys(values.faqs).forEach(function (key) {
          let obj = { _id: values.faqs[key]._id, order: key };
          orderedList.push(obj);
        });

        if (orderedList.length > 0) {
          const res = await UpdateFaqsOrder({ order: orderedList });

          if (res.success) {
            this.updateDataTable();
          }
        }
      }

      this.setState({ loading: false });
      this.closeOrderModal();
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
      orderModal,
    } = this.state;
    const { history } = this.props;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Lista de FAQs</PageTitle>
            {`FAQs`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/website/faqs/add')}
            />
            <BaseButton
              type='default'
              icon='retweet'
              text='Editar Ordem'
              onClick={() => this.openOrderModal(rows)}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <FaqsFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem FAQs inseridas!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
        <OrderFaqs
          open={orderModal}
          loading={loading}
          onSubmit={this.onSubmitOrder}
          closeModal={this.closeOrderModal}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(FaqsPage);
