import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col, notification } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetIntro,
  UpdateIntro
} from '../../infra/requests/AboutRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab,
  TabsContainer,
  Tab
} from '../../styles/BasicStyles';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/generic/inputs/TextInput';
import DraftInput from '../../components/generic/inputs/DraftInput';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";
import MottosList from './components/MottosList';
const TabPane = Tabs.TabPane;

const id = '604b7460bf4da172c732689c';

class ManageMottosPage extends Component {
  state = {  
    loading: true,
    defaultTab: 'details'
  };

  componentDidMount = async () => {
    const {
      dispatch,
      CloseMenu,
      history,
      user
    } = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 

    CloseMenu();

    let defaultTab = 'details';
    if(history?.location?.search?.includes('mottos=true')) {
      defaultTab = 'mottos';
      history.replace(`/website/about/programs/mottos`);
    } 

    const result = await GetIntro(id);
    dispatch(
      initialize('manage_programs_mottos_form', {
        ...result.data,
      })
    );

    this.setState({ defaultTab, loading: false });
  };

  onSubmit = async (values) => {
    try {
      const { dispatch } = this.props;

      this.setState({ loading: true });
      
      const payload = FlattenToFormData(values);
      const { data, success } = await UpdateIntro(id, payload);

      if(success) {
        dispatch(
          initialize('manage_programs_mottos_form', {
            ...data,
          })
        );

        notification.success({
          message: "Registo alterado com sucesso!"
        });
      }
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  render() {
    const { handleSubmit, pristine, activeLanguage, languages } = this.props;
    const { loading, defaultTab } = this.state;

    if(loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Sobre', 'Programas SMS', 'Lemas', 'Editar']}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            }
          ]}
        />
        <FormContainer>
          <TabsContainer defaultActiveKey={defaultTab}>
            <Tab
              tab='Introdução'
              key='details'
            >
              <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
                <Row>
                  <Row gutter={24}>
                    <Col xs={12} offset={0}>
                      <DefaultLanguageTab>
                        {GetTranslationDescription(activeLanguage?.code)}
                      </DefaultLanguageTab>
                      <Field
                        component={TextInput}
                        name={`title1.${activeLanguage?.code}`}
                        type='text'
                        label={'Título'}
                        placeholder={'Título'}
                      />
                      <Field
                        component={DraftInput}
                        name={`description1.${activeLanguage?.code}`}
                        label={'Descrição'}
                        placeholder={'Descrição'}
                      />
                    </Col>
                    <Col xs={12} offset={0}>
                      <Tabs>
                        {languages
                          .filter((x) => !x.active)
                          .map((language) => (
                            <TabPane
                              tab={GetTranslationDescription(language.code)}
                              key={language.code}
                            >
                              <Field
                                component={TextInput}
                                name={`title1.${language.code}`}
                                type='text'
                                label={'Título'}
                                placeholder={'Título'}
                              />
                              <Field
                                component={DraftInput}
                                name={`description1.${language.code}`}
                                label={'Descrição'}
                                placeholder={'Descrição'}
                              />
                            </TabPane>
                          ))}
                      </Tabs>
                    </Col>
                  </Row>
                </Row>
              </BaseForm>
            </Tab>
            <Tab
              tab='Lemas'
              key='mottos'
            >
              <MottosList/>
            </Tab>
          </TabsContainer>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageMottosPage = reduxForm({
  form: 'manage_programs_mottos_form'
})(ManageMottosPage);

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageMottosPage));