import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetUser,
  GetParent,
  CreateParent,
  UpdateParent,
  GetParentsList
} from '../../../infra/requests/UsersRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading
} from '../../../styles/BasicStyles';
import FormValidator from '../../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../../components/base/ManageFormHeader';
import TextInput from '../../../components/generic/inputs/TextInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../../redux/Menu/menu.actions';
import { ParentTypes } from '../../../infra/utils/ParentTypes';
import SelectInput from '../../../components/generic/inputs/SelectInput';

const validations = FormValidator.make({
  type: 'required',
  parent: 'required'
});

class ManageParentsList extends Component {
  state = { 
    isNew: false,
    loading: true,
    showOther: false,
    parents: [],
    userInfo: undefined
  };

  componentDidMount = async () => {
    const { user, history } = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 
      
    await this.loadDetail();
  };

  loadDetail = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu
    } = this.props;
    
    CloseMenu();

    const parents = await GetParentsList();
    const userInfo = await GetUser(params.user);

    if(params.id) {
      const result = await GetParent(params.user, params.id);
  console.log(result, params.user, params.id)      
      if (result.success) {
        dispatch(
          initialize('manage_parents_form', {
            ...result.data,
          })
        );

        this.setState({ 
          isNew: false,
          loading: false,
          showOther: result?.data.type === 3 ? true : false,
          parents: parents?.data || [],
          userInfo: userInfo?.data
        });
      } else {
        this.setState({ 
          loading: false,
          showOther: false,
        });

      }
    }
    else {
      this.setState({ 
        isNew: true,
        loading: false,
        showOther: false,
        parents: parents?.data || [],
        userInfo: userInfo?.data
      });
    }
  }

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const { history, match: { params } } = this.props;
      const { isNew } = this.state;

      const { success } = isNew ? await CreateParent(params.user, values) : await UpdateParent(params.user, params.id, values);

      if(success) {
        return history.push(`/users/${params.user}?parents=true`);
      }

      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history, match: { params } } = this.props;

    return history.push(`/users/${params.user}?parents=true`);
  };

  checkType = (value) => {
    if(parseInt(value) === 3) this.setState({ showOther: true });
    else this.setState({ showOther: false });
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { isNew, loading, showOther, parents, userInfo } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Utilizadores', userInfo?.name, 'Encarregados de Educação', title]}
          titlePosition={2}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24}>
              <Col xs={12}>
                <Field
                  component={SelectInput}
                  name={'parent'}
                  label={'Encarregado de Educação *'}
                  placeholder={'Escolha o utilizador'}
                  type='select'
                  data={parents}
                  dataKey={'_id'}
                  dataLabel={'name'}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12}>
                <Field
                  component={SelectInput}
                  name={'type'}
                  label={'Tipo de Relação *'}
                  placeholder={'Escolha o tipo de relação'}
                  type='select'
                  data={ParentTypes}
                  dataKey={'_id'}
                  dataLabel={'name'}
                  execAfterChange={this.checkType}
                />
              </Col>
              {
                showOther &&
                <Col xs={12}>
                  <Field
                    component={TextInput}
                    name='type_other'
                    type='text'
                    label='Qual?'
                    placeholder='Outra tipo de relação'
                  />
                </Col>
              }
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageParentsList = reduxForm({
  form: 'manage_parents_form',
  validate: validations,
})(ManageParentsList);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageParentsList));