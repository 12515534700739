import React, { Component } from 'react';
import Table from '../../../components/generic/table/Table';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Popconfirm, Icon } from 'antd';
import {
  TableButton
} from '../../../styles/BasicStyles';
import {
  StateLabel
} from '../Styles';
import {
  GetSubComments,
  DeleteSubComment,
  ValidateSubComment
} from '../../../infra/requests/ForumRequests';
import {
  GetUsersList
} from '../../../infra/requests/UsersRequests';
import CommentsFilters from './CommentsFilters';

class SubCommentsList extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Data de Criação',
        dataIndex: 'createdAt',
        render: (value) => `${moment.utc(value).format('DD-MM-YYYY, HH:mm')}h`,
        width: '15%'
      },
      {
        title: 'Autor',
        dataIndex: 'author',
        render: (value) => value?.name,
        width: '18%'
      },
      {
        title: 'Comentário',
        dataIndex: 'description',
        render: (value) => value.length > 100 ? `${value.substr(0, 100)}...` : value,
        width: '35%'
      },
      {
        title: 'Estado (Autor)',
        dataIndex: 'pendingUser',
        render: (value) => this.renderState(value),
        width: '10%'
      },
      {
        title: 'Estado (Admin)',
        dataIndex: 'pending',
        render: (value) => this.renderState(value),
        width: '10%'
      },
      {
        title: 'Ações',
        render: (data) => (
          <React.Fragment>
            {
              data.pending &&
              <TableButton onClick={(e) => e.stopPropagation()}>
                <Popconfirm
                  placement='topRight'
                  title='Tem a certeza que pretende validar este Comentário?'
                  onConfirm={() => this.validateComment(data)}
                >
                  <Icon style={{ marginRight: 5 }} type='close-circle'/>
                  Validar
                </Popconfirm>
              </TableButton>
            }
            <TableButton error onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement='topRight'
                title='Tem a certeza que quer remover este Comentário?'
                onConfirm={() => this.removeComment(data)}
              >
                <Icon style={{ marginRight: 10 }} type='delete' />
                {'Remover'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        ),
      }
    ],
    rows: [],
    total: 0,
    usersList: []
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { commentID } = this.props;
      let { filters } = this.state;

      const result = await GetSubComments(commentID, filters);
      const usersList = await GetUsersList();

      this.setState({
        rows: result?.data || [],
        total: result?.data?.length || 0,
        usersList: usersList?.data || []
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleOnPressRow = (record) => {
    const { history, forumID, threadID, commentID } = this.props;
    history.push(`/forum/${forumID}/${threadID}/${commentID}/${record._id}`);
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  removeComment = async (data) => {
    try {
      const { commentID } = this.props;
      await DeleteSubComment(commentID, data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  validateComment = async (data) => {
    try {
      const { commentID } = this.props;
      await ValidateSubComment(commentID, data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  renderState = (value) => {
    return (
      <StateLabel
        bgColor={value ? '#cf1322' : '#389e0d'}
      >
        {value ? 'Não Validado' : 'Validado'}
      </StateLabel>
    );
  }

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
      usersList
    } = this.state;

    return (
      <React.Fragment>
        <CommentsFilters queryChange={this.handleFilterChange} usersList={usersList}/>
        <Table
          columns={columns}
          currentPage={currentPage}
          pageSize={pageSize}
          loading={loading}
          rows={rows}
          showHeader={true}
          emptyIcon='warning'
          emptyText={'Não existem Comentários inseridos!'}
          total={total}
          rowKey={'_id'}
          hasPagination={false}
          onPressRow={this.handleOnPressRow}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(SubCommentsList);