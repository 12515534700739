import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetForm,
  UpdateForm,
  CreateForm
} from '../../infra/requests/FormsRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab,
} from '../../styles/BasicStyles';
import {
  Title
} from './FormsStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/generic/inputs/TextInput';
import DraftInput from '../../components/generic/inputs/DraftInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";
import Table from '../../components/generic/table/Table';
import { GetClass, GetClasses } from '../../infra/requests/SchoolsRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import ManageAvailabilityDate from './components/ManageAvailabilityDate';
import AddDateStudent from './components/AddDateStudent';
import SelectInput from '../../components/generic/inputs/SelectInput';
import { FormUserTypeTypes, getFormUserType } from '../../infra/utils/FormUserTypeTypes';
import { GetApplicatorParentsList, GetParentsList } from '../../infra/requests/UsersRequests';
import { TextTitle } from '../users/UserStyles';
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  name: 'required',
  tag: 'required',
  user_type: 'required',
  title: 'required|languages',
  description: 'required|languages'
});

class ManageFormsPage extends Component {
  state = { 
    loading: true,
    info: undefined,
    pageSize: 30,
    currentPage: 1,
    columns: [],
    rows: [],
    total: 0,
    filters: '',
    columnsUser: [],
    rowsUser: [],
    expandedRowKeys: '',
    openModal: false,
    classId: '',
    className: '',
    showStudentTable: false,
    showParentTable: false,
    isNew: false
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu, 
      user,
      history
    } = this.props;
    
    CloseMenu();
    
    if(params.id) {
      const result = await GetForm(params.id);

      if(result?.data?.user_type === 4) {
        this.dataTableStudent();
      } else if(result?.data?.user_type === 3) {
        this.dataTableParent();
      }
      
  
      dispatch(
        initialize('manage_forms_form', {
          ...result.data,
        })
      );
  
      this.setState({ 
        loading: false,
        showStudentTable: result?.data?.user_type === 4 ? true : false,
        showParentTable: result?.data?.user_type === 3 ? true : false,
        info: result?.data 
      });
    }
    else {
      this.setState({
        isNew: true,
        loading: false
      });
    }
  };

  dataTableStudent = async () => {
    const { match: { params } } = this.props;

    let { currentPage, pageSize, filters } = this.state;

    const classes = await GetClasses(currentPage, pageSize, filters);

    this.setState({
      columns: [
        {
          title: 'Nome',
          dataIndex: 'name',
          width: '33%'
        },
        {
          title: 'Escola',
          dataIndex: 'school',
          render: (value) => value?.name,
          width: '33%'
        },
        {
          title: 'Data de Disponibilidade',
          render: (data) =>  (
            <React.Fragment>
              <BaseButton 
                type={'default'}
                icon={'calendar'}
                text={'Definir Data'}
                onClick={() => this.setState({openModal: true, classId: data._id, className: data.name})}
              />
            </React.Fragment>
          ),
        },
      ],
      columnsUser: [
        {
          title: 'Nome',
          dataIndex: 'name'
        },
        {
          title: 'Data de Disponibilidade',
          render: (data) =>  {
            let availability_date;
            if(data?.forms_availability?.length > 0) {
              availability_date = data.forms_availability.find(x => x.form === params.id)?.availability_date;
            }
            return (
              <AddDateStudent 
                availability_date={availability_date}
                student={data}
                formId={params.id}
              />
          )},
          width: '32%'
        },
      ]
    })

    this.setState({
      rows: classes?.data?.items || [],
      total: classes?.data?.total || 0, 
    })
  }

  dataTableParent = async() => {
    const { match: { params },  user } = this.props;

    let parents = [];

    if(user.type === 2) {
      parents = await GetApplicatorParentsList();
    } else {
      parents = await GetParentsList();
    }

    this.setState({
      columns: [
        {
          title: 'Nome',
          dataIndex: 'name'
        },
        {
          title: 'Data de Disponibilidade',
          render: (data) =>  {
            let availability_date;
            if(data?.forms_availability?.length > 0) {
              availability_date = data.forms_availability.find(x => x.form === params.id)?.availability_date;
            }
            return (
              <AddDateStudent 
                availability_date={availability_date}
                student={data}
                formId={params.id}
              />
          )},
          width: '32%'
        },
      ]
    })
    this.setState({
      rows: parents?.data || []
    })
  }

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;
      const { isNew } = this.state;

      const payload = FlattenToFormData(values);
      const { success } = isNew ? await CreateForm(payload) : await UpdateForm(params.id, payload);

      if (success) return history.push('/forms');
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/forms');
  };

  getClassStudents = async(exp, data) => {
    var keys = [];

    if(exp){
        keys.push(data._id); // I have set my record.id as row key.
    }

    const result = await GetClass(data._id); 

    this.setState({
      rowsUser: result?.data, 
      expandedRowKeys: keys
    });
  }

  closeModal = () => {
    this.setState({ openModal: false })
    window.location.reload(false);
  }


  renderExpandedRowRender = () => {
    const { rowsUser, columnsUser, loading } = this.state; 

    return(
      <Table
        columns={columnsUser}
        loading={loading}
        rows={rowsUser?.students || []}
        showHeader={true}
        emptyIcon='warning'
        emptyText={'Não existem Utilizadores inseridos!'}
        rowKey={'_id'}
        secondary={true}
      />
    )
  }

  checkUserType = (value) => {
    if(parseInt(value) === 1) {
      this.setState({ 
        showStudentTable: true,
        showParentTable: false
      });
    } 
    else if(parseInt(value) === 2) {
      this.setState({ 
        showStudentTable: false,
        showParentTable: true
      });
    }
    else {
      this.setState({ 
        showStudentTable: false,
        showParentTable: false
      });
    }
  }

  render() {
    const { handleSubmit, pristine, activeLanguage, languages, match: { params }, user } = this.props;
    const { 
      loading, 
      info, 
      columns,
      rows,
      total,
      currentPage,
      pageSize,
      expandedRowKeys,
      openModal,
      classId,
      className,
      showStudentTable,
      showParentTable,
      isNew
    } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Questionários', title]}
          buttons={
            user.type === 2 ? [
              {
                type: 'default',
                icon: 'close',
                text: 'Cancelar',
                onClick: this.handleOnCancel,
              }
            ] : [
              {
                type: 'primary',
                icon: 'save',
                text: 'Gravar',
                onClick: handleSubmit((values) => this.onSubmit(values)),
                disabled: pristine,
              },
              {
                type: 'default',
                icon: 'close',
                text: 'Cancelar',
                onClick: this.handleOnCancel,
              }
            ]
          }
        />
        {user.type === 2 ? (
          <FormContainer>
            <Row gutter={24}>
              <Col xs={12}>
                <TextTitle>Tipo: <span>{getFormUserType(info?.user_type)}</span></TextTitle>
              </Col>
              <Col xs={12}>
                <TextTitle>Título: <span>{info?.title.pt}</span></TextTitle>
              </Col>
            </Row>
            {showStudentTable && (
              <Row gutter={24} style={{marginTop: 16}}>
                <Col xs={24} offset={0}>
                  <Title style={{marginBottom: 8}}>Data de Disponibilidade</Title>
                  <Table
                      columns={columns}
                      currentPage={currentPage}
                      pageSize={pageSize}
                      loading={loading}
                      rows={rows}
                      showHeader={true}
                      emptyIcon='warning'
                      emptyText={'Não existem Turmas inseridas!'}
                      total={total}
                      rowKey={'_id'}
                      hasPagination={false}
                      expandedRowRender={((data, index) => this.renderExpandedRowRender(data, index))}
                      expandedRowKeys={expandedRowKeys}
                      onExpand={(exp, data) => this.getClassStudents(exp, data)}
                    />
                </Col>
            </Row>
            )}
            {showParentTable && (
              <Row gutter={24} style={{marginTop: 16}}>
                <Col xs={24} offset={0}>
                  <Title style={{marginBottom: 8}}>Data de Disponibilidade</Title>
                  <Table
                    columns={columns}
                    loading={loading}
                    rows={rows}
                    showHeader={true}
                    hasPagination={false}
                    emptyIcon='warning'
                    emptyText={'Não existem Encarregados de Educação inseridas!'}
                    rowKey={'_id'}
                  />
                </Col>
              </Row>
            )}
          </FormContainer>
        ):(
          <FormContainer>
            <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
              <Row gutter={24}>
                <Col xs={12} offset={0}>
                  <Title>Questionário: <span>{info?.name}</span></Title>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={12} offset={0}>
                  <Field
                    component={TextInput}
                    name='name'
                    type='text'
                    label='Nome *'
                    placeholder={'Inserir nome do questionário'}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={12} offset={0}>
                  <Field
                    component={SelectInput}
                    disabled={isNew ? false : true}
                    name={'user_type'}
                    label={'Tipo *'}
                    placeholder={'Escolha o tipo de utilizador'}
                    type='select'
                    data={FormUserTypeTypes}
                    dataKey={'_id'}
                    dataLabel={'name'}
                    execAfterChange={isNew ? null : this.checkUserType}
                  />
                </Col>
                <Col xs={12} offset={0}>
                  <Field
                    component={TextInput}
                    name='tag'
                    type='text'
                    label='Tag *'
                    placeholder={'Inserir a tag do questionário'}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={12} offset={0}>
                  <DefaultLanguageTab>
                    {GetTranslationDescription(activeLanguage?.code)}
                  </DefaultLanguageTab>
                  <Field
                    component={TextInput}
                    name={`title.${activeLanguage?.code}`}
                    type='text'
                    label={'Título *'}
                    placeholder={'Título'}
                  />
                  <Field
                    component={DraftInput}
                    name={`description.${activeLanguage?.code}`}
                    label={'Descrição *'}
                  />
                  <Field
                    component={TextInput}
                    name={`email_message.${activeLanguage?.code}`}
                    type='text'
                    label={'Mensagem de Email Personalizada'}
                    placeholder={'Mensagem de Email Personalizada'}
                  />
                </Col>
                <Col xs={12} offset={0}>
                  <Tabs>
                    {languages
                      .filter((x) => !x.active)
                      .map((language) => (
                        <TabPane
                          tab={GetTranslationDescription(language.code)}
                          key={language.code}
                        >
                          <Field
                            component={TextInput}
                            name={`title.${language.code}`}
                            type='text'
                            label={'Título *'}
                            placeholder={'Título'}
                          />
                          <Field
                            component={DraftInput}
                            name={`description.${language.code}`}
                            label={'Descrição *'}
                          />
                          <Field
                            component={TextInput}
                            name={`email_message.${language.code}`}
                            type='text'
                            label={'Mensagem de Email Personalizada'}
                            placeholder={'Mensagem de Email Personalizada'}
                          />
                        </TabPane>
                      ))}
                  </Tabs>
                </Col>
              </Row>
              {showStudentTable && (
                <Row gutter={24} style={{marginTop: 16}}>
                  <Col xs={24} offset={0}>
                    <Title style={{marginBottom: 8}}>Data de Disponibilidade</Title>
                    <Table
                      columns={columns}
                      currentPage={currentPage}
                      pageSize={pageSize}
                      loading={loading}
                      rows={rows}
                      showHeader={true}
                      emptyIcon='warning'
                      emptyText={'Não existem Turmas inseridas!'}
                      total={total}
                      rowKey={'_id'}
                      hasPagination={true}
                      expandedRowRender={((data, index) => this.renderExpandedRowRender(data, index))}
                      expandedRowKeys={expandedRowKeys}
                      onExpand={(exp, data) => this.getClassStudents(exp, data)}
                    />
                  </Col>
                </Row>
              )}
              {showParentTable && (
                <Row gutter={24} style={{marginTop: 16}}>
                  <Col xs={24} offset={0}>
                    <Title style={{marginBottom: 8}}>Data de Disponibilidade</Title>
                    <Table
                      columns={columns}
                      loading={loading}
                      rows={rows}
                      showHeader={true}
                      emptyIcon='warning'
                      emptyText={'Não existem Encarregados de Educação inseridas!'}
                      rowKey={'_id'}
                    />
                  </Col>
                </Row>
              )}
            </BaseForm>
          </FormContainer>
        )}
        <ManageAvailabilityDate 
          isOpen={openModal}
          classId={classId}
          className={className}
          formId={params.id}
          onClose={() => this.closeModal()}
        />
      </React.Fragment>
    );
  }
}

ManageFormsPage = reduxForm({
  form: 'manage_forms_form',
  validate: validations
})(ManageFormsPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageFormsPage));