import LoginPage from "../../pages/auth/LoginPage";
import RecoverAccountPage from "../../pages/auth/RecoverAccountPage";
import RecoverPasswordPage from "../../pages/auth/RecoverPasswordPage";

export default [
  {
    path: "/login",
    component: LoginPage,
    isAuthenticated: false
  },
  {
    path: "/recover-account",
    component: RecoverAccountPage,
    isAuthenticated: false
  },
  {
    path: "/recover-password",
    component: RecoverPasswordPage,
    isAuthenticated: false
  }
];
