import React from "react";
import { 
  PageContainer,
} from "../../../styles/BasicStyles";
import { Title, Block, TitleData, RowDashboard, ColDashboard } from "../../dashboard/DashboardStyles";

const Dashboard = ({info}) => {
  if(!info) return null;
  return (
    <PageContainer>
      <RowDashboard gutter={[16, 16]}>
        <ColDashboard xs={12} md={8} lg={8}>
          <Block>
            <Title>Número de Turmas</Title>
            <TitleData>{info.classes || 0}</TitleData>
          </Block>
        </ColDashboard>
        <ColDashboard xs={12} md={6} lg={8}>
          <Block>
            <Title>Número de aplicadores</Title>
            <TitleData>{info.applicators || 0}</TitleData>
          </Block>
        </ColDashboard>
        <ColDashboard xs={12} md={8} lg={8}>
          <Block>
            <Title>Número de alunos</Title>
            <TitleData>{info.students || 0}</TitleData>
          </Block>
        </ColDashboard>
      </RowDashboard>
    </PageContainer>
  );
}

export default Dashboard;