import styled from 'styled-components';

export const InfoContainer = styled.div`
  font-size: 16px;
  margin-bottom: 5px;

  & span {
    font-weight: bold;
  }
`;

export const StateLabel = styled.div`
  padding: 3px 5px;
  border-radius: 5px;
  background-color: ${(p) => (p.bgColor ? p.bgColor : 'transparent')};
  color: #ffffff;
  display: inline-block;
  width: auto;
`;