import { Col, Row } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import BaseButton from '../../components/generic/buttons/BaseButton';
import { GetClass, GetSchool } from '../../infra/requests/SchoolsRequests';
import { CompleteRegister, GetUser } from '../../infra/requests/UsersRegisterRequests';
import { getCategoryType } from '../../infra/utils/CategoriesTypes';
import { getGenderType } from '../../infra/utils/GenderTypes';
import { getParentType } from '../../infra/utils/ParentTypes';
import { getProgramType } from '../../infra/utils/ProgramsTypes';
import { getUserRegisterTypes } from '../../infra/utils/UserRegisterTypes';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import { SpinLoading, FormContainer, Separator, TabsContainer, Tab } from '../../styles/BasicStyles';
import { TextTitle } from '../users/UserStyles';
import StudentsList from './components/StudentsList';

class UserRegisterDetail extends Component {
  state = {
    loading: false,
    userInfo: undefined,
    school: undefined,
    school_class: undefined,
    students: []
  };

  componentDidMount = async () => {
    const {
      match: { params },
      CloseMenu,
      user,
      history
    } = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 

    //Current user must edit his profile in the "My Profile" page
    if(user._id === params.id) {
      return history.push('/users-register');
    }

    CloseMenu();

    if(params.id) {
      await this.updateDataTable();
    } 
    else {
      this.setState({
        loading: false
      });
    }
  };

  updateDataTable = async () => {
    const {
      match: { params }
    } = this.props;

    const result = await GetUser(params.id);

    if(result?.data?.type === 4|| result?.data?.type === 2) {
      const school = await GetSchool(result?.data?.school);

      if(result?.data?.type === 4) {
        const school_class = await GetClass(result?.data?.class);

        this.setState({school_class: school_class?.data})
      }
      

      this.setState({
        school: school?.data
      })
    }
    
    

    this.setState({ 
      loading: false,
      userInfo: result?.data,
    });
  }

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/users-register');
  };

  handleMoreInfo = () => {
    const { history } = this.props;
    const { userInfo } = this.state;
    history.push(`/users/${userInfo.new_id}`);
  };

  handleComplete = async (record) => {
    const { students } = this.state; 
    this.setState({ loading: true });
    await CompleteRegister(record._id, { completed: !record.completed, email: record.email, type: record.parent, type_other: record?.type_other, array: students });
    await this.updateDataTable();
  };

  renderDetails = (userInfo) => { 
    const {school, school_class} = this.state;

    return(
      <>
        <Row gutter={24} style={{ marginTop: '10px' }}>
          <Col xs={12}>
            <TextTitle>Tipo: <span>{getUserRegisterTypes(userInfo?.type)}</span></TextTitle>
          </Col>
        </Row>
        <Row gutter={24} style={{ marginTop: '10px' }}>
          <Col xs={12}>
            <TextTitle>Nome: <span>{userInfo?.name}</span></TextTitle>
          </Col>
          <Col xs={12}>
            <TextTitle>Email: <span>{userInfo?.email}</span></TextTitle>
          </Col>
          <Col xs={12}>
            <TextTitle>Data de Nascimento: <span>{moment(userInfo?.birthday).format('DD-MM-YYYY')}</span></TextTitle>
          </Col>
          <Col xs={12}>
            <TextTitle>Género: <span>{getGenderType(userInfo?.gender)}</span></TextTitle>
          </Col>
          {userInfo?.school && (
            <Col xs={12}>
              <TextTitle>Escola: <span>{school?.name}</span></TextTitle>
            </Col>
          )}
          {userInfo?.class && (
            <Col xs={12}>
              <TextTitle>Turma: <span>{school_class?.name}</span></TextTitle>
            </Col>
          )}
          {userInfo?.applicator_category !== 0 && (
            <Col xs={12}>
              <TextTitle>Categoria: <span>{getCategoryType(userInfo?.applicator_category)}</span></TextTitle>
            </Col>
          )}
          {userInfo?.applicator_category_other && (
            <Col xs={12}>
              <TextTitle>Qual? <span>{userInfo?.applicator_category_other}</span></TextTitle>
            </Col>
          )}
          {userInfo?.applicator_program !== 0 && (
            <Col xs={12}>
              <TextTitle>Programa Aplicado: <span>{getProgramType(userInfo?.applicator_program)}</span></TextTitle>
            </Col>
          )}
          {userInfo?.code && (
            <Col xs={12}>
              <TextTitle>Código de Aluno: <span>{userInfo?.code}</span></TextTitle>
            </Col>
          )}
          {userInfo?.parent !== 0 && (
            <Col xs={12}>
              <TextTitle>Grau de Parentesco: <span>{getParentType(userInfo?.parent)}</span></TextTitle>
            </Col>
          )}
          {userInfo?.parent_other && (
            <Col xs={12}>
              <TextTitle>Qual? <span>{userInfo?.parent_other}</span></TextTitle>
            </Col>
          )}
          {userInfo?.phone && (
            <Col xs={12}>
              <TextTitle>Telemóvel: <span>{userInfo?.phone}</span></TextTitle>
            </Col>
          )}
        </Row>
      </>
    )     
  }

  renderStudents = (userInfo) => {
    return (
      <StudentsList code={userInfo?.code} changeStudents={(array) => this.setState({students: array})} />
    )
  }

  render() {
    const { loading, userInfo, defaultTab } = this.state;

    if (loading) return <SpinLoading />;
    const title = 'Informações';

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Pedido de Registo', title]}
          buttons={userInfo?.completed ? [
            {
              type: 'primary',
              icon: 'caret-right',
              text: 'Ver Utilizador',
              onClick: this.handleMoreInfo,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ] : [
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
            {!userInfo?.completed && (
              <Row>
                <BaseButton 
                  type={'primary'}
                  icon={'check-circle'}
                  text={'Adicionar utilizador'}
                  onClick={() => this.handleComplete(userInfo)}
                  style={{float: 'right'}}
                />
              </Row>
            )}
            <Row gutter={24} style={{ marginTop: '24px' }}>
              <Col xs={6}>
                <TextTitle>Data do Pedido: <span>{moment(userInfo?.request_date).format('DD-MM-YYYY HH:mm')}</span></TextTitle>
              </Col>
              {(userInfo?.completed && userInfo?.completed_date) &&
                <Col xs={6}>
                  <TextTitle>Data de Aceitação do Registo: <span>{moment(userInfo?.completed_date).format('DD-MM-YYYY HH:mm')}</span></TextTitle>
                </Col>
              }
            </Row>
            <Separator />
            {userInfo?.type === 3 && !userInfo?.completed ? (
              <TabsContainer defaultActiveKey={defaultTab}>
              <Tab 
                tab='Detalhes'
                key='details'
              >
                 { this.renderDetails(userInfo) }
              </Tab>
              <Tab
                tab='Educandos'
                key='students'
              >
                { this.renderStudents(userInfo) }
              </Tab>
            </TabsContainer>
            ) : (
              this.renderDetails(userInfo)
            )}
        </FormContainer>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(UserRegisterDetail));