import React, { useEffect, useState } from "react";
import { 
  PageContainer, 
  SpinLoading
} from "../../../styles/BasicStyles";
import { Col } from "antd";
import { Title, Block, TitleData, RowDashboard, Label, Line, Title2, ColDashboard } from "../../../pages/dashboard/DashboardStyles";
import { VictoryPie, VictoryLabel, VictoryChart, VictoryAxis, VictoryLine, VictoryContainer, VictoryBar, VictoryGroup } from "victory";
import { SecondaryColour, MenuBorderColor, SecondaryBackgroundColor, PrimaryColour, ThirdColour, HoverBorderColor } from "../../../styles/Colours";
import { GetDashboardByForm } from "../../../infra/requests/MindRequests";

const DashboardComponent = ({id}) => {
  const [info, setInfo] = useState(undefined)
  const isRobustez = id === '607426bd39410918768cca9f'

  useEffect(() => {
    async function getInfo() {
      const result = await GetDashboardByForm(id);
      if (result.success) {
        setInfo(result.data)
      }
    }

    getInfo()
  }, [])

  if(!info) return null;
  const users = []
  let totalUsers = 0
  const colors = []
  info.byGender.forEach(u => {
    users.push({x: u.total, y: u.total, 
      title: u._id === 1 ? 'Feminino' : u._id === 2 ? 'Masculino' : 'Outro', 
      color: u._id === 1 ? '#FFBF00' : u._id === 2 ? "#F38231" : "#46329B"
    })
    colors.push(u._id === 1 ? '#FFBF00' : u._id === 2 ? "#F38231" : "#46329B")
    totalUsers += u.total
  })
  
  const usersAge = []
  let totalUsersAge = 0
  const colorsAge = []
  info.byAge.forEach(u => {
    usersAge.push({x: u.total, y: u.total, 
      title: u._id === 0 ? '<20' : u._id === 20 ? '20-39' : u._id === 40 ? '40-64' : '>=65', 
      color: u._id === 0 ? "#FFBF00" : u._id === 20 ? "#F38231" : u._id === 40 ? "#46329B" : "#B1A1F5"
    })
    colorsAge.push(u._id === 0 ? "#FFBF00" : u._id === 20 ? "#F38231" : u._id === 40 ? "#46329B" : "#B1A1F5")
    totalUsersAge += u.total
  })

  const newDataA = []
  const newDataB = []
  const newDataC = []
  const pointsGroup = isRobustez ? [{title: '<2.84'}, {title: '>= 2.84'}] : [{title: '<53'}, {title: '53-60'}, {title: '>61'}] 
  pointsGroup.map((group, i) => {
    info.byGender.forEach((gender, j) => {
      const total = i === 0 ? gender.points.filter(x => parseInt(x) < 53).length
      : i === 1 ? gender.points.filter(x => parseInt(x) >= 53 && parseInt(x) <= 60).length
      : gender.points.filter(x => parseInt(x) >= 61).length
      
      
      if (gender._id === 1) {
        newDataA.push({x: i+1, y: total})
      } else if (gender._id === 2) {
        newDataB.push({x: i+1, y: total})
      } else {
        newDataC.push({x: i+1, y: total})
      }
    })
  })

  const data = [newDataA, newDataB, newDataC]

  const newData0 = []
  const newData20 = []
  const newData40 = []
  const newData65 = []
  pointsGroup.forEach((group, i) => {
    info.byAge.forEach((age, j) => {
      const total = i === 0 ? age.points.filter(x => parseInt(x) < 53).length
      : i === 1 ? age.points.filter(x => parseInt(x) >= 53 && parseInt(x) <= 60).length
      : age.points.filter(x => parseInt(x) >= 61).length
      
      if (age._id === 0) {
        newData0.push({x: i+1, y: total})
      } else if (age._id === 20) {
        newData20.push({x: i+1, y: total})
      } else if (age._id === 40) {
        newData40.push({x: i+1, y: total})
      } else {
        newData65.push({x: i+1, y: total})
      }
    })
  })

  const dataAge = [newData0, newData20, newData40, newData65]
console.log(dataAge)
  return (
    <PageContainer>
      <RowDashboard gutter={[16, 16]}>
        <Col xs={24} md={24} lg={12} style={{height: 400}}>
          <Block>
            <svg viewBox="50 50 100 100" style={{width: 250, height: 250, marginBottom: 16}}>
              <VictoryPie
                standalone={false}
                width={200} height={200}
                colorScale={colors}
                data={users}
                innerRadius={30} labelRadius={35}
                style={{ labels: { fontSize: 6, fill: "white" } }}
              />
              <VictoryLabel
                textAnchor="middle"
                style={{ fontSize: 8, fill: SecondaryColour }}
                x={100} y={100}
                text={`${totalUsers} utilizadores`}
              />
            </svg>
            <Label>
              {users.map(u => { return(<Title2><Line color={u.color}/>{u.title}</Title2>)})}
            </Label>
          </Block>
        </Col>
        <Col xs={24} md={24} lg={12} style={{height: 400}}>
          <Block>
            <svg viewBox="50 50 100 100" style={{width: 250, height: 250, marginBottom: 16}}>
              <VictoryPie
                standalone={false}
                width={200} height={200}
                colorScale={colorsAge}
                data={usersAge}
                innerRadius={30} labelRadius={35}
                style={{ labels: { fontSize: 6, fill: "white" } }}
              />
              <VictoryLabel
                textAnchor="middle"
                style={{ fontSize: 8, fill: SecondaryColour }}
                x={100} y={100}
                text={`${totalUsersAge} utilizadores`}
              />
            </svg>
            <Label>
              {usersAge.map(u => { return(<Title2><Line color={u.color}/>{u.title}</Title2>)})}
            </Label>
          </Block>
        </Col>
      </RowDashboard>
      <RowDashboard gutter={[16, 16]}>
        <Col xs={24} md={24} lg={12} style={{height: 400}}>
          <Block>
            <VictoryChart
              style={{width: '100%', height: 375}}
              domain={{x: [0, 1, 2, 3, 4]}}
            >
              <VictoryAxis
                style={{
                  axis: {stroke: MenuBorderColor},
                  tickLabels: {
                    fill: HoverBorderColor,
                    fontSize: 14,
                    fontFamily: 'Poppins'
                  },
                  grid: {stroke: MenuBorderColor}
                }}
                tickFormat={(value) =>
                  pointsGroup[value - 1]?.title
                }
                tickValues={isRobustez ? [0, 1, 2] : [0, 1, 2, 3]}
              />
              <VictoryAxis
                dependentAxis
                style={{
                  axis: {stroke: MenuBorderColor},
                  tickLabels: {
                    fill: HoverBorderColor,
                    fontSize: 14,
                    fontFamily: 'Poppins'
                  },
                  grid: {stroke: MenuBorderColor}
                }}
              />
              <VictoryAxis
                dependentAxis
                offsetX={400}
                style={{
                  axis: {stroke: MenuBorderColor},
                  tickLabels: {
                    fill: 'transparent',
                    fontSize: 14,
                    fontFamily: 'Poppins'
                  },
                  grid: {stroke: MenuBorderColor}
                }}
                // tickValues={undefined}
              />
              <VictoryGroup
                offset={25}
                colorScale={colors}
                domainPadding={{ x: 20 }}
              >
                <VictoryBar barWidth={20} data={data[0]} />
                <VictoryBar barWidth={20} data={data[1]} />
                <VictoryBar barWidth={20} data={data[2]} />
              </VictoryGroup>
            </VictoryChart> 
            <Label>
              {users.map(u => { return(<Title2><Line color={u.color}/>{u.title}</Title2>)})}
            </Label>
          </Block>
        </Col>
        <Col xs={24} md={24} lg={12} style={{height: 400}}>
          <Block>
            <VictoryChart
              style={{width: '100%', height: 375}}
              domain={{x: [0, 1, 2, 3]}}
            >
              <VictoryAxis
                style={{
                  axis: {stroke: MenuBorderColor},
                  tickLabels: {
                    fill: HoverBorderColor,
                    fontSize: 14,
                    fontFamily: 'Poppins'
                  },
                  grid: {stroke: MenuBorderColor}
                }}
                tickFormat={(value) =>
                  pointsGroup[value - 1]?.title
                }
                tickValues={isRobustez ? [0, 1, 2] : [0, 1, 2, 3]}
              />
              <VictoryAxis
                dependentAxis
                style={{
                  axis: {stroke: MenuBorderColor},
                  tickLabels: {
                    fill: HoverBorderColor,
                    fontSize: 14,
                    fontFamily: 'Poppins'
                  },
                  grid: {stroke: MenuBorderColor}
                }}
              />
              <VictoryAxis
                dependentAxis
                offsetX={400}
                style={{
                  axis: {stroke: MenuBorderColor},
                  tickLabels: {
                    fill: 'transparent',
                    fontSize: 14,
                    fontFamily: 'Poppins'
                  },
                  grid: {stroke: MenuBorderColor}
                }}
              />
              <VictoryGroup
                offset={15}
                colorScale={colorsAge}
              >
                <VictoryBar barWidth={15} data={dataAge[0]} />
                <VictoryBar barWidth={15} data={dataAge[1]} />
                <VictoryBar barWidth={15} data={dataAge[2]} />
                <VictoryBar barWidth={15} data={dataAge[3]} />
              </VictoryGroup>
            </VictoryChart> 
            <Label>
              {usersAge.map(u => { return(<Title2><Line color={u.color}/>{u.title}</Title2>)})}
            </Label>
          </Block>
        </Col>
      </RowDashboard>
    </PageContainer>
  );
}

export default DashboardComponent;