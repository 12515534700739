import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import {VictoryChart, VictoryBar, VictoryGroup, VictoryAxis} from 'victory'
import { ChartBox, QuestionTitle, ChartBoxInfo, Title, LegendMeditationContainer, BoxColor, LegendLine, TextTitle } from '../UserStyles';
import { SecondaryColour, PrimaryColour, MenuBorderColor, HoverBorderColor } from '../../../styles/Colours';
import { withLocalize } from 'react-localize-redux';

const daysOfWeek = [
  'SEGUNDA',
  'TERÇA',
  'QUARTA',
  'QUINTA',
  'SEXTA',
  'SABADO',
  'DOMINGO'
]

const xOffsets = [50, 400]
const tickValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
const tickPadding = [0, -35]
const anchors = ['end', 'end', 'start']

const ChartMeditation = ({
  info,
  week,
  translate,
  isEducator
}) => {
  const colors = isEducator
    ? [SecondaryColour]
    : [PrimaryColour, SecondaryColour]
  const [data, setData] = useState(undefined)

  useEffect(() => {
    if (info) {
      const newDataA = []
      const newDataB = []
      info.forEach((value, i) => {
        newDataA.push({x: i + 1, y: value.beforeAverage})
        newDataB.push({x: i + 1, y: value.afterAverage})
      })
      setData([newDataA, newDataB])
    }
  }, [info])

  const renderChart = () => (
    <VictoryChart
      style={{width: '100%', height: 375}}
      domain={{x: [0, 8], y: [0, 10]}}
    >
      <VictoryAxis
        style={{
          axis: {stroke: MenuBorderColor},
          tickLabels: {
            fill: HoverBorderColor,
            fontSize: 14,
            fontFamily: 'Poppins'
          },
          grid: {stroke: MenuBorderColor}
        }}
        tickFormat={(value) =>
          value
            ? value === 0 || value === 8
              ? ''
              : daysOfWeek[value - 1].slice(0, 3)
            : undefined
        }
        tickValues={[0, 1, 2, 3, 4, 5, 6, 7]}
      />
      <VictoryAxis
        dependentAxis
        style={{
          axis: {stroke: MenuBorderColor},
          tickLabels: {
            fill: HoverBorderColor,
            fontSize: 14,
            fontFamily: 'Poppins'
          },
          grid: {stroke: MenuBorderColor}
        }}
      />
      <VictoryAxis
        dependentAxis
        offsetX={400}
        style={{
          axis: {stroke: MenuBorderColor},
          tickLabels: {
            fill: 'transparent',
            fontSize: 14,
            fontFamily: 'Poppins'
          },
          grid: {stroke: MenuBorderColor}
        }}
        tickValues={undefined}
      />
      <VictoryGroup
        offset={10}
        colorScale={[SecondaryColour, PrimaryColour]}
      >
        <VictoryBar data={data[0]} />
        <VictoryBar data={data[1]} />
      </VictoryGroup>
    </VictoryChart>
  )

  return (
    <ChartBox>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={16} lg={18}>
          <QuestionTitle margin={-44}>
            Gráfico da Meditação
          </QuestionTitle>
          {data && renderChart()}
        </Col>
        <Col sm={24} md={8} lg={6}>
          <LegendMeditationContainer>
            <Row gutter={[16]}>
              <LegendLine>
                <BoxColor />
                <TextTitle>
                 Como se sentiu antes dos exercícios de meditação
                </TextTitle>
              </LegendLine>
              <LegendLine>
                <BoxColor color={PrimaryColour} />
                <TextTitle>
                 Como se sentiu depois dos exercícios de meditação
                </TextTitle>
              </LegendLine>
            </Row>
          </LegendMeditationContainer>
        </Col>
      </Row>
    </ChartBox>
  )
}

export default withLocalize(ChartMeditation);