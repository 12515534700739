import React, { Component } from 'react';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle
} from '../../styles/BasicStyles';
import { GetPrograms } from '../../infra/requests/ProgramsRequests';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import Table from '../../components/generic/table/Table';
import { connect } from 'react-redux';

class ProgramsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Título',
        dataIndex: 'title',
        render: (value) => TranslateValue(value)
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    const {user, history} = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 
    
    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/programs/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize } = this.state;
      const result = await GetPrograms(currentPage, pageSize);

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
      });

      this.setState({ ready: true, loading: false });
    } 
    catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize
    } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle>
            <PageTitle>Lista de Programas</PageTitle>
            {`Programas`}
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Programas inseridos!'}
            total={total}
            rowKey={'_id'}
            hasPagination={false}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, null)(ProgramsPage);