import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { TableFilterSection } from '../../styles/BasicStyles';
import TextInput from '../../components/generic/inputs/TextInput';
import SelectInput from '../../components/generic/inputs/SelectInput';
import DateInput from '../../components/generic/inputs/DateInput';
import { UserTypes } from '../../infra/utils/UserTypes';
import Cookies from 'js-cookie';

class UsersFilters extends Component {
  state = {
    search: '',
    birthday: '',
    type: '',
    school: '',
    schoolClass: ''
  };

  componentDidMount = () => {
    const { user } = this.props;

    const cookieJson = Cookies.get('userFilters');
    if(cookieJson) {
      const cookie = JSON.parse(cookieJson);

      if(cookie?.user === user._id) {
        this.setState({
          ...cookie.filters
        });
      }
    }
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange, user } = this.props;
    const query = {};
    Object.keys(this.state).map((key) => {
      if (this.state.schoolClass && this.state.schoolClass !== '' ) {
        query.class = this.state.schoolClass;
      }
      else if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });

    //When selecting a new filter, reset the currentPage and pageSize values
    //Cookie timeout: 1 hour
    const timeout = new Date(new Date().getTime() + 60 * 60 * 1000);
    const cookie = JSON.stringify({
      user: user?._id,
      filters: query,
      currentPage: 1,
      pageSize: 30
    });
    Cookies.set('userFilters', cookie, { expires: timeout })

    queryChange(JSON.stringify(query));
  };

  render() {
    const { search, birthday, type, schoolClass, school } = this.state;
    const { applicator, Classes, Schools } = this.props;

    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} md={6}>
            <TextInput
              input={{
                value: search,
                onChange: (event) =>
                  this.onInputChange('search', event.target.value, 1000),
              }}
              meta={{ valid: true }}
              type='text'
              label='Pesquisar'
              placeholder='Procurar por nome ou email'
            />
          </Col>
          <Col xs={24} md={4}>
            <DateInput
              label='Data de Nascimento'
              placeholder='Escolher data de nascimento'
              input={{
                value: birthday,
                onChange: value => this.onInputChange('birthday', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          {!applicator && (
            <Col xs={24} md={4}>
              <SelectInput
                label='Tipo'
                allowClear
                placeholder='Escolher tipo'
                data={UserTypes}
                dataKey='_id'
                dataLabel='name'
                input={{
                  value: type,
                  onChange: value => this.onInputChange('type', value)
                }}
                meta={{valid: true}}
              />
            </Col>
          )}
          <Col xs={24} md={4}>
            <SelectInput
              label='Escola'
              allowClear
              placeholder='Escolher Escola'
              data={Schools}
              dataKey='_id'
              dataLabel='name'
              input={{
                value: school,
                onChange: value => this.onInputChange('school', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={4}>
            <SelectInput
              label='Turma'
              allowClear
              placeholder='Escolher Turma'
              data={Classes}
              dataKey='_id'
              dataLabel='name'
              input={{
                value: schoolClass,
                onChange: value => this.onInputChange('schoolClass', value)
              }}
              meta={{valid: true}}
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

export default UsersFilters;