import React, { Component } from 'react';
import { Field, reduxForm, initialize, reset } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import { Row, Col } from 'antd';
import {
  GetUser,
  UpdateUser,
  CreateUser,
  BlockUserProgram,
  EmitCertificate
} from '../../infra/requests/UsersRequests';
import {
  GetSchoolsList,
  GetClassesList
} from '../../infra/requests/SchoolsRequests';
import TextInput from '../../components/generic/inputs/TextInput';
import SelectInput from '../../components/generic/inputs/SelectInput';
import DateInput from '../../components/generic/inputs/DateInput';
import { 
  FormContainer, 
  BaseForm, 
  SpinLoading,
  TabsContainer, 
  Tab
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import { UserTypes } from '../../infra/utils/UserTypes';
import { GenderTypes } from '../../infra/utils/GenderTypes';
import { ProgramsTypes } from '../../infra/utils/ProgramsTypes';
import { CategoriesTypes } from '../../infra/utils/CategoriesTypes';
import ParentsList from './components/ParentsList';
import ParentsApplicatorsList from './components/ParentsApplicatorsList';
import StudentsApplicatorsList from './components/StudentsApplicatorsList';
import { GetUserProgram } from '../../infra/requests/ProgramsRequests';
import SessionsList from '../programs/components/SessionsList';
import FormsList from './components/FormsList';
import Dashboard from './components/Dashboard';
import { Languages } from '../../infra/services/translations/AvailableTranslations';

const validations = FormValidator.make({
  type: 'required',
  name: 'required',
  email: 'required|email',
  language: 'required'
});

class ManageUsersPage extends Component {
  state = {
    isNew: false,
    loading: true,
    showStudentFields: false,
    showParentFields: false,
    showApplicatorFields: false,
    userInfo: undefined,
    defaultTab: 'details',
    schools: [],
    classes: [],
    sessionsList: [],
    showCategoryOther: false,
    isLoading: false
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu,
      user,
      history
    } = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 

    //Current user must edit his profile in the "My Profile" page
    if(user._id === params.id) {
      return history.push('/users');
    }

    CloseMenu();

    const schools = await GetSchoolsList();

    if(params.id) {
      //const resultSessions = await GetUserProgram('5fe1d835dc0ba87334bb1710', params.id);

      const result = await GetUser(params.id);
      let resultSessions
      if (result?.data?.type === 4)
      resultSessions = await GetUserProgram('5fe1d835dc0ba87334bb1710', params.id);
      else
      resultSessions = await GetUserProgram('607408b1461229668639dee5', params.id); 

      dispatch(
        initialize('manage_users_form', {
          ...result.data,
        })
      );

      let showCategoryOther = false;
      if(result?.data?.applicator_category === 3) showCategoryOther = true;

      let classes = {};
      if(result?.data?.school) classes = await GetClassesList(result.data.school);

      let defaultTab = (result?.data?.type === 3 || result?.data?.type === 4) ? 'dashboard' : 'details';
      if(history?.location?.search?.includes('parents=true')) {
        defaultTab = 'parents';
        history.replace(`/users/${params.id}`);
      } 
      if(history?.location?.search?.includes('applicators=true')) {
        defaultTab = 'applicators';
        history.replace(`/users/${params.id}`);
      } 
      if(history?.location?.search?.includes('program=true')) {
        defaultTab = 'program';
        history.replace(`/users/${params.id}`);
      } 

      this.setState({ 
        loading: false,
        showStudentFields: result?.data?.type === 4 ? true : false,
        showParentFields: result?.data?.type === 3 ? true : false,
        showApplicatorFields: result?.data?.type === 2 ? true : false,
        userInfo: result?.data,
        schools: schools?.data || [],
        classes: classes?.data || [],
        sessionsList: resultSessions?.data?.sessions || [],
        showCategoryOther,
        defaultTab
      });
    } 
    else {
      this.setState({
        isNew: true,
        loading: false,
        schools: schools?.data || []
      });
    }
  };

  onSubmit = async (values, createNewAfter) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history,
        dispatch
      } = this.props;
      const { isNew, userInfo } = this.state;

      const request = isNew ? await CreateUser(values) : await UpdateUser(params.id, {...values, block_program: userInfo.block_program, already_block_program: userInfo.already_block_program  });

      this.setState({ loading: false });

      if(request.success) {
        if(createNewAfter) {
          dispatch(reset('manage_users_form'));
          return history.push('/users/add');
        } 
        else {
          return history.push('/users');
        }
      }
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/users');
  };

  checkUserType = (value) => {
    if(parseInt(value) === 4) {
      this.setState({ 
        showStudentFields: true,
        showParentFields: false,
        showApplicatorFields: false
      });
    }
    else if(parseInt(value) === 3) {
      this.setState({ 
        showStudentFields: false,
        showParentFields: true,
        showApplicatorFields: false
      });
    }
    else if(parseInt(value) === 2) {
      this.setState({ 
        showStudentFields: false,
        showParentFields: false,
        showApplicatorFields: true
      });
    }
    else {
      this.setState({ 
        showStudentFields: false,
        showParentFields: false,
        showApplicatorFields: false
      });
    }
  }

  loadSchoolClasses = async (value) => {
    let classes = {};
    if(value) classes = await GetClassesList(value);

    this.setState({ 
      classes: classes?.data || []
    });
  }

  checkCategory = (value) => {
    if(parseInt(value) === 3) this.setState({ showCategoryOther: true });
    else this.setState({ showCategoryOther: false });
  }

  renderDetails = () => {
    const { handleSubmit } = this.props;
    const { 
      showStudentFields, 
      showParentFields, 
      showApplicatorFields, 
      schools, 
      classes, 
      showCategoryOther
    } = this.state;

    return (
      <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
        <Row gutter={24}>
          <Col xs={12}>
            <Field
              component={SelectInput}
              name={'type'}
              label={'Tipo *'}
              placeholder={'Escolha o tipo de utilizador'}
              type='select'
              data={UserTypes}
              dataKey={'_id'}
              dataLabel={'name'}
              execAfterChange={this.checkUserType}
            />
          </Col>
          <Col xs={12}>
            <Field
              component={SelectInput}
              name={'language'}
              label={'Idioma *'}
              placeholder={'Escolha o idioma'}
              type='select'
              data={Languages}
              dataKey={'code'}
              dataLabel={'name'}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={12}>
            <Field
              component={TextInput}
              name='name'
              type='text'
              label='Nome *'
              placeholder='Primeiro e último nome'
            />
          </Col>
          <Col xs={12}>
            <Field
              component={TextInput}
              name='email'
              type='email'
              label='Email *'
              placeholder='Email'
            />
          </Col>
        </Row>
        {
          showStudentFields &&
          <React.Fragment>
            <Row gutter={24} style={{ marginTop: '15px' }}>
              <Col xs={12}>
                <Field
                  component={DateInput}
                  name='birthday'
                  label='Data de Nascimento'
                  placeholder='Data de nascimento'
                />
              </Col>
              <Col xs={12}>
                <Field
                  component={SelectInput}
                  name={'gender'}
                  label={'Sexo'}
                  placeholder={'Escolha o sexo'}
                  type='select'
                  data={GenderTypes}
                  dataKey={'_id'}
                  dataLabel={'name'}
                  allowClear={true}
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '15px' }}>
              <Col xs={12}>
                <Field
                  component={SelectInput}
                  name={'school'}
                  label={'Escola'}
                  placeholder={'Escolha a escola'}
                  type='select'
                  data={schools}
                  dataKey={'_id'}
                  dataLabel={'name'}
                  allowClear={true}
                  execAfterChange={this.loadSchoolClasses}
                />
              </Col>
              <Col xs={12}>
                <Field
                  component={SelectInput}
                  name={'class'}
                  label={'Turma'}
                  placeholder={'Escolha a turma'}
                  type='select'
                  data={classes}
                  dataKey={'_id'}
                  dataLabel={'name'}
                  allowClear={true}
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '15px' }}>
              <Col xs={12}>
                <Field
                  component={DateInput}
                  name='program_start'
                  label='Data de Início do Programa'
                  placeholder='Data de início do programa'
                />
              </Col>
              <Col xs={12}>
                <Field
                  component={TextInput}
                  name='phone'
                  type='number'
                  label='Número de telemóvel *'
                  placeholder='Número de telemóvel'
                />
              </Col>
            </Row>
          </React.Fragment>
        }
        {
          showParentFields &&
          <React.Fragment>
            <Row gutter={24} style={{ marginTop: '15px' }}>
              <Col xs={12}>
                <Field
                  component={DateInput}
                  name='birthday'
                  label='Data de Nascimento'
                  placeholder='Data de nascimento'
                />
              </Col>
              <Col xs={12}>
                <Field
                  component={SelectInput}
                  name={'gender'}
                  label={'Sexo'}
                  placeholder={'Escolha o Sexo'}
                  type='select'
                  data={GenderTypes}
                  dataKey={'_id'}
                  dataLabel={'name'}
                  allowClear={true}
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '15px' }}>
              <Col xs={12}>
                <Field
                  component={DateInput}
                  name='program_start'
                  label='Data de Início do Programa'
                  placeholder='Data de início do Programa'
                />
              </Col>
            </Row>
          </React.Fragment>
        }
        {
          showApplicatorFields &&
          <React.Fragment>
            <Row gutter={24} style={{ marginTop: '15px' }}>
              <Col xs={12}>
                <Field
                  component={DateInput}
                  name='birthday'
                  label='Data de Nascimento'
                  placeholder='Data de nascimento'
                />
              </Col>
              <Col xs={12}>
                <Field
                  component={SelectInput}
                  name={'gender'}
                  label={'Sexo'}
                  placeholder={'Escolha o Sexo'}
                  type='select'
                  data={GenderTypes}
                  dataKey={'_id'}
                  dataLabel={'name'}
                  allowClear={true}
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '15px' }}>
              <Col xs={12}>
                <Field
                  component={SelectInput}
                  name={'applicator_category'}
                  label={'Categoria'}
                  placeholder={'Escolha a categoria'}
                  type='select'
                  data={CategoriesTypes}
                  dataKey={'_id'}
                  dataLabel={'name'}
                  allowClear={true}
                  execAfterChange={this.checkCategory}
                />
              </Col>
              {
                showCategoryOther &&
                <Col xs={12}>
                  <Field
                    component={TextInput}
                    name='applicator_category_other'
                    type='text'
                    label='Qual?'
                    placeholder='Outra categoria'
                  />
                </Col>
              }
            </Row>
            <Row gutter={24} style={{ marginTop: '15px' }}>
              <Col xs={12}>
                <Field
                  component={SelectInput}
                  name={'applicator_program'}
                  label={'Programa aplicado'}
                  placeholder={'Escolha o programa'}
                  type='select'
                  data={ProgramsTypes}
                  dataKey={'_id'}
                  dataLabel={'name'}
                  allowClear={true}
                />
              </Col>
            </Row>
          </React.Fragment>
        }
      </BaseForm>
    );
  }

  handleBlockUserProgram = async() => {
    const { userInfo } = this.state;
    try {
      this.setState({ isLoading: true });
      const res = await BlockUserProgram(userInfo._id);
      userInfo.block_program = res?.data?.block_program
      userInfo.already_block_program = res?.data?.already_block_program
      this.setState({userInfo, isLoading: false})
    } catch (e) {
      this.setState({isLoading: false})
      console.error(e);
    }
  }

  handleCertificate = async() => {
    const { userInfo } = this.state;
    try {
      const res = await EmitCertificate(userInfo._id);
      userInfo.programCompleted = res?.data?.programCompleted
      this.setState({userInfo})
    } catch (e) {
      console.error(e);
    }
  }


  render() {
    const { handleSubmit, pristine } = this.props;
    const { isNew, loading, defaultTab, userInfo, sessionsList, isLoading } = this.state;
    const isEducator = userInfo?.type === 3


    if (loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : `Editar ${userInfo.name}`;

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Utilizadores', title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values, false)),
              disabled: pristine,
            },
            {
              type: 'primary',
              icon: 'file',
              text: 'Gravar & Criar novo',
              onClick: handleSubmit((values) => this.onSubmit(values, true)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          {
            isNew ?
            this.renderDetails()
            :
            <TabsContainer defaultActiveKey={defaultTab}>
              {
                (userInfo?.type === 3 || userInfo?.type === 4) &&
                <Tab
                  tab='Dashboard'
                  key='dashboard'
                >
                  <Dashboard 
                    userInfo={userInfo} 
                    isNew={isNew} 
                    handleBlockUserProgram={this.handleBlockUserProgram} 
                    handleCertificate={this.handleCertificate}
                    isLoading={isLoading}
                  />
                </Tab>
              }
              <Tab
                tab='Detalhes'
                key='details'
              >
                { this.renderDetails() }
              </Tab>
              {
                userInfo?.type === 4 &&
                <Tab
                  tab='Enc. de Educação'
                  key='parents'
                >
                  <ParentsList userID={userInfo?._id}/>
                </Tab>
              }
              {
                (userInfo?.type === 3 || userInfo?.type === 4) &&
                <Tab
                  tab='Aplicadores'
                  key='applicators'
                >
                  {
                    userInfo?.type === 3 ?
                    <ParentsApplicatorsList userID={userInfo?._id}/>
                    :
                    <StudentsApplicatorsList userID={userInfo?._id}/>
                  }
                </Tab>
              }
              {
                (userInfo?.type === 4 || isEducator) &&
                <Tab
                  tab='Programa'
                  key='program'
                >
                  <SessionsList admin userId={userInfo?._id} list={sessionsList} />
                </Tab>
              }
              {
                (userInfo?.type === 3 || userInfo?.type === 4) &&
                <Tab
                  tab='Questionários'
                  key='forms'
                >
                  <FormsList userID={userInfo?._id} />
                </Tab>
              }
            </TabsContainer>
          }
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageUsersPage = reduxForm({
  form: 'manage_users_form',
  validate: validations,
})(ManageUsersPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageUsersPage));