import styled from 'styled-components';
import { Col, Row } from 'antd';

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.titleSize};
  color: ${({ theme }) => theme.primaryColor};
  margin-bottom: 16px;
`;

export const TitleData = styled.span`
  font-size: 48px;
  color: ${({ theme }) => theme.secondaryColor};
  margin-bottom: 0px;
`;

export const Block = styled.div`
  background-color: #fff;
  border-radius: 8px;
  Border: 2px solid ${({ theme }) => theme.menuBorderColor};
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

export const RowDashboard = styled(Row)`
`;

export const ColDashboard = styled(Col)`
  height: 160px;
`;

export const Label = styled.div`
  display: inline-block;
`;

export const Line = styled.div`
  margin-bottom: 8px;
  margin-right: 4px;
  width: 10px;
  border-bottom: 3px solid ${({color}) => color}
`;

export const Title2 = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.primaryColor};
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  margin-right: 16px;
  float: left;
`;
