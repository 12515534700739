import DashboardPage from '../../pages/dashboard/DashboardPage';
import UsersPage from '../../pages/users/UsersPage';
import ManageUsersPage from '../../pages/users/ManageUsersPage';
import ManageProfilePage from '../../pages/users/ManageProfilePage';
import TranslationsPage from '../../pages/translations/TranslationsPage';
import ProgramsPage from '../../pages/programs/ProgramsPage';
import ManageProgramsPage from '../../pages/programs/ManageProgramsPage';
import ManageSessionsPage from '../../pages/programs/ManageSessionsPage';
import ManageChaptersPage from '../../pages/programs/ManageChaptersPage';
import ManageStepsPage from '../../pages/programs/ManageStepsPage';
import SchoolsPage from '../../pages/schools/SchoolsPage';
import ManageSchoolsPage from '../../pages/schools/ManageSchoolsPage';
import ClassesPage from '../../pages/schools_classes/ClassesPage';
import ManageClassesPage from '../../pages/schools_classes/ManageClassesPage';
import AudioPage from '../../pages/audio/AudioPage';
import ManageAudioContentPage from '../../pages/audio/ManageAudioContentPage';
import ThreadsPage from '../../pages/forum/ThreadsPage';
import ManageThreadsPage from '../../pages/forum/ManageThreadsPage';
import ManageCommentsPage from '../../pages/forum/components/ManageCommentsPage';
import ManageSubCommentsPage from '../../pages/forum/components/ManageSubCommentsPage';
import PointsPage from '../../pages/points/PointsPage';
import ManagePointsPage from '../../pages/points/ManagePointsPage';
import ApplicatorStudentsPage from '../../pages/users/ApplicatorStudentsPage';
import ManageApplicatorStudentsPage from '../../pages/users/ManageApplicatorStudentsPage';
import ManageParentsList from '../../pages/users/components/ManageParentsList';
import ManageParentsApplicatorsList from '../../pages/users/components/ManageParentsApplicatorsList';
import ManageClassApplicatorsList from '../../pages/schools_classes/components/ManageApplicatorsList';
import ManageClassStudentsList from '../../pages/schools_classes/components/ManageStudentsList';
import JewelsPage from '../../pages/jewels/JewelsPage';
import ManageJewelsPage from '../../pages/jewels/ManageJewelsPage';
import FormsPage from '../../pages/forms/FormsPage';
import ManageFormsPage from '../../pages/forms/ManageFormsPage';
import ApplicatorChaptersPage from '../../pages/users/ApplicatorChaptersPage';
import ApplicatorParentsPage from '../../pages/users/ApplicatorParentsPage';
import ManageApplicatorParentsPage from '../../pages/users/ManageApplicatorParentsPage';
import UsersRegisterPage from '../../pages/users_register/UsersRegisterPage';
import UserRegisterDetail from '../../pages/users_register/UserRegisterDetail';
import ManageExtraSessionPage from '../../pages/extra_session/ManageExtraSessionPage';
import ManageBlocksPage from '../../pages/extra_session/components/ManageBlocksPage';
import ExtraSessionsPage from '../../pages/extra_session/ExtraSessionsPage';
import EmailTemplatesPage from '../../pages/email_templates/EmailTemplatesPage';
import ManageEmailTemplatesPage from '../../pages/email_templates/ManageEmailTemplatesPage';
import VideosPage from '../../pages/videos/VideosPage';
import ManageVideosPage from '../../pages/videos/ManageVideosPage';

export default [
  {
    path: '/',
    component: DashboardPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/users/students',
    component: ApplicatorStudentsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/users/students/:id',
    component: ManageApplicatorStudentsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/users/parents',
    component: ApplicatorParentsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/users/parents/:id',
    component: ManageApplicatorParentsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/users/students/:id/chapters/:chapterId',
    component: ApplicatorChaptersPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/users',
    component: UsersPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/users/add',
    component: ManageUsersPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/users/:id',
    component: ManageUsersPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/users/:id/chapters/:chapterId',
    component: ApplicatorChaptersPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/users/:user/guardians/add',
    component: ManageParentsList,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/users/:user/guardians/:id',
    component: ManageParentsList,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/users/:user/applicators/add',
    component: ManageParentsApplicatorsList,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/users/:user/applicators/:id',
    component: ManageParentsApplicatorsList,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/users-register',
    component: UsersRegisterPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/users-register/:id',
    component: UserRegisterDetail,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/my-profile',
    component: ManageProfilePage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/translations',
    component: TranslationsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/programs',
    component: ProgramsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/programs/:id',
    component: ManageProgramsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/programs/sessions/:id',
    component: ManageSessionsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/programs/chapters/:id',
    component: ManageChaptersPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/programs/steps/:id',
    component: ManageStepsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/schools',
    component: SchoolsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/schools/add',
    component: ManageSchoolsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/schools/:id',
    component: ManageSchoolsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/classes',
    component: ClassesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/classes/add',
    component: ManageClassesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/classes/:id',
    component: ManageClassesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/classes/:class/applicators/add',
    component: ManageClassApplicatorsList,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/classes/:class/applicators/:id',
    component: ManageClassApplicatorsList,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/classes/:class/students/add',
    component: ManageClassStudentsList,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/audio',
    component: AudioPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/audio/:id',
    component: ManageAudioContentPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/forum/:forum',
    component: ThreadsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/forum/:forum/:id',
    component: ManageThreadsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/forum/:forum/:thread/:id',
    component: ManageCommentsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/forum/:forum/:thread/:comment/:id',
    component: ManageSubCommentsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/points',
    component: PointsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/points/:id',
    component: ManagePointsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/jewels',
    component: JewelsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/jewels/:id',
    component: ManageJewelsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/forms',
    component: FormsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/forms/add',
    component: ManageFormsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/forms/:id',
    component: ManageFormsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/extra-session',
    component: ExtraSessionsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/extra-session/:id',
    component: ManageExtraSessionPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/extra-session/blocks/:id',
    component: ManageBlocksPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/extra-session/:idSession/blocks/add',
    component: ManageBlocksPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/email-templates',
    component: EmailTemplatesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/email-templates/:id',
    component: ManageEmailTemplatesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/videos',
    component: VideosPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/videos/add',
    component: ManageVideosPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/videos/:id',
    component: ManageVideosPage,
    isAuthenticated: true,
    role: 1,
  }
];
