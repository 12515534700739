import client from '../config/AxiosConfig';

/* NEWS */
export const GetNews = async (currentPage, limit, filters) => await client.get(`/news/${currentPage}/${limit}?filter=${filters}`);

export const GetNewsItem = async (id) => await client.get(`/news/id/${id}`);

export const CreateNews = async (data) => await client.post(`/news`, data);

export const UpdateNews = async (id, data) => await client.put(`/news/${id}`, data);

export const DeleteNews = async (id) => await client.delete(`/news/${id}`);

export const ToggleNews = async (id, data) => await client.put(`/news/${id}/toggle`, data);

export const ToggleFeaturedNews = async (id, data) => await client.put(`/news/${id}/toggle-featured`, data);

/* NEWS - BLOCKS */
export const GetBlocks = async (id, currentPage, limit) => await client.get(`/news_blocks/${id}/${currentPage}/${limit}`);

export const GetBlock = async (id) => await client.get(`/news_blocks/${id}`);

export const CreateBlock = async (data) => await client.post(`/news_blocks`, data);

export const UpdateBlock = async (id, data) => await client.put(`/news_blocks/${id}`, data);

export const ToggleBlock = async (id, data) => await client.put(`/news_blocks/${id}/toggle`, data);

export const DeleteBlock = async (id) => await client.delete(`/news_blocks/${id}`);

export const UpdateBlocksOrder = async (data) => await client.post(`/news_blocks/order`, data);