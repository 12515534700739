import client from '../config/AxiosConfig';

/* THREADS */
export const GetThreads = async (currentPage, limit, filters) => await client.get(`/threads/${currentPage}/${limit}?filter=${filters}`);

export const GetThread = async (id) => await client.get(`/threads/id/${id}`);

export const UpdateThread = async (id, data) => await client.put(`/threads/${id}`, data);

export const DeleteThread = async (id) => await client.delete(`/threads/${id}`);

export const ValidateThread = async (id) => await client.put(`/threads/validate/${id}`);

/* COMMENTS */
export const GetThreadComments = async (id, currentPage, limit, filters) => await client.get(`/comments/thread/${id}/${currentPage}/${limit}?filter=${filters}`);

export const DeleteComment = async (id) => await client.delete(`/comments/${id}`);

export const GetComment = async (id) => await client.get(`/comments/${id}`);

export const UpdateComment = async (id, data) => await client.put(`/comments/${id}`, data);

export const ValidateComment = async (id) => await client.put(`/comments/validate/${id}`);

/* SUB COMMENTS */
export const GetSubComments = async (id, filters) => await client.get(`/comments/comment/${id}?filter=${filters}`);

export const GetSubComment = async (comment, id) => await client.get(`/comments/subcomment/${comment}/${id}`);

export const UpdateSubComment = async (comment, id, data) => await client.put(`/comments/fo/${comment}/${id}`, data);

export const DeleteSubComment = async (comment, id) => await client.delete(`/comments/subcomment/${comment}/${id}`);

export const ValidateSubComment = async (comment, id) => await client.put(`/comments/validate/subcomment/${comment}/${id}`);