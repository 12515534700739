import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetInstrument,
  UpdateInstrument,
  CreateInstrument,
  GetCategoriesList
} from '../../infra/requests/AboutInstrumentsRequests';
import TextAreaInput from '../../components/generic/inputs/TextAreaInput';
import SelectInput from '../../components/generic/inputs/SelectInput';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab,
  InputNote,
  SectionTitle,
  TabsContainer,
  Tab
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/generic/inputs/TextInput';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import BlocksPage from './components/BlocksPage';
import { InstrumentsTypes } from '../../infra/utils/InstrumentsTypes';
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  url: 'noSpaces|noSpecialCharacter',
  title: 'required|languages',
  image: 'required',
  category: 'required',
  type: 'required'
});

class ManageInstrumentsPage extends Component {
  state = { 
    isNew: false, 
    loading: true,
    instrument: undefined,
    defaultTab: 'details',
    categories: []
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu,
      history,
      user
    } = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 

    CloseMenu();

    const categories = await GetCategoriesList();

    if(params.id) {
      const result = await GetInstrument(params.id);
      dispatch(
        initialize('manage_instruments_form', {
          ...result.data,
        })
      );

      this.setState({ 
        loading: false,
        instrument: result?.data,
        defaultTab: history?.location?.search?.includes('blocks=true') ? 'blocks' : 'details',
        categories: categories?.data || []
      });
    } 
    else {
      this.setState({
        isNew: true,
        loading: false,
        categories: categories?.data || []
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;

      if(values.image && !values.image.blob) {
        delete values.image;
      }

      const payload = FlattenToFormData(values);
      const { success } = params.id ? await UpdateInstrument(params.id, payload) : await CreateInstrument(payload);

      if(success) return history.push('/website/about/instruments');
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/website/about/instruments');
  };

  renderDetails = () => {
    const { handleSubmit, activeLanguage, languages } = this.props;
    const { categories } = this.state;

    return (
      <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
        <Row>
          <Row gutter={24}>
            <SectionTitle>Informação Principal</SectionTitle>
          </Row>
          <Row gutter={24}>
            <Col xs={12}>
              <Field
                component={SelectInput}
                name={'type'}
                label={'Tipo *'}
                placeholder={'Escolha o tipo'}
                type='select'
                data={InstrumentsTypes}
                dataKey={'_id'}
                dataLabel={'name'}
              />
            </Col>
            <Col xs={12}>
              <Field
                component={SelectInput}
                name={'category'}
                label={'Categoria *'}
                placeholder={'Escolha a categoria'}
                type='select'
                data={categories}
                dataKey={'_id'}
                dataLabel={'name'}
                translatable={true}
              />
            </Col>
          </Row>
          <Row gutter={24} style={{ marginTop: '20px' }}>
            <Col xs={12} offset={0}>
              <DefaultLanguageTab>
                {GetTranslationDescription(activeLanguage?.code)}
              </DefaultLanguageTab>
              <Field
                component={TextInput}
                name={`title.${activeLanguage?.code}`}
                type='text'
                label={'Título *'}
                placeholder={'Título'}
              />
            </Col>
            <Col xs={12} offset={0}>
              <Tabs>
                {languages
                  .filter((x) => !x.active)
                  .map((language) => (
                    <TabPane
                      tab={GetTranslationDescription(language.code)}
                      key={language.code}
                    >
                      <Field
                        component={TextInput}
                        name={`title.${language.code}`}
                        type='text'
                        label={'Título *'}
                        placeholder={'Título'}
                      />
                    </TabPane>
                  ))}
              </Tabs>
            </Col>
          </Row>
          <Row gutter={24}>
            <SectionTitle>Metatags</SectionTitle>
          </Row>
          <Row gutter={24}>
            <Col xs={24}>
              <Field
                component={TextInput}
                name={`url`}
                type='text'
                label={'URL *'}
                placeholder={'URL do Instrumento'}
              />
              <InputNote>
                <span>NOTA:</span> Deverá introduzir o URL sem espaços, sem
                acentos, letras minúsculas e apenas com letras, números e
                hífens.
              </InputNote>
              <InputNote>
                <span>EXEMPLO:</span> Título do Instrumento: "Novo Instrumento 2020"
                / URL: "novo-instrumento-2020"
              </InputNote>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12}>
              <DefaultLanguageTab>
                {GetTranslationDescription(activeLanguage?.code)}
              </DefaultLanguageTab>
              <Field
                component={TextInput}
                name={`meta_title.${activeLanguage?.code}`}
                label={'Meta Title'}
                type={'text'}
              />
              <Field
                component={TextAreaInput}
                name={`meta_description.${activeLanguage?.code}`}
                label={'Meta Description'}
              />
              <Field
                component={TextAreaInput}
                name={`meta_keywords.${activeLanguage?.code}`}
                label={'Meta Keywords'}
              />
            </Col>
            <Col xs={12}>
              <Tabs>
                {languages
                  .filter((x) => !x.active)
                  .map((language) => (
                    <TabPane
                      tab={GetTranslationDescription(language.code)}
                      key={language.code}
                    >
                      <Field
                        component={TextInput}
                        name={`meta_title.${language.code}`}
                        label={'Meta Title'}
                        type={'text'}
                      />
                      <Field
                        component={TextAreaInput}
                        name={`meta_description.${language?.code}`}
                        label={'Meta Description'}
                      />
                      <Field
                        component={TextAreaInput}
                        name={`meta_keywords.${language?.code}`}
                        label={'Meta Keywords'}
                      />
                    </TabPane>
                  ))}
              </Tabs>
            </Col>
          </Row>
        </Row>
      </BaseForm>
    );
  }

  render() {
    const { handleSubmit, pristine } = this.props;
    const { instrument, isNew, loading, defaultTab } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Sobre', 'Instrumentos', title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          {
            isNew ?
            this.renderDetails()
            :
            <TabsContainer defaultActiveKey={defaultTab}>
              <Tab
                tab='Detalhes'
                key='details'
              >
                {this.renderDetails()}
              </Tab>
              <Tab
                tab='Blocos'
                key='blocks'
              >
                <BlocksPage
                  instrument={instrument}
                />
              </Tab>
            </TabsContainer>
          }
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageInstrumentsPage = reduxForm({
  form: 'manage_instruments_form',
  validate: validations,
})(ManageInstrumentsPage);

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageInstrumentsPage));