export const GoalTypes = [
  { _id: 0, name: 'Sem Resposta' },
  { _id: 1, name: 'Atingi o desafio na totalidade' },
  { _id: 2, name: 'Atingi o desafio em parte' },
  { _id: 3, name: 'Não atingi o desafio' }
];

export const getGoalType = (value) => {
  if(Number.isInteger(value) && value >= 0 && GoalTypes[value]) return GoalTypes[value].name;
  return null;
};