import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Layout, Icon } from 'antd';
import MenuItems from './MenuItems';
import WebsiteItems from './WebsiteItems';
import { DeleteUser } from '../../../redux/User/user.actions';
import { MenuToggle } from "../../../redux/Menu/menu.actions";
import { withRouter } from 'react-router-dom';
import {
  StyledMenuItem,
  StyledNavLink,
  StyledSider,
  StyledContent,
  TransitionLayout,
  MenuSVG,
  StyledSubMenu,
  StyledMenu
} from './LayoutStyles';
import HeaderComponent from './HeaderComponent';
import {
  FieldNumberOutlined
} from '@ant-design/icons';

class ResponsiveSider extends Component {
  state = {
    index: []
  };

  componentDidMount = () => {
    const { user, history } = this.props;

    this.mounted = true;
    
    if(!user) {
      history.push('/login');
    }
  };

  logOut = () => {
    const { DeleteUser, history } = this.props;
    localStorage.clear();
    DeleteUser();
    history.push('/login');
  };

  renderMenuList = (item, index) => {
    const { user, history } = this.props;
    if(!user || user.type > item.permission_type || user.type < item.permission_type) return null;
    if(item.codepoint_admin && !user.isCodepointAdmin) return null;

    switch (item.type) {
      case 'menu':
        return (
          <StyledMenuItem key={index} onClick={() => this.openSider()}>
            <StyledNavLink
              to={item.to}
              exact={item.exact}
              activeClassName='activated'
            >
              {
                item.external_icon ?
                <MenuSVG className='anticon' size={item.size}>
                  <ReactSVG src={require(`../../../../public/${item.icon}`)} />
                </MenuSVG>
                :
                item.field_number ?
                <FieldNumberOutlined style={{ fontSize: item.size ? item.size : '20px' }} />
                : 
                <Icon
                  style={{ fontSize: item.size ? item.size : '20px' }}
                  type={item.icon}
                />
              }
              <span>{item.name}</span>
            </StyledNavLink>
          </StyledMenuItem>
        );
      case 'submenu':
        return (
          <StyledSubMenu
            key={`sub_${index}`}
            className={history?.location?.pathname?.includes(item.baseUrl) ? 'ant-menu-submenu-active' : ''}
            title={
              <>
                {item.external_icon ? (
                  <MenuSVG className='anticon' size={item.size}>
                    <ReactSVG src={require(`../../../../public/${item.icon}`)} />
                  </MenuSVG>
                ) : (
                  <Icon
                    style={{ fontSize: item.size ? item.size : '20px' }}
                    type={item.icon}
                  />
                )}
                <span>{item.name}</span>
              </>
            }
          >
            {item.to.map(this.renderMenuList)}
          </StyledSubMenu>
        );
      case 'subsubmenu':
        return (
          <StyledSubMenu
            key={`subsub_${index}`}
            className={history?.location?.pathname?.includes(item.baseUrl) ? 'ant-menu-submenu-active' : ''}
            title={
              <>
                {item.external_icon ? (
                  <MenuSVG className='anticon' size={item.size}>
                    <ReactSVG src={require(`../../../../public/${item.icon}`)} />
                  </MenuSVG>
                ) : (
                  <Icon
                    style={{ fontSize: item.size ? item.size : '20px' }}
                    type={item.icon}
                  />
                )}
                <span>{item.name}</span>
              </>
            }
          >
            {item.to.map(this.renderMenuList)}
          </StyledSubMenu>
        );
      default:
        return [];
    }
  };

  openSider = () => {
    const { MenuToggle } = this.props;

    MenuToggle(false);
  }

  handleSider = () => {
    const { menuOpen, MenuToggle } = this.props;

    MenuToggle(!menuOpen);
  }

  render() {
    const { menuOpen } = this.props;
    const {location} = this.props;
    const list = location.pathname.indexOf('/website') === -1 ? MenuItems : WebsiteItems;

    return (
      <Layout>
        <HeaderComponent logOut={this.logOut}/>
        <div style={{ width: '100%' }}>
          <StyledSider
            className='no-print'
            collapsible
            collapsed={menuOpen}
            onCollapse={this.handleSider}
          > 
            <StyledMenu 
              mode='inline'
              forceSubMenuRender={true}
            >
              {list.map(this.renderMenuList)}
              <StyledMenuItem key={9999} onClick={this.logOut}>
                <Icon style={{ fontSize: '20px' }} type={'logout'} />
                <span>{'Logout'}</span>
              </StyledMenuItem>
            </StyledMenu>
          </StyledSider>
          <TransitionLayout collapsed={menuOpen}>
            <StyledContent>{this.props.children}</StyledContent>
          </TransitionLayout>
        </div>
      </Layout>
    );
  }
}

ResponsiveSider.propTypes = {};

const mapStateToProps = (state) => ({ 
  user: state.user,
  menuOpen: state.menu
});

const mapActionToProps = (dispatch) => bindActionCreators({ DeleteUser, MenuToggle }, dispatch);

ResponsiveSider = connect(mapStateToProps, mapActionToProps)(ResponsiveSider);

export default withRouter(ResponsiveSider);