import React, { useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import DateInput from '../../../components/generic/inputs/DateInput';
import { TableButton } from '../../../styles/BasicStyles';
import { Icon, notification, Popconfirm } from 'antd';
import { UpdateDateStudent } from '../../../infra/requests/FormsRequests';

let AddDataStudent = ({availability_date, student, formId}) => {
  const [newDate, setNewDate] = useState(availability_date);

  const onChange = (value) => {
    setNewDate(value);
  };

  const confirmDate = async() => {
    try {
      const { success } = await UpdateDateStudent(formId, student._id, {availability_date: newDate});

      if(success) {
        notification.success({
          message: "Data de Disponibilidade alterada com sucesso!"
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return(
    <React.Fragment>
      <TableButton onClick={(e) => e.stopPropagation()}>
        <DateInput 
          input={{value: newDate, onChange: value => onChange(value)}}
          meta={{}}
        />
      </TableButton>
      <TableButton onClick={(e) => e.stopPropagation()}>
        <Popconfirm
          placement='topRight'
          title={'Quer confirmar a Data de Disponibilidade?'}
          onConfirm={() => confirmDate()}
        >
          <Icon type={'check-circle'} />
          {' Confirmar'}
        </Popconfirm>
      </TableButton>
    </React.Fragment>
  )
}

export default withLocalize(AddDataStudent);