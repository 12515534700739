import client from "../config/AxiosConfig";
import { auth_token_key } from '../../infra/config/LocalStorageKeys';
const authToken = localStorage.getItem(auth_token_key);

export const GetUser = async id => await client.get(`/users/${id}`);

export const GetUserClass = async id => await client.get(`/users/class/${id}`);

export const GetUsers = async (currentPage, limit, filters) => await client.get(`/users/${currentPage}/${limit}?filter=${filters}`);

export const GetStudents = async (filters) => await client.get(`/users/students?filter=${filters}`);

export const GetStudentsBirthday = async (code, filters) => await client.get(`/users/students/${code}?filter=${filters}`);

export const GetParents = async (currentPage, limit, filters) => await client.get(`/users/parents/${currentPage}/${limit}?filter=${filters}`);

export const GetApplicatorParentsList = async () => await client.get('users/applicator/parents');

export const UpdateUser = async (id, data) => await client.put(`/users/${id}`, data);

export const UpdateProfile = async (id, data) => await client.put(`/users/profile/${id}`, data);

export const CreateUser = async data => await client.post(`/users`, data);

export const DeleteUser = async id => await client.delete(`/users/${id}`);

export const GetUsersList = async () => await client.get(`/users`);

export const GetProfile = async () => await client.get('/users/my-profile');

export const ToggleUserState = async (id, data) => await client.put(`/users/${id}/toggle`, data);

export const GetStudentsList = async (currentPage, limit, filters) => await client.get(`/users/students/${currentPage}/${limit}?filter=${filters}`);

export const GetAnswers = async (userId, chapterId) => await client.get(`/users/answers/${userId}/${chapterId}`);

export const GetAnswersWithDate = async (userId, chapterId, startDate, endDate) => await client.get(`/users/answers/${userId}/${chapterId}?start_date=${startDate}&end_date=${endDate}`);

/* PARENTS */
export const GetParent = async (user, id) => await client.get(`/users/parent/${user}/${id}`);

export const CreateParent = async (user, data) => await client.post(`/users/parent/${user}`, data);

export const UpdateParent = async (user, id, data) => await client.put(`/users/parent/${user}/${id}`, data);

export const DeleteParent = async (user, id) => await client.delete(`/users/parent/${user}/${id}`);

export const GetParentsList = async () => await client.get('/users/parents');

/* APPLICATORS */
export const GetApplicator = async (user, id) => await client.get(`/users/applicator/${user}/${id}`);

export const CreateApplicator = async (user, data) => await client.post(`/users/applicator/${user}`, data);

export const UpdateApplicator = async (user, id, data) => await client.put(`/users/applicator/${user}/${id}`, data);

export const DeleteApplicator = async (user, id) => await client.delete(`/users/applicator/${user}/${id}`);

export const GetApplicatorsList = async () => await client.get(`/users/applicators`);

export const GetStudentsApplicators = async (user) => await client.get(`/users/students_applicators/${user}`);

/* STUDENTS */

export const GetClassStudentsList = async () => await client.get(`/users/students`);

export const GetStudentDashboard = async (id, startDate, endDate) =>
  client.get(
    `/dashboards/student/${id}?start_date=${startDate}&end_date=${endDate}`
  )

export const DownloadUsers = data => { 
  return new Promise((accept, reject) => {
    fetch(`${process.env.REACT_APP_API}/reports/users`, { 
      method: 'POST', 
      body: JSON.stringify(data), 
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` } 
    })
    .then(response => {
      return response.blob().then(blob => {
        return {
          headers: response.headers,
          blob
        }
      })
    })
    .then(data => accept({ headers: data.headers, blob: data.blob }))
    .catch(error => reject(error))
  });
}

export const DownloadUserInfo = data => { 
  return new Promise((accept, reject) => {
    fetch(`${process.env.REACT_APP_API}/reports/user`, { 
      method: 'POST', 
      body: JSON.stringify(data), 
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` } 
    })
    .then(response => {
      return response.blob().then(blob => {
        return {
          headers: response.headers,
          blob
        }
      })
    })
    .then(data => accept({ headers: data.headers, blob: data.blob }))
    .catch(error => reject(error))
  });
}

export const resendEmails = async () => await client.post('/users/resend_email')

export const resendUserEmail = async (id) => await client.post(`/users/resend_user_email/${id}`)

export const BlockUserProgram = async (id) => await client.post(`/users/${id}/block_program`)

export const EmitCertificate = async (id) => await client.post(`/users/${id}/certificate`)