import { Col } from 'antd';
import styled from 'styled-components';
import { InputBorderColour, SuccessBorderColor, MenuBorderColor, SecondaryColour } from '../../styles/Colours';
import { device } from '../../styles/Responsive';

export const PointsContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const Points = styled.div`
  font-size: 16px;

  & span {
    font-weight: 700;
  }
`;

export const Image = styled.img`
  max-width: 80px;
`;

export const TextTitle = styled.div`
  font-size: 16px;
  margin-bottom: 16px;

  & span {
    font-size: 14px;
    padding: 7px;
    font-weight: 700;
    border: 1px solid ${(p) => p.theme.inputBorderColor};
    border-radius: 5px;
    width: 100%;
    display: flex;
    height: 35px;
  }
`;

export const Title = styled.div`
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 20px;

  & span {
    font-weight: 700;
  }
`;

export const AnswersContainer = styled.div`
  width: 100%;
  position: relative;
  padding: 20px;
`;

export const QuestionBox = styled.div`
  overflow: hidden;
  position: relative;
  border: 2px solid ${(p) => p.color ? p.color : p.theme.inputBorderColor};
  padding: ${(p) => p.padding ? p.padding : '8px 16px'};
  border-radius: 8px;
  text-align: left;
  display: flex; 
  flex-direction: ${p => p.row ? 'row' : 'column'};
  min-height: 100px;
  max-height: 650px;
`;

export const QuestionTitle = styled.div`
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  margin-bottom: 8px;
`; 

export const QuestionAnswer = styled.div`
  font-size: 15px;
  color: ${(p) => p.theme.inputBorderColor};
  font-weight: 500;
  margin-bottom: ${p => p.marginB ? 36 : 0}px;

  & span {
    color: ${(p) => p.color ? p.color : '#000000'};
    font-weight: 700;
  }
`;

export const BoxCol1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const BoxCol2 = styled(Col)`
  border-left: 2px solid ${(p) => p.theme.inputBorderColor};
  display: flex;
  flex-direction: column;
  padding: 24px;
  overflow-y: auto;
`;

export const BoxDate = styled.div`
  text-align: center;
  width: auto;
  background-color: ${(p) => p.index ? p.theme.secondaryColor : p.theme.thirdColor};
  padding: 5px 24px;
  border-radius: 10px;
  cursor: pointer;

  &:hover{
    background-color: #FFDE7A;
  }
`;

export const CalendarContainer = styled.div`
  border-top: ${(p) => p.first ? p.first : `2px solid ${InputBorderColour}`};
  height: auto;
  flex-wrap: wrap;
  display: flex;
`;

export const ActivitiesBox = styled.div`
  position: relative;
  border: 2px solid ${(p) => p.theme.inputBorderColor};
  border-radius: 8px;
  text-align: left;
  display: flex; 
  flex-direction: column;
  position: relative;
`;

export const ActivitiesContainer = styled(Col)`
  padding: 8px 16px;
  border-right: 2px solid ${InputBorderColour};
  height: auto;

  @media${device.tablet} {
    border-right: none;
    border-bottom: 2px solid ${InputBorderColour};
  }

`;

export const ActivityBox = styled(Col)`
  border-radius: 10px;
  background-color: ${p => p.completed ? SuccessBorderColor : '#FCE5C4'};
  color: ${p => p.completed ? '#ffffff' : '#5E5A54'};
  padding: 5px 15px;
  margin-right: 10px;
  height: auto;
`;

export const ObjectiveCol = styled.div `
  border: 2px solid ${InputBorderColour};
  border-radius: 8px;
`;

export const AnswerBox = styled.div`
  border-bottom: ${(p) => p.last ? null : `2px solid ${InputBorderColour}`};
  padding: 8px; 
`;


export const ChartBox = styled.div`
  width: 100%;
  height: 100%;
  border: 2px solid
    ${MenuBorderColor};
  border-radius: 8px;
  padding: 24px;
  margin-top: 24px;
`

export const ChartBoxInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid ${MenuBorderColor};
  border-radius: 8px;
  margin-bottom: 8px;
  text-align: center;
`

export const LegendMeditationContainer = styled.div`
  padding: 0px 24px;
`

export const LegendLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: left;
`

export const BoxColor = styled.div`
  border-top: 3px solid ${({color}) => color || SecondaryColour};
  width: 10px;
  margin-right: 8px;
  margin-top: 8px;
  min-width: 10px;
`
