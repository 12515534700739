export const Types = [
  { _id: 1, name: 'Texto' },
  { _id: 2, name: 'Imagem' },
  { _id: 3, name: 'Vídeo' },
  { _id: 4, name: 'Áudio' }
];

export const getType = (value) => {
  if(Number.isInteger(value) && value > 0 && Types[value - 1]) return Types[value - 1].name;
  return null;
};