import client from '../config/AxiosConfig';

export const GetExtraSessions = async () => await client.get('/extra_session');

export const GetExtraSession = async (id) => await client.get(`/extra_session/${id}`);

export const UpdateExtraSession = async (id, data) => await client.put(`/extra_session/${id}`, data);

export const UpdateDateStudent= async (idSession, idStudent, data) => await client.put(`/extra_session_availability/${idSession}/student/${idStudent}`, data);

export const UpdateDateClass = async (idSession, idClass, data) => await client.put(`/extra_session_availability/${idSession}/class/${idClass}`, data);

// BLOCKS
export const GetBlocks = async (id, currentPage, limit) => await client.get(`/extra_session_blocks/${id}/${currentPage}/${limit}`);

export const GetBlock = async (id) => await client.get(`/extra_session_blocks/${id}`);

export const CreateBlock = async (data) => await client.post(`/extra_session_blocks`, data);

export const UpdateBlock = async (id, data) => await client.put(`/extra_session_blocks/${id}`, data);

export const ToggleBlock = async (id, data) => await client.put(`/extra_session_blocks/${id}/toggle`, data);

export const DeleteBlock = async (id) => await client.delete(`/extra_session_blocks/${id}`);

export const UpdateBlocksOrder = async (data) => await client.post(`/extra_session_blocks/order`, data);