import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Row, Col, Tabs } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetJewel,
  UpdateJewel
} from '../../infra/requests/JewelsRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab
} from '../../styles/BasicStyles';
import {
  TitleContainer,
  Title,
  Image
} from './JewelsStyles';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/generic/inputs/TextInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";
import Jewel_1 from '../../assets/jewels/1.svg';
import Jewel_2 from '../../assets/jewels/2.svg';
import Jewel_3 from '../../assets/jewels/3.svg';
import Jewel_4 from '../../assets/jewels/4.svg';
import Jewel_5 from '../../assets/jewels/5.svg';
import Jewel_6 from '../../assets/jewels/6.svg';
import Jewel_7 from '../../assets/jewels/7.svg';
import Jewel_8 from '../../assets/jewels/8.svg';
import Jewel_9 from '../../assets/jewels/9.svg';
import Jewel_10 from '../../assets/jewels/10.svg';
import Jewel_11 from '../../assets/jewels/11.svg';
import Jewel_12 from '../../assets/jewels/12.svg';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import FormValidator from '../../infra/services/validations/FormValidator';

const TabPane = Tabs.TabPane;

const chooseIcon = (order) => {
  if(order === 1) return Jewel_1;
  if(order === 2) return Jewel_2;
  if(order === 3) return Jewel_3;
  if(order === 4) return Jewel_4;
  if(order === 5) return Jewel_5;
  if(order === 6) return Jewel_6;
  if(order === 7) return Jewel_7;
  if(order === 8) return Jewel_8;
  if(order === 9) return Jewel_9;
  if(order === 10) return Jewel_10;
  if(order === 11) return Jewel_11;
  if(order === 12) return Jewel_12;
  return '';
};

const validations = FormValidator.make({
  title: 'required|languages',
  points: 'required'
});

class ManageJewelsPage extends Component {
  state = { 
    loading: true,
    info: undefined
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu, 
      user,
      history
    } = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 
    
    CloseMenu();

    const result = await GetJewel(params.id);
    dispatch(
      initialize('manage_jewels_form', {
        ...result.data,
      })
    );

    this.setState({
      loading: false,
      info: result?.data 
    });
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;

      const payload = FlattenToFormData(values);
      const { success } = await UpdateJewel(params.id, payload);

      if (success) return history.push('/jewels');
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/jewels');
  };

  render() {
    const { handleSubmit, pristine, activeLanguage, languages } = this.props;
    const { loading, info } = this.state;

    if(loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Jóias', 'Editar']}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24}>
              <Col xs={12} offset={0}>
                <TitleContainer>
                  <Title>Jóia:</Title>
                  <Image src={chooseIcon(info?.order)} alt='icon'/>
                </TitleContainer>
              </Col>
            </Row>
            <Row gutter={24} style={{marginBottom: 24}}>
              <Col xs={12} offset={0}>
                <Field
                  component={TextInput}
                  name='points'
                  type='number'
                  label='Pontos *'
                  placeholder='Pontos'
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12} offset={0}>
                <DefaultLanguageTab>
                  {GetTranslationDescription(activeLanguage?.code)}
                </DefaultLanguageTab>
                <Field
                  component={TextInput}
                  name={`title.${activeLanguage?.code}`}
                  type='text'
                  label={'Título *'}
                  placeholder={'Título'}
                />
              </Col>
              <Col xs={12} offset={0}>
                <Tabs>
                  {languages
                    .filter((x) => !x.active)
                    .map((language) => (
                      <TabPane
                        tab={GetTranslationDescription(language.code)}
                        key={language.code}
                      >
                        <Field
                          component={TextInput}
                          name={`title.${language.code}`}
                          type='text'
                          label={'Título *'}
                          placeholder={'Título'}
                        />
                      </TabPane>
                    ))}
                </Tabs>
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageJewelsPage = reduxForm({
  form: 'manage_jewels_form',
  validate: validations
})(ManageJewelsPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageJewelsPage));