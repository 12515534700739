import React, { Component } from 'react';
import { Field, reduxForm, initialize, FieldArray } from 'redux-form';
import { Tabs, Row, Col, notification } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetStep,
  UpdateStep
} from '../../infra/requests/ProgramsRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab,
  Separator
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import TextInput from '../../components/generic/inputs/TextInput';
import VideoInput from '../../components/generic/inputs/VideoInput';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import DraftInput from '../../components/generic/inputs/DraftInput';
import { getStepType } from '../../infra/utils/StepTypes';
import ImageInput from '../../components/generic/inputs/ImageInput';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import FilesInput from '../../components/generic/inputs/FilesInput';
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  title: 'required|languages',
  description: 'required|languages'
});

class ManageStepsPage extends Component {
  state = { 
    loading: true,
    program: undefined,
    session: undefined,
    chapter: undefined,
    step: undefined
  };

  componentDidMount = async () => {
    const {user, history} = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 

    await this.loadDetail();
  };

  loadDetail = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu
    } = this.props;
    
    CloseMenu();

    const result = await GetStep(params.id);

    dispatch(
      initialize('manage_steps_form', {
        ...result.data,
      })
    );

    this.setState({ 
      loading: false,
      program: result?.data?.chapter?.session?.program,
      session: result?.data?.chapter?.session,
      chapter: result?.data?.chapter,
      step: result?.data,
    });
  }

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const { match: { params } } = this.props;

      if(values.image && !values.image.blob) {
        delete values.image;
      }
     
      const payload = FlattenToFormData({ ...values });
      const { success } = await UpdateStep(params.id, payload);

      if(success) {
        notification.success({
          message: "Sucesso!",
          description: "Alterações guardadas com sucesso."
        });
        return await this.loadDetail();
      }

      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    const { chapter } = this.state;

    return history.push(`/programs/chapters/${chapter._id}?steps=true`);
  };

  renderDescriptions = ({ fields, language }) => {
    const {activeLanguage, languages} = this.props;
    return (
      <Row gutter={24}>
        <Col xs={12} offset={0}>
          <DefaultLanguageTab>
            {GetTranslationDescription(activeLanguage?.code)}
          </DefaultLanguageTab>
          {fields.map((description, index) => {
            return (
              <Field
                component={DraftInput}
                name={`${description}.title.${activeLanguage?.code}`}
                label={'Descrição'}
              />
            )
          })}
        </Col>
        <Col xs={12} offset={0}>
          <Tabs>
            {languages
              .filter((x) => !x.active)
              .map((language) => (
                <TabPane
                  tab={GetTranslationDescription(language.code)}
                  key={language.code}
                >
                  {fields.map((description, index) => {
                    return (
                      <Field
                        key={index}
                        component={DraftInput}
                        name={`${description}.title.${language.code}`}
                        label={'Descrição'}
                      />
                    )
                  })}
                </TabPane>
              ))}
          </Tabs>
        </Col>
      </Row>
    );
  };

  renderQuestions = ({ fields, language }) => {
    const {activeLanguage, languages} = this.props;
    const questions = fields.getAll();
    return (
      <Row gutter={24}>
        <Col xs={12} offset={0}>
          <DefaultLanguageTab>
            {GetTranslationDescription(activeLanguage?.code)}
          </DefaultLanguageTab>
          {fields.map((question, index) => {
            return (
              <div key={index}>
                {questions[index].title && (
                  <Field
                  type='text'
                    component={TextInput}
                    name={`${question}.title.${activeLanguage?.code}`}
                    label={'Pergunta'}
                  />
                )}
                {questions[index].options && questions[index].options.length > 0 && (
                  <div style={{marginBottom: 15, padding: '0px 15px 15px', background: '#f0f0f0'}}>
                  {questions[index].options.map((option, i) => (
                    <div key={i}>
                      <Field
                        type='text'
                        component={TextInput}
                        name={`${question}.options[${i}].title.${activeLanguage?.code}`}
                        label={'Opção de Resposta'}
                      />
                      {questions[index].options[i].explanation && (
                        <Field
                          type='text'
                          component={DraftInput}
                          name={`${question}.options[${i}].explanation.${activeLanguage?.code}`}
                          label={'Explicação da Resposta'}
                        />
                      )}
                    </div>
                  ))}
                  </div>
                )}
                {questions[index].correct && (<Field
                  type='text'
                  component={TextInput}
                  name={`${question}.correct.${activeLanguage?.code}`}
                  label={'Feedback quando acerta'}
                />)}
                 {questions[index].wrong && (<Field
                  type='text'
                  component={TextInput}
                  name={`${question}.wrong.${activeLanguage?.code}`}
                  label={'Feedback quando erra'}
                />)}
              </div>
            )
          })}
        </Col>
        <Col xs={12} offset={0}>
          <Tabs>
            {languages
              .filter((x) => !x.active)
              .map((language) => (
                <TabPane
                  tab={GetTranslationDescription(language.code)}
                  key={language.code}
                >
                  {fields.map((question, index) => {
                    return (
                      <div key={index}>
                        {questions[index].title && (
                          <Field
                            key={index}
                            type='text'
                            component={TextInput}
                            name={`${question}.title.${language.code}`}
                            label={'Pergunta'}
                          />
                        )}
                        {questions[index].options && questions[index].options.length > 0 && (
                          <div style={{marginBottom: 15, padding: '0px 15px 15px', background: '#f0f0f0'}}>
                          {questions[index].options.map((option, i) => (
                            <div key={i}>
                              <Field
                                type='text'
                                component={TextInput}
                                name={`${question}.options[${i}].title.${language.code}`}
                                label={'Opção de Resposta'}
                              />
                              {questions[index].options[i].explanation && (
                                <Field
                                  type='text'
                                  component={DraftInput}
                                  name={`${question}.options[${i}].explanation.${language.code}`}
                                  label={'Explicação da Resposta'}
                                />
                              )}
                            </div>
                          ))}
                          </div>
                        )}
                        {questions[index].correct && (<Field
                          type='text'
                          component={TextInput}
                          name={`${question}.correct.${language.code}`}
                          label={'Feedback quando acerta'}
                        />)}
                        {questions[index].wrong && (<Field
                          type='text'
                          component={TextInput}
                          name={`${question}.wrong.${language.code}`}
                          label={'Feedback quando erra'}
                        />)}
                      </div>
                    )
                  })}
                </TabPane>
              ))}
          </Tabs>
        </Col>
      </Row>
    );
  };

  render() {
    const { handleSubmit, pristine, activeLanguage, languages, translate } = this.props;
    const { loading, program, session, chapter, step } = this.state;

    if(loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Programas', TranslateValue(program?.title), TranslateValue(session?.title),  TranslateValue(chapter?.title), `Cena ${step.order}`,'Editar']}
          titlePosition={3}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <>
              <Row gutter={24}>
                <Col xs={12} offset={0}>
                  <TextInput
                    type='text'
                    label={'Tipo'}
                    meta={{}}
                    input={{ value: getStepType(step.type), onChange: () => null }}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Separator/>
              {step.title && (
                <Row gutter={24}>
                  <Col xs={12} offset={0}>
                    <DefaultLanguageTab>
                      {GetTranslationDescription(activeLanguage?.code)}
                    </DefaultLanguageTab>
                    <Field
                      component={TextInput}
                      name={`title.${activeLanguage?.code}`}
                      type='text'
                      label={'Título *'}
                      placeholder={'Título'}
                    />
                  </Col>
                  <Col xs={12} offset={0}>
                    <Tabs>
                      {languages
                        .filter((x) => !x.active)
                        .map((language) => (
                          <TabPane
                            tab={GetTranslationDescription(language.code)}
                            key={language.code}
                          >
                            <Field
                            type='text'
                              component={TextInput}
                              name={`title.${language.code}`}
                              label={'Título'}
                            />
                          </TabPane>
                        ))}
                    </Tabs>
                  </Col>
                </Row>
              )}
              {/* {step.type === 'text' && ( */}
                {step.descriptions && step.descriptions.length > 0 && (<FieldArray
                    component={this.renderDescriptions}
                    name="descriptions"
                    type="text"
                    label={translate("BO_LANGUAGES")}
                    placeholder={translate("BO_LANGUAGES_PLACEHOLDER")}
                  />)}
              {/* )} */}
              {step.type === 'question' && (
                <FieldArray
                  component={this.renderQuestions}
                  name="questions"
                  type="text"
                  label={translate("BO_LANGUAGES")}
                  placeholder={translate("BO_LANGUAGES_PLACEHOLDER")}
                />
              )}
              {step.type === 'external_video' && (
                <Field
                  component={VideoInput}
                  name='video'
                  type='text'
                  label='URL do Vídeo *'
                  sublabel='Pré-visualizar'
                  placeholder='Link para Youtube ou Vimeo'
                />
              )}
              {step.type === 'image' && (
                <Row gutter={24}>
                  <Col xs={12} offset={0}>
                    <DefaultLanguageTab>
                      {GetTranslationDescription(activeLanguage?.code)}
                    </DefaultLanguageTab>
                    <Field
                      component={ImageInput}
                      name='image'
                      label='Imagem *'
                      ratio={0.8}
                      noCrop={true}
                    />
                  </Col>
                  <Col xs={12} offset={0}>
                    <Tabs>
                      {languages
                        .filter((x) => !x.active)
                        .map((language) => (
                          <TabPane
                            tab={GetTranslationDescription(language.code)}
                            key={language.code}
                          >
                            <Field
                              component={ImageInput}
                              name={`image_${language.code}`}
                              label='Imagem *'
                              ratio={0.8}
                              noCrop={true}
                            />
                          </TabPane>
                        ))}
                    </Tabs>
                  </Col>
                </Row>
              )}
              {step.type === 'media' && (
                <Row gutter={24}>
                  <Col xs={12} offset={0}>
                    <DefaultLanguageTab>
                      {GetTranslationDescription(activeLanguage?.code)}
                    </DefaultLanguageTab>
                    <Field
                      component={FilesInput}
                      name={`file_${activeLanguage?.code}`}
                      label='Ficheiro *'
                      accept={'application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf'}
                      acceptText='Formato(s) permitido(s): .pdf, .doc, .docx, .xls, .xlsx, .csv, .tsv, .ppt, .pptx, .pages, .odt, .rtf '
                    />
                  </Col>
                  <Col xs={12} offset={0}>
                    <Tabs>
                      {languages
                        .filter((x) => !x.active)
                        .map((language) => (
                          <TabPane
                            tab={GetTranslationDescription(language.code)}
                            key={language.code}
                          >
                            <Field
                              component={FilesInput}
                              name={`file_${language.code}`}
                              label='Ficheiro *'
                              accept={'application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf'}
                              acceptText='Formato(s) permitido(s): .pdf, .doc, .docx, .xls, .xlsx, .csv, .tsv, .ppt, .pptx, .pages, .odt, .rtf '
                            />
                          </TabPane>
                        ))}
                    </Tabs>
                  </Col>
                </Row>
              )}
              {step.type === 'audio' && (
                <Row gutter={24}>
                  <Col xs={12} offset={0}>
                    <DefaultLanguageTab>
                      {GetTranslationDescription(activeLanguage?.code)}
                    </DefaultLanguageTab>
                    <Field
                      component={FilesInput}
                      name={`audio_${activeLanguage?.code}`}
                      label='Ficheiro Áudio *'
                      accept={'audio/mpeg, audio/wav'}
                      acceptText='Formato(s) permitido(s): .mp3, .wav'
                    />
                  </Col>
                  <Col xs={12} offset={0}>
                    <Tabs>
                      {languages
                        .filter((x) => !x.active)
                        .map((language) => (
                          <TabPane
                            tab={GetTranslationDescription(language.code)}
                            key={language.code}
                          >
                            <Field
                              component={FilesInput}
                              name={`audio_${language.code}`}
                              label='Ficheiro Áudio *'
                              accept={'audio/mpeg, audio/wav'}
                              acceptText='Formato(s) permitido(s): .mp3, .wav'
                            />
                          </TabPane>
                        ))}
                    </Tabs>
                  </Col>
                </Row>
              )}
              {/* {step.type === 'video' && (
                <Row gutter={24}>
                  <Col xs={12} offset={0}>
                    <DefaultLanguageTab>
                      {GetTranslationDescription(activeLanguage?.code)}
                    </DefaultLanguageTab>
                    <Field
                      maxSize={70000000}
                      component={FilesInput}
                      name={`video_${activeLanguage?.code}`}
                      label='Ficheiro de Vídeo *'
                      accept={'video/webm, video/mp4'}
                      acceptText='Formato(s) permitido(s): .mp4, .webm'
                    />
                  </Col>
                  <Col xs={12} offset={0}>
                    <Tabs>
                      {languages
                        .filter((x) => !x.active)
                        .map((language) => (
                          <TabPane
                            tab={GetTranslationDescription(language.code)}
                            key={language.code}
                          >
                            <Field
                              maxSize={70000000}
                              component={FilesInput}
                              name={`video_${language.code}`}
                              label='Ficheiro de Vídeo *'
                              accept={'video/webm, video/mp4'}
                              acceptText='Formato(s) permitido(s): .mp4, .webm'
                            />
                          </TabPane>
                        ))}
                    </Tabs>
                  </Col>
                </Row>
              )} */}
            </>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageStepsPage = reduxForm({
  form: 'manage_steps_form',
  validate: validations,
})(ManageStepsPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageStepsPage));