export default [
  {
    type: 'menu',
    name: 'Dashboard',
    to: '/',
    icon: 'home',
    exact: true,
  },
  {
    type: 'menu',
    name: 'Programas',
    to: '/programs',
    icon: 'form',
    exact: false,
    permission_type: 1
  },
  {
    type: 'menu',
    name: 'Escolas',
    to: '/schools',
    icon: 'schools.svg',
    external_icon: true,
    exact: false,
  },
  {
    type: 'menu',
    name: 'Turmas',
    to: '/classes',
    icon: 'classes.svg',
    external_icon: true,
    exact: false,
  },
  {
    type: 'menu',
    name: 'Conteúdo dos Audios',
    to: '/audio',
    icon: 'sound',
    exact: false,
    permission_type: 1
  },
  {
    type: 'submenu',
    name: 'Fórum',
    baseUrl: '/forum',
    to: [
      {
        type: 'menu',
        name: 'Alunos',
        to: '/forum/603e191d3222800e9d983904',
        icon: 'bars',
        exact: true,
        permission_type: 1
      },
      {
        type: 'menu',
        name: 'Enc. Educação',
        to: '/forum/603e192a3222800e9d983905',
        icon: 'bars',
        exact: true,
        permission_type: 1
      },
      {
        type: 'menu',
        name: 'Público',
        to: '/forum/603e19313222800e9d983906',
        icon: 'bars',
        exact: true,
        permission_type: 1
      }
    ],
    icon: 'message',
    exact: true,
    permission_type: 1
  },
  {
    type: 'menu',
    name: 'Questionários',
    to: '/forms',
    icon: 'forms.svg',
    external_icon: true,
    exact: false
  },
  {
    type: 'menu',
    name: 'Sessões Extra',
    to: '/extra-session',
    icon: 'fire',
    exact: false
  },
  {
    type: 'menu',
    name: 'Pontos',
    to: '/points',
    icon: 'points.svg',
    external_icon: true,
    exact: false,
    permission_type: 1
  },
  {
    type: 'menu',
    name: 'Jóias',
    to: '/jewels',
    icon: 'jewels.svg',
    external_icon: true,
    exact: false,
    permission_type: 1
  },
  {
    type: 'menu',
    name: 'Utilizadores',
    to: '/users',
    icon: 'team',
    exact: false,
    permission_type: 1
  },
  {
    type: 'menu',
    name: 'Pedidos de Registo',
    to: '/users-register',
    icon: 'solution',
    exact: false,
    permission_type: 1
  },
  {
    type: 'submenu',
    name: 'Utilizadores',
    baseUrl: '/users',
    to: [
      {
        type: 'menu',
        name: 'Educandos',
        to: '/users/students',
        icon: 'bars',
        exact: true,
        permission_type: 2
      },
      {
        type: 'menu',
        name: 'Enc. Educação',
        to: '/users/parents',
        icon: 'bars',
        exact: true,
        permission_type: 2
      },
    ],
    icon: 'team',
    exact: true,
    permission_type: 2
  },
  {
    type: 'menu',
    name: 'O Meu Perfil',
    to: '/my-profile',
    icon: 'user',
    exact: true,
  },
  {
    type: 'menu',
    name: 'Templates de Email',
    to: '/email-templates',
    icon: 'mail',
    exact: false,
    permission_type: 1
  },
  {
    type: 'menu',
    name: 'Traduções',
    to: '/translations',
    icon: 'global',
    exact: true,
    permission_type: 1
  },
  {
    type: 'menu',
    name: 'Vídeos',
    to: '/videos',
    icon: 'video-camera',
    exact: true,
    permission_type: 1
  }
];