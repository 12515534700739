export const StepTypes = [
  { _id: 'text', name: 'Texto' },
  { _id: 'question', name: 'Pergunta' },
  { _id: 'media', name: 'Media' },
  { _id: 'video', name: 'Vídeo' },
  { _id: 'audio', name: 'Áudio' },
  { _id: 'select', name: 'Pergunta do tipo selecionar' },
  { _id: 'slide', name: 'Pergunta do tipo slide' },
  { _id: 'external_video', name: 'Vídeo Externo' },
  { _id: 'image', name: 'Imagem' },
]

export const getStepType = (value) => {
  if(StepTypes.find(t => t._id === value)) return StepTypes.find(t => t._id === value).name;
  return null;
};