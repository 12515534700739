import React, { Component } from 'react';
import { Field, reduxForm, initialize, FieldArray } from 'redux-form';
import { Tabs, Row, Col, notification } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab,
  Separator
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import TextInput from '../../components/generic/inputs/TextInput';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import DraftInput from '../../components/generic/inputs/DraftInput';
import { getStepType } from '../../infra/utils/StepTypes';
import { GetAudioContent, UpdateAudioContent } from '../../infra/requests/AudioRequests';
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  title: 'required|languages',
  description: 'required|languages',
  duration: 'required'
});

class ManageAudioContentPage extends Component {
  state = { 
    loading: true,
    audio: undefined
  };

  componentDidMount = async () => {
    const {user, history} = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 
    
    await this.loadDetail();
  };

  loadDetail = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu
    } = this.props;
    
    CloseMenu();

    const result = await GetAudioContent(params.id);

    dispatch(
      initialize('manage_audio_form', {
        ...result.data,
      })
    );

    this.setState({ 
      loading: false,
      audio: result?.data,
    });
  }

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const { match: { params } } = this.props;

      const { success } = await UpdateAudioContent(params.id, values);

      if(success) {
        notification.success({
          message: "Sucesso!",
          description: "Alterações guardadas com sucesso."
        });
        return await this.loadDetail();
      }

      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;

    return history.push('/audio');
  };

  renderDescriptions = ({ fields, language }) => {
    const {activeLanguage, languages} = this.props;
    return (
      <Row gutter={24}>
        <Col xs={12} offset={0}>
          <DefaultLanguageTab>
            {GetTranslationDescription(activeLanguage?.code)}
          </DefaultLanguageTab>
          {fields.map((description, index) => {
            return (
              <Field
                component={DraftInput}
                name={`${description}.title.${activeLanguage?.code}`}
                label={'Descrição'}
              />
            )
          })}
        </Col>
        <Col xs={12} offset={0}>
          <Tabs>
            {languages
              .filter((x) => !x.active)
              .map((language) => (
                <TabPane
                  tab={GetTranslationDescription(language.code)}
                  key={language.code}
                >
                  {fields.map((description, index) => {
                    return (
                      <Field
                        key={index}
                        component={DraftInput}
                        name={`${description}.title.${language.code}`}
                        label={'Descrição'}
                      />
                    )
                  })}
                </TabPane>
              ))}
          </Tabs>
        </Col>
      </Row>
    );
  };

  renderOptions = ({ fields, language }) => {
    const {activeLanguage, languages} = this.props;
    const options = fields.getAll();
    return (
      <Row gutter={24}>
        <Col xs={12} offset={0}>
          <DefaultLanguageTab>
            {GetTranslationDescription(activeLanguage?.code)}
          </DefaultLanguageTab>
          {fields.map((option, index) => {
            return (
              <div key={index}>
                {options[index].title && (
                  <Field
                  type='text'
                    component={TextInput}
                    name={`${option}.title.${activeLanguage?.code}`}
                    label={'Opção de Resposta'}
                  />
                )}
              </div>
            )
          })}
        </Col>
        <Col xs={12} offset={0}>
          <Tabs>
            {languages
              .filter((x) => !x.active)
              .map((language) => (
                <TabPane
                  tab={GetTranslationDescription(language.code)}
                  key={language.code}
                >
                  {fields.map((option, index) => {
                    return (
                      <div key={index}>
                        {options[index].title && (
                          <Field
                            key={index}
                            type='text'
                            component={TextInput}
                            name={`${option}.title.${language.code}`}
                            label={'Opção de Resposta'}
                          />
                        )}
                      </div>
                    )
                  })}
                </TabPane>
              ))}
          </Tabs>
        </Col>
      </Row>
    );
  };

  render() {
    const { handleSubmit, pristine, activeLanguage, languages, translate } = this.props;
    const { loading, audio } = this.state;

    if(loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Audio', `Conteúdo ${audio.order}`,'Editar']}
          titlePosition={3}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <>
              <Row gutter={24}>
                <Col xs={12} offset={0}>
                  <TextInput
                    type='text'
                    label={'Tipo'}
                    meta={{}}
                    input={{ value: getStepType(audio.type), onChange: () => null }}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Separator/>
              {audio.title && (
                <Row gutter={24}>
                  <Col xs={12} offset={0}>
                    <DefaultLanguageTab>
                      {GetTranslationDescription(activeLanguage?.code)}
                    </DefaultLanguageTab>
                    <Field
                      component={TextInput}
                      name={`title.${activeLanguage?.code}`}
                      type='text'
                      label={'Título *'}
                      placeholder={'Título'}
                    />
                  </Col>
                  <Col xs={12} offset={0}>
                    <Tabs>
                      {languages
                        .filter((x) => !x.active)
                        .map((language) => (
                          <TabPane
                            tab={GetTranslationDescription(language.code)}
                            key={language.code}
                          >
                            <Field
                            type='text'
                              component={TextInput}
                              name={`title.${language.code}`}
                              label={'Título'}
                            />
                          </TabPane>
                        ))}
                    </Tabs>
                  </Col>
                </Row>
              )}
              {audio.descriptions && audio.descriptions.length > 0 && (
                <FieldArray
                  component={this.renderDescriptions}
                  name="descriptions"
                  type="text"
                  label={translate("BO_LANGUAGES")}
                  placeholder={translate("BO_LANGUAGES_PLACEHOLDER")}
                />
              )}
              {(audio.type === 'select' || audio.type === 'slide') && (
                <FieldArray
                  component={this.renderOptions}
                  name="options"
                  type="text"
                  label={translate("BO_LANGUAGES")}
                  placeholder={translate("BO_LANGUAGES_PLACEHOLDER")}
                />
              )}
            </>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageAudioContentPage = reduxForm({
  form: 'manage_audio_form',
  validate: validations,
})(ManageAudioContentPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageAudioContentPage));