import client from '../config/AxiosConfig';

/* BANNER */
export const GetBanner = async (id) => await client.get(`/home_banner/${id}`);

export const UpdateBanner = async (id, data) => await client.put(`/home_banner/${id}`, data);

/* PLATFORM */
export const GetPlatforms = async (currentPage, limit, filters) => await client.get(`/home_platform/${currentPage}/${limit}?filter=${filters}`);

export const GetPlatform = async (id) => await client.get(`/home_platform/${id}`);

export const CreatePlatform = async (data) => await client.post(`/home_platform`, data);

export const UpdatePlatform = async (id, data) => await client.put(`/home_platform/${id}`, data);

export const DeletePlatform = async (id) => await client.delete(`/home_platform/${id}`);

export const UpdatePlatformsOrder = async (data) => await client.post(`/home_platform/order`, data);

/* PROGRAMS */
export const GetPrograms = async (currentPage, limit, filters) => await client.get(`/home_programs/${currentPage}/${limit}?filter=${filters}`);

export const GetProgram = async (id) => await client.get(`/home_programs/${id}`);

export const UpdateProgram = async (id, data) => await client.put(`/home_programs/${id}`, data);

export const UpdateProgramsOrder = async (data) => await client.post(`/home_programs/order`, data);

/* TESTIMONIALS */
export const GetTestimonials = async (currentPage, limit, filters) => await client.get(`/home_testimonials/${currentPage}/${limit}?filter=${filters}`);

export const GetTestimonial = async (id) => await client.get(`/home_testimonials/${id}`);

export const CreateTestimonial = async (data) => await client.post(`/home_testimonials`, data);

export const UpdateTestimonial = async (id, data) => await client.put(`/home_testimonials/${id}`, data);

export const DeleteTestimonial = async (id) => await client.delete(`/home_testimonials/${id}`);

export const ToggleFeaturedTestimonial = async (id, data) => await client.put(`/home_testimonials/${id}/toggle-featured`, data);