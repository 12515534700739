import React, { Component } from 'react';
import { Popconfirm, Icon } from 'antd';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton,
} from '../../styles/BasicStyles';
import {
  GetSchoolsList,
  GetClasses,
  DeleteClass
} from '../../infra/requests/SchoolsRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import ClassesFilters from './ClassesFilters';
import { connect } from 'react-redux';

class ClassesPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Nome',
        dataIndex: 'name',
        width: '35%'
      },
      {
        title: 'Escola',
        dataIndex: 'school',
        render: (value) => value?.name,
        width: '35%'
      },
      {
        title: 'Ações',
        render: (data) => (
          <React.Fragment>
            <TableButton error onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement='topRight'
                title='Tem a certeza que quer remover esta Turma?'
                onConfirm={() => this.removeClass(data)}
              >
                <Icon style={{ marginRight: 10 }} type='delete' />
                {'Remover'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        ),
      },
    ],
    rows: [],
    total: 0,
    schools: []
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/classes/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  removeClass = async (data) => {
    try {
      await DeleteClass(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    try {
      this.setState({ loading: true });
      let { currentPage, pageSize, filters, columns } = this.state;
      const { user } = this.props;

      const result = await GetClasses(currentPage, pageSize, filters);
      const schools = await GetSchoolsList();

      if(user.type === 2) {
        columns = [
          {
            title: 'Nome',
            dataIndex: 'name',
            width: '60%'
          },
          {
            title: 'Escola',
            dataIndex: 'school',
            render: (value) => value?.name,
            width: '40%'
          }
        ];
      }

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
        schools: schools?.data || [],
        columns
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
      schools
    } = this.state;
    const { user, history } = this.props;
    
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={user?.type === 2 ? 0 : 1}>
            <PageTitle>Lista de Turmas</PageTitle>
            {`Turmas`}
          </HeaderTitle>
          {
            user?.type === 1 &&
            <HeaderButtonsContainer buttons={11}>
              <BaseButton
                type={'primary'}
                icon={'plus'}
                text={'Criar'}
                onClick={() => history.push('/classes/add')}
              />
            </HeaderButtonsContainer>
          }
        </HeaderContainer>
        <PageContainer>
          <ClassesFilters schools={schools} queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Turmas inseridas!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={user?.type !== 2 && this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(ClassesPage);