
import React, { Component } from 'react';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import { GetVideos } from '../../infra/requests/VideosRequests';
import VideosFilters from './VideosFilters';
import { connect } from 'react-redux';
import BaseButton from '../../components/generic/buttons/BaseButton';

class VideosPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Nome',
        dataIndex: 'name',
        width: '70%'
      },
      {
        title: 'Sessão',
        dataIndex: 'session',
        width: '10%'
      },
      {
        title: 'Capítulo',
        dataIndex: 'chapter',
        width: '10%'
      },
      {
        title: 'Cena',
        dataIndex: 'step',
        width: '10%'
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    const { user, history } = this.props;

    if(user.type !== 1) return history.push('/');

    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/videos/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetVideos(currentPage, pageSize, filters);

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize
    } = this.state;
    const { history, user } = this.props;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Lista de Vídeos</PageTitle>
            {`Vídeos`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            {user.isCodepointAdmin && (
              <BaseButton
                type={'primary'}
                icon={'plus'}
                text={'Criar'}
                onClick={() => history.push('/videos/add')}
              />
            )}
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <VideosFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Vídeos inseridos!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, null)(VideosPage);