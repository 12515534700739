import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col, notification } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetChapter,
  UpdateChapter,
  DownloadChapter
} from '../../infra/requests/ProgramsRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab,
  Separator,
  TabsContainer,
  Tab
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import TextInput from '../../components/generic/inputs/TextInput';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import DraftInput from '../../components/generic/inputs/DraftInput';
import { getChapterType } from '../../infra/utils/ChapterTypes';
import StepsList from './components/StepsList'; 
import { Title } from '../users/UserStyles';
import CheckboxInput from '../../components/generic/inputs/CheckboxInput';
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  title: 'required|languages'
});

class ManageChaptersPage extends Component {
  state = { 
    loading: true,
    program: undefined,
    session: undefined,
    chapter: undefined,
    stepsList: [],
    defaultTab: 'details'
  };

  componentDidMount = async () => {
    const {user, history} = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 
    
    await this.loadDetail();
    
  };

  loadDetail = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu,
      history
    } = this.props;
    
    CloseMenu();

    const result = await GetChapter(params.id);

    dispatch(
      initialize('manage_chapters_form', {
        ...result.data,
      })
    );

    let defaultTab = 'details';
    if(history?.location?.search?.includes('steps=true')) {
      defaultTab = 'steps';
      history.replace(`/programs/chapters/${params.id}`);
    } 

    this.setState({ 
      loading: false,
      stepsList: result?.data?.steps || [],
      program: result?.data?.session?.program,
      session: result?.data?.session,
      chapter: result?.data,
      defaultTab
    });
  }

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const { match: { params } } = this.props;

      const { success } = await UpdateChapter(params.id, values);

      if(success) {
        notification.success({
          message: "Sucesso!",
          description: "Alterações guardadas com sucesso."
        });
        return await this.loadDetail();
      }

      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    const { session } = this.state;

    return history.push(`/programs/sessions/${session._id}?chapters=true`);
  };

  downloadReport = async () => {
    const { rows, filters, chapter, session } = this.state;
    const {
      match: { params }
    } = this.props;

    try {
      if(params.id) {
        const name = `${TranslateValue(chapter?.title)}_${TranslateValue(session?.title)}` || 'Capítulo'
        const result = await DownloadChapter({id: params.id, filename: name});

        if(result) {
          let filename = `${name.replace(/ /g,"_")}.xlsx`;
          if(result.headers) {
            const contentDisposition = result.headers.get('content-disposition');
            filename = contentDisposition.split('filename="')[1].split('";')[0];
          }

          //Create blob link to download
          const url = window.URL.createObjectURL(new Blob([result.blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);

          //Append to html link element page
          document.body.appendChild(link);
          
          //Start download
          link.click();
          
          //Clean up and remove the link
          link.parentNode.removeChild(link);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { handleSubmit, pristine, activeLanguage, languages } = this.props;
    const { loading, program, session, chapter, defaultTab, stepsList } = this.state;

    if(loading) return <SpinLoading />;
    console.log('chapter', chapter);
    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Programas', TranslateValue(program?.title), TranslateValue(session?.title),  TranslateValue(chapter?.title), 'Editar']}
          titlePosition={3}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
            {
              type: 'secondary',
              icon: 'download',
              text: 'Exportar',
              onClick: this.downloadReport,
            }
          ]}
        />
        <FormContainer>
        <TabsContainer defaultActiveKey={defaultTab}>
            <Tab
              tab='Detalhes'
              key='details'
            >
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row>
              <Row gutter={24}>
                <Col xs={12} offset={0}>
                  <TextInput
                    type='text'
                    label={'Tipo'}
                    meta={{}}
                    input={{ value: getChapterType(chapter.type), onChange: () => null }}
                    disabled={true}
                  />
                </Col>
                {chapter.type === 10 && (
                  <Col xs={12}>
                    <Field
                      component={CheckboxInput}
                      name={'required'}
                      label={'Obrigatório responder para avançar na sessão'}
                    />
                  </Col>
                )}
              </Row>
              <Separator/>
              <Row gutter={24}>
                <Col xs={12} offset={0}>
                  <DefaultLanguageTab>
                    {GetTranslationDescription(activeLanguage?.code)}
                  </DefaultLanguageTab>
                  <Field
                    component={TextInput}
                    name={`title.${activeLanguage?.code}`}
                    type='text'
                    label={'Título *'}
                    placeholder={'Título'}
                  />
                  <Field
                    component={DraftInput}
                    name={`description.${activeLanguage?.code}`}
                    label={'Descrição'}
                  />
                </Col>
                <Col xs={12} offset={0}>
                  <Tabs>
                    {languages
                      .filter((x) => !x.active)
                      .map((language) => (
                        <TabPane
                          tab={GetTranslationDescription(language.code)}
                          key={language.code}
                        >
                          <Field
                            component={TextInput}
                            name={`title.${language.code}`}
                            type='text'
                            label={'Título *'}
                            placeholder={'Título'}
                          />
                          <Field
                            component={DraftInput}
                            name={`description.${language.code}`}
                            label={'Descrição'}
                          />
                        </TabPane>
                      ))}
                  </Tabs>
                </Col>
              </Row>
            </Row>
          </BaseForm>
          </Tab>
            <Tab
              tab='Cenas'
              key='steps'
            >
              <StepsList list={stepsList}/>
            </Tab>
          </TabsContainer>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageChaptersPage = reduxForm({
  form: 'manage_chapters_form',
  validate: validations,
})(ManageChaptersPage);


const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageChaptersPage));