import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetMotto,
  UpdateMotto,
  CreateMotto,
} from '../../../infra/requests/AboutRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab
} from '../../../styles/BasicStyles';
import FormValidator from '../../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../../components/base/ManageFormHeader';
import TextInput from '../../../components/generic/inputs/TextInput';
import { GetTranslationDescription } from '../../../infra/services/translations/AvailableTranslations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../../redux/Menu/menu.actions';
const TabPane = Tabs.TabPane;

const id = '604b7460bf4da172c732689c';

const validations = FormValidator.make({
  title: 'required|languages'
});

class ManageMottosList extends Component {
  state = { 
    isNew: false, 
    loading: true
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu,
      user,
      history
    } = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 

    CloseMenu();

    if(params.id) {
      const result = await GetMotto(id, params.id);
      dispatch(
        initialize('manage_motto_form', {
          ...result.data,
        })
      );

      this.setState({ 
        loading: false,
        caseInfo: result?.data
      });
    } 
    else {
      this.setState({
        isNew: true,
        loading: false
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;

      const { success } = params.id ? await UpdateMotto(id, params.id, values) : await CreateMotto(id, values);

      if(success) return history.push('/website/about/programs/mottos?=mottos=true');
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/website/about/programs/mottos?mottos=true');
  };

  render() {
    const { handleSubmit, pristine, activeLanguage, languages } = this.props;
    const { isNew, loading } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Sobre', 'Programas SMS', 'Lemas', title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel
            }
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24}>
              <Col xs={12} offset={0}>
                <DefaultLanguageTab>
                  {GetTranslationDescription(activeLanguage?.code)}
                </DefaultLanguageTab>
                <Field
                  component={TextInput}
                  name={`title.${activeLanguage?.code}`}
                  type='text'
                  label={'Título *'}
                  placeholder={'Título'}
                />
              </Col>
              <Col xs={12} offset={0}>
                <Tabs>
                  {languages
                    .filter((x) => !x.active)
                    .map((language) => (
                      <TabPane
                        tab={GetTranslationDescription(language.code)}
                        key={language.code}
                      >
                        <Field
                          component={TextInput}
                          name={`title.${language.code}`}
                          type='text'
                          label={'Título *'}
                          placeholder={'Título'}
                        />
                      </TabPane>
                    ))}
                </Tabs>
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageMottosList = reduxForm({
  form: 'manage_motto_form',
  validate: validations,
})(ManageMottosList);

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageMottosList));