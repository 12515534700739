import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import ReactPlayer from 'react-player';
import ReactAudioPlayer from 'react-audio-player';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { 
	DraftText, 
	ItemBox,
	ItemImage,
	ItemVideo,
	OptionButton, 
	BlocksContainer 
} from './BlockComponentStyles';
import { Row, Col, Icon, Popconfirm, notification } from 'antd';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import { UpdateBlocksOrder } from '../../../infra/requests/AboutInstrumentsRequests';

let BlockComponent = ({items, updateDataTable, toggleActive, removeBlock, editBlock}) => {

	const onDragEnd = async (data) => {
		if(data.destination && data.source.index !== data.destination.index) {
			let new_order = [...items];
			const [removed] = new_order.splice(data.source.index, 1);
			new_order.splice(data.destination.index, 0, removed);
			
			if(new_order && new_order.length) {
        let orderedList = [];
        new_order.forEach((item, index) => {
          orderedList.push({_id: item._id, order: index});
				});
				
				if(orderedList.length > 0) {
          const res = await UpdateBlocksOrder({ order: orderedList });

          if(res.success) {
            updateDataTable();

						notification.success({
							message: "Alterações guardadas com sucesso!"
						});
          }
        }
      }
    }
	}	

	return(
		<BlocksContainer>
			<DragDropContext onDragEnd={data => onDragEnd(data)}>
				<Droppable droppableId="blocks">
					{
						provided => 
						<div {...provided.props} ref={provided.innerRef}>
							{
								items.map((item, index) =>
								<Draggable
									key={index}
									draggableId={`blocks_${index}`}
									index={index}
								>
									{
										provided =>
										<div ref={provided.innerRef} {...provided.draggableProps}>
											{provided.placeholder}
											<ItemBox>
												<Row gutter={[24]}>
													<Col xs={4}/>
													<Col xs={16}>
														{
															item.type === 1 &&
															<DraftText
																dangerouslySetInnerHTML={{
																__html: TranslateValue(item.description)
																}}
															/>
														}
														{
															item.type === 2 &&
															<ItemImage>
																<img src={item.image.url} alt=''/>
															</ItemImage>
														}
														{
															item.type === 3 &&
															<ItemVideo>
																<ReactPlayer controls width='100%' height='300px' url={item.video}/>
															</ItemVideo>
														}
														{
															item.type === 4 &&
															<ReactAudioPlayer src={`${process.env.REACT_APP_IMAGES_URL}/${item.file._id}`} controls/>
														}
													</Col>
													<Col xs={4}/> 
												</Row>
												<React.Fragment>
													<OptionButton number={0}>
														<Popconfirm placement="topRight" 
															title='Tem a certeza que quer remover o Bloco?'
															onConfirm={() => removeBlock(item)}>
															<Icon type="delete"/> Eliminar
														</Popconfirm>
													</OptionButton>
													<OptionButton number={1} onClick={() => editBlock(item)}>
														<Icon type="edit" /> Editar
													</OptionButton>
													<OptionButton number={2}>
														<Popconfirm
															placement='topRight'
															title={`Quer ${
																item.active ? 'desativar' : 'publicar'
															} este Bloco?`}
															onConfirm={() => toggleActive(item)}
														>
															<Icon type={item.active ? 'close-circle' : 'check-circle'} /> { item.active ? 'Desativar' : 'Publicar' }
														</Popconfirm>
													</OptionButton>
													<OptionButton number={3} {...provided.dragHandleProps}>
														<Icon type="column-height" /> Alterar Ordem
													</OptionButton>
												</React.Fragment>
											</ItemBox>
										</div>
									}
								</Draggable>
							)}
						</div>
					}
				</Droppable>
			</DragDropContext>
		</BlocksContainer>
	)
}

const selector = formValueSelector('manage_order_blocks_form');

BlockComponent = reduxForm({
  form: 'manage_order_blocks_form'
})(BlockComponent);

const mapStateToProps = state => ({
  blocks: selector(state, 'blocks')
});

export default connect(mapStateToProps)(BlockComponent);