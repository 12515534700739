import client from '../config/AxiosConfig';

export const GetForms = async () => await client.get(`/forms`);

export const GetForm = async (id) => await client.get(`/forms/${id}`);

export const GetFormsByUser = async (idUser) => await client.get(`forms_availability/user/${idUser}`);

export const ToggleAnswered = async (id, data) => await client.put(`/forms_availability/${id}/toggle`, data);

export const CreateForm = async ( data) => await client.post('/forms', data);

export const UpdateForm = async (id, data) => await client.put(`/forms/${id}`, data);

export const UpdateDateStudent= async (idForm, idStudent, data) => await client.put(`/forms_availability/${idForm}/student/${idStudent}`, data);

export const UpdateDateClass = async (idForm, idClass, data) => await client.put(`/forms_availability/${idForm}/class/${idClass}`, data);

export const RemoveForm = async (id) => await client.delete(`/forms/${id}`);