import client from '../config/AxiosConfig';
import { auth_token_key } from '../../infra/config/LocalStorageKeys';
const authToken = localStorage.getItem(auth_token_key);

export const GetMind = async (id) => await client.get(`/mind/${id}`);

export const UpdateMind = async (id, data) => await client.put(`/mind/${id}`, data);

export const GetFormMind = async (id) => await client.get(`/mind_forms/${id}`);

export const UpdateFormMind = async (id, data) => await client.put(`/mind_forms/${id}`, data);

export const GetAnswersByForm = async (id) => await client.get(`/mind_form_answers/${id}`);

export const GetDashboardByForm = async (id) => await client.get(`/dashboards/form/${id}`);

export const DownloadMindForm = data => { 
    return new Promise((accept, reject) => {
      fetch(`${process.env.REACT_APP_API}/reports/form`, { 
        method: 'POST', 
        body: JSON.stringify(data), 
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` } 
      })
      .then(response => {
        return response.blob().then(blob => {
          return {
            headers: response.headers,
            blob
          }
        })
      })
      .then(data => accept({ headers: data.headers, blob: data.blob }))
      .catch(error => reject(error))
    });
  }
  