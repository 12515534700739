import styled from 'styled-components';

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-right: 40px;
`;

export const Image = styled.img`
  max-width: 50px;
`;