import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { InputLabelDiv, SunDraftWrapper } from './InputStyles';
import sanitizeHtml from 'sanitize-html';
import pluginGallery from '../sunEditorGallery/Plugin';

const emptyDraft = /^<p>(<br>|<br\/>)*<\/p>/;

const sanitizeOptions = {
  allowedTags: [
    'p',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'strong',
    'del',
    'em',
    'u',
    'sub',
    'sup',
    'ul',
    'li',
    'ol',
    'span',
    'a',
    'hr',
    'img',
    'div',
    'figure'
  ],
  allowedAttributes: {
    p: ['style'],
    h1: ['style'],
    h2: ['style'],
    h3: ['style'],
    h4: ['style'],
    h5: ['style'],
    h6: ['style'],
    strong: ['style'],
    del: ['style'],
    em: ['style'],
    u: ['style'],
    sub: ['style'],
    sup: ['style'],
    ul: ['style'],
    li: ['style'],
    ol: ['style'],
    span: ['style'],
    a: ['href', 'target', 'style'],
    hr: ['class'],
    img: ['src', 'style', 'alt', 'data-proportion', 'data-align', 'data-index', 'data-file-name', 'data-file-size', 'data-origin', 'data-size']
  },
  allowedStyles: {
    '*': {
      'text-align': [/^left$/, /^right$/, /^center$/, /^justify$/],
      'font-size': [/^\d+(?:px|em|%)$/],
      'line-height': [/^\d+(?:px|em|%)*$/],
      'color': [/rgb\((\d{1,3}), *(\d{1,3}), *(\d{1,3})\)/],
      'background-color': [/rgb\((\d{1,3}), *(\d{1,3}), *(\d{1,3})\)/],
      'margin-left': [/^\d+(?:px|em|%)$/],
      'margin-right': [/^\d+(?:px|em|%)$/],
      'margin-top': [/^\d+(?:px|em|%)$/],
      'margin-bottom': [/^\d+(?:px|em|%)$/],
      'width': [/^\d+(?:px|em|%)$/],
      'height': [/^\d+(?:px|em|%)$/]
    }
  },
  allowedSchemes: ['http', 'https', 'data']
};

const toolbars = {
  basic: [
    ['undo', 'redo'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['link'],
    ['fullScreen'],
    ['removeFormat']
  ],
  full: [
    ['undo', 'redo'],
    ['fontSize', 'formatBlock'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['align', 'list', 'lineHeight'],
    ['link', 'image', {
      name: 'pluginGallery', 
      dataDisplay:'dialog',
      title:'Image Gallery', 
      buttonClass:'', 
      innerHTML:'<svg viewBox="30 30 150 150" style="width:16px;height:16px;"><g><path fill="currentColor" d="M152.775,120.548V51.651c0-12.271-9.984-22.254-22.254-22.254H43.727c-12.271,0-22.254,9.983-22.254,22.254v68.896c0,12.27,9.983,22.254,22.254,22.254h86.795C142.791,142.802,152.775,132.817,152.775,120.548z M36.394,51.651c0-4.042,3.291-7.333,7.333-7.333h86.795c4.042,0,7.332,3.291,7.332,7.333v23.917l-14.938-17.767c-1.41-1.678-3.487-2.649-5.68-2.658h-0.029c-2.184,0-4.255,0.954-5.674,2.613L76.709,98.519l-9.096-9.398c-1.427-1.474-3.392-2.291-5.448-2.273c-2.052,0.025-4.004,0.893-5.396,2.4L36.394,111.32V51.651z M41.684,127.585l20.697-22.416l9.312,9.622c1.461,1.511,3.489,2.334,5.592,2.27c2.101-0.066,4.075-1.013,5.44-2.612l34.436-40.308l20.693,24.613v21.794c0,4.042-3.29,7.332-7.332,7.332H43.727C43.018,127.88,42.334,127.775,41.684,127.585z M182.616,152.5V75.657c0-4.12-3.34-7.46-7.461-7.46c-4.119,0-7.46,3.34-7.46,7.46V152.5c0,4.112-3.347,7.46-7.461,7.46h-94c-4.119,0-7.46,3.339-7.46,7.459c0,4.123,3.341,7.462,7.46,7.462h94C172.576,174.881,182.616,164.841,182.616,152.5z"></path></g></svg>'
    }],
    ['fullScreen'],
    ['removeFormat']
  ]
}

const DraftInput = ({ label, placeholder, disabled, toolbar, input, meta }) => {
  const inputEditor = useRef(null);

  const {invalid, submitFailed} = meta;
  const showError = invalid && submitFailed ? 1 : 0;

  const handleChange = async (content) => {
    if(content) {
      const cleanHTML = sanitizeHtml(content, sanitizeOptions);
      input.onChange(emptyDraft.test(cleanHTML) ? '' : cleanHTML);
    }
  }

  return (
    <SunDraftWrapper error={showError}>
      {label && <InputLabelDiv>{label}</InputLabelDiv>}
      <SunEditor
        ref={inputEditor}
        placeholder={placeholder}
        disable={disabled}
        setContents={input?.value}
        onChange={handleChange}
        setOptions={{
          mode: 'classic', // 'classic', 'inline', 'balloon', 'balloon-always'
          height: 'auto',
          minHeight: 150,
          maxHeight: 600,
          customPlugins: [pluginGallery],
          buttonList: toolbars[toolbar],
          formats: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
          imageAccept: '.jpg, .png, .gif',
          imageUploadUrl: `${process.env.REACT_APP_API}/sun_editor_images/save`,
          imageCustomGalleryUrl: `${process.env.REACT_APP_API}/sun_editor_images`
        }}
      />
    </SunDraftWrapper>
  );
}

DraftInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  toolbar: PropTypes.oneOf(['basic', 'full']),
  setLoadingImage: PropTypes.func
};

DraftInput.defaultProps = {
  toolbar: 'basic'
};

export default DraftInput;