import React, { Component } from 'react';
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form';
import { Tabs, Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetCategory,
  UpdateCategory,
  CreateCategory,
} from '../../infra/requests/AboutThemesRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/generic/inputs/TextInput';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  name: 'required|languages',
  color: 'required'
});

class ManageCategoriesPage extends Component {
  state = { 
    isNew: false, 
    loading: true
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu,
      user, 
      history
    } = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 

    CloseMenu();

    if(params.id) {
      const result = await GetCategory(params.id);
      dispatch(
        initialize('manage_categories_form', {
          ...result.data,
        })
      );

      this.setState({ 
        loading: false
      });
    } 
    else {
      this.setState({
        isNew: true,
        loading: false
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;

      const payload = FlattenToFormData(values);
      const { success } = params.id ? await UpdateCategory(params.id, payload) : await CreateCategory(payload);

      if(success) return history.push('/website/about/themes/categories');
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/website/about/themes/categories');
  };

  render() {
    const { handleSubmit, pristine, activeLanguage, languages, color } = this.props;
    const { isNew, loading } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Sobre', 'Temas', 'Categorias', title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel
            }
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24}>
              <Col xs={12} offset={0}>
                <DefaultLanguageTab>
                  {GetTranslationDescription(activeLanguage?.code)}
                </DefaultLanguageTab>
                <Field
                  component={TextInput}
                  name={`name.${activeLanguage?.code}`}
                  type='text'
                  label={'Nome *'}
                  placeholder={'Nome'}
                />
              </Col>
              <Col xs={12} offset={0}>
                <Tabs>
                  {languages
                    .filter((x) => !x.active)
                    .map((language) => (
                      <TabPane
                        tab={GetTranslationDescription(language.code)}
                        key={language.code}
                      >
                        <Field
                          component={TextInput}
                          name={`name.${language.code}`}
                          type='text'
                          label={'Nome *'}
                          placeholder={'Nome'}
                        />
                      </TabPane>
                    ))}
                </Tabs>
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '20px' }}>
              <Col xs={12} offset={0}>
                <Field
                  component={TextInput}
                  name='color'
                  type='text'
                  label={'Cor *'}
                  placeholder={'Cor'}
                  notesText='Formatos permitidos: HEX (#000000) ou RGB(0,0,0)'
                />
              </Col>
              {
                color &&
                <Col xs={12} offset={0}>
                  <div style={{ marginTop: '35px', width: '30px', height: '30px', backgroundColor: color }}>&nbsp;</div>
                </Col>
              }
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageCategoriesPage = reduxForm({
  form: 'manage_categories_form',
  validate: validations,
})(ManageCategoriesPage);

const selector = formValueSelector('manage_categories_form');

const mapStateToProps = state => ({
  color: selector(state, 'color'),
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageCategoriesPage));