import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { 
  StyledHeader, 
  StyledLogoContainer,
  StyledLogo,
  StyleHeaderLine,
  StyleUserContainer,
  StyleUserContent,
  UserLogout,
  StyledLogoContent,
  StyledWebsiteContent,
  StyledWebsite,
  StyledButton
} from './LayoutStyles';
import { Icon } from 'antd';
import { getUserType } from '../../../infra/utils/UserTypes';
import Logo from "../../../assets/icons/logo.svg";

const HeaderComponent = ({ history, location, user, logOut }) => {
  const renderName = () => {
    let name = '';

    if(user?.name) {
      if(user.name.includes(' ')) {
        const parts = user.name.split(' ');
        name = `${parts[0]} ${parts[parts.length - 1]}`;
      }
      else {
        name = user.name;
      }
    }

    return name;
  }

  //Private Area
  if(location.pathname.indexOf('/website') === -1) {
    return (
      <StyledHeader className='no-print'>
        <StyledLogoContainer>
          <StyledLogoContent>
            <StyledLogo src={Logo}/>
            <StyledWebsiteContent>
              <StyledWebsite>Área Reservada</StyledWebsite>
              {user?.type !== 2 && (
                <StyledButton onClick={() => history.push('/website')}>
                  <Icon type="global"/>
                  Go To Website
                </StyledButton>
              )}
            </StyledWebsiteContent>
          </StyledLogoContent>
        </StyledLogoContainer>
        <StyleUserContainer>
          <StyleUserContent>
            <StyleHeaderLine>Bem vindo, { renderName() }</StyleHeaderLine>
            <StyleHeaderLine small={true}>{ getUserType(user?.type) }</StyleHeaderLine>
          </StyleUserContent>
          <UserLogout title='Logout' onClick={logOut}>
            <Icon type={'logout'} />
          </UserLogout>
        </StyleUserContainer>
      </StyledHeader>
    );
  }

  //Website
  return (
    <StyledHeader className='no-print'>
      <StyledLogoContainer>
        <StyledLogoContent>
          <StyledLogo src={Logo}/>
          <StyledWebsiteContent>
            <StyledWebsite>Website</StyledWebsite>
            {user?.type !== 2 && (
              <StyledButton onClick={() => history.push('/')}>
                <Icon type="team"/>
                Go To Private Area
              </StyledButton>
            )}
          </StyledWebsiteContent>
        </StyledLogoContent>
      </StyledLogoContainer>
      <StyleUserContainer>
        <StyleUserContent>
          <StyleHeaderLine>Bem vindo, { renderName() }</StyleHeaderLine>
          <StyleHeaderLine small={true}>{ getUserType(user?.type) }</StyleHeaderLine>
        </StyleUserContent>
        <UserLogout title='Logout' onClick={logOut}>
          <Icon type={'logout'} />
        </UserLogout>
      </StyleUserContainer>
    </StyledHeader>
  );
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, null)(withRouter(HeaderComponent));