import React, { Component } from 'react';
import { Popconfirm, Icon } from 'antd';
import Table from '../../../components/generic/table/Table';
import { withRouter } from 'react-router-dom';
import {
  GetClass,
  DeleteClassStudent
} from '../../../infra/requests/SchoolsRequests';
import {
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton,
} from '../../../styles/BasicStyles';
import BaseButton from '../../../components/generic/buttons/BaseButton';

class StudentsList extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: true,
    columns: [
      {
        title: 'Nome',
        key: 'name',
        render: (data) => data?.name,
        width: '45%'
      },
      {
        title: 'Email',
        key: 'email',
        render: (data) => data?.email,
        width: '30%'
      },
      {
        title: '',
        render: (data) => (
          <React.Fragment>
            <TableButton error onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement='topRight'
                title='Tem a certeza que quer remover o Aluno?'
                onConfirm={() => this.removeStudent(data)}
              >
                <Icon style={{ marginRight: 10 }} type='delete' />
                {'Remover'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        )
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  updateDataTable = async () => {
    try {
      this.setState({ loading: true });
      
      const { classID } = this.props;
      const result = await GetClass(classID);

      this.setState({
        rows: result?.data?.students || [],
        total: result?.data?.students?.length || 0,
        loading: false
      });
    } 
    catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  }

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/users/${record._id}`);
  };

  removeStudent = async (data) => {
    try {
      const { classID } = this.props;
      await DeleteClassStudent(classID, data._id);
      await this.updateDataTable();
    } 
    catch (e) {
      console.error(e);
    }
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
    } = this.state;
    const { history, classID } = this.props;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}></HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Adicionar'}
              onClick={() => history.push(`/classes/${classID}/students/add`)}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <Table
          columns={columns}
          currentPage={currentPage}
          pageSize={pageSize}
          loading={loading}
          rows={rows}
          showHeader={true}
          emptyIcon='warning'
          emptyText={'Não existem Alunos inseridos!'}
          total={total}
          rowKey={'_id'}
          hasPagination={false}
          onPressRow={this.handleOnPressRow}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(StudentsList);