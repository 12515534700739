import styled from 'styled-components';

export const InfoTitle = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
`;

export const InfoValue = styled.div`
  margin-bottom: 5px;

  & span {
    font-weight: bold;
  }
`;

export const InfoWarning = styled.div`
  font-size: 17px;
  margin-top: 15px;
  color: ${({ error }) => error ? 'red' : 'rgba(0, 0, 0, 0.65)'};
`;