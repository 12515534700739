import React, { Component } from 'react';
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form';
import { Tabs, Row, Col, notification } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetSession,
  ImportChapter,
  UpdateSession
} from '../../infra/requests/ProgramsRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab,
  TabsContainer,
  Tab
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import TextInput from '../../components/generic/inputs/TextInput';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import ChaptersList from './components/ChaptersList';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import DraftInput from '../../components/generic/inputs/DraftInput';
import TextAreaInput from '../../components/generic/inputs/TextAreaInput';
import BaseButton from '../../components/generic/buttons/BaseButton';
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  title: 'required|languages'
});

class ManageSessionsPage extends Component {
  state = { 
    loading: true,
    chaptersList: [],
    program: undefined,
    session: undefined,
    defaultTab: 'details'
  };

  componentDidMount = async () => {
    const {user, history} = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 
      
    await this.loadDetail();
  };

  loadDetail = async () => {
    const {
      match: { params },
      history,
      dispatch,
      CloseMenu
    } = this.props;
    
    CloseMenu();

    const result = await GetSession(params.id);

    dispatch(
      initialize('manage_sessions_form', {
        ...result.data,
      })
    );

    let defaultTab = 'details';
    if(history?.location?.search?.includes('chapters=true')) {
      defaultTab = 'chapters';
      history.replace(`/programs/sessions/${params.id}`);
    } 

    this.setState({ 
      loading: false,
      chaptersList: result?.data?.chapters || [],
      program: result?.data?.program,
      session: result?.data,
      defaultTab
    });
  }

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const { match: { params } } = this.props;

      const { success } = await UpdateSession(params.id, values);

      if(success) {
        notification.success({
          message: "Sucesso!",
          description: "Alterações guardadas com sucesso."
        });
        return await this.loadDetail();
      }

      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    const { program } = this.state;

    return history.push(`/programs/${program._id}?sessions=true`);
  };


  onImport = async () => {
    const { importData } = this.props;
    try {
      this.setState({ loading: true });
      const { match: { params } } = this.props;
      console.log(JSON.parse(importData))
      const { data, success } = await ImportChapter(params.id, JSON.parse(importData));

      if(success) {
        notification.success({
          message: "Sucesso!",
          description: "Alterações guardadas com sucesso."
        });
        console.log('result', data)
      }

      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  }

  render() {
    const { handleSubmit, pristine, activeLanguage, languages, user } = this.props;
    const { loading, chaptersList, program, session, defaultTab } = this.state;

    if(loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Programas', TranslateValue(program?.title), TranslateValue(session?.title), 'Editar']}
          titlePosition={2}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <TabsContainer defaultActiveKey={defaultTab}>
            <Tab
              tab='Detalhes'
              key='details'
            >
              <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
                <Row>
                  <Row gutter={24}>
                    <Col xs={12} offset={0}>
                      <DefaultLanguageTab>
                        {GetTranslationDescription(activeLanguage?.code)}
                      </DefaultLanguageTab>
                      <Field
                        component={TextInput}
                        name={`title.${activeLanguage?.code}`}
                        type='text'
                        label={'Título *'}
                        placeholder={'Título'}
                      />
                      <Field
                        component={DraftInput}
                        name={`description.${activeLanguage?.code}`}
                        label={'Descrição'}
                      />
                    </Col>
                    <Col xs={12} offset={0}>
                      <Tabs>
                        {languages
                          .filter((x) => !x.active)
                          .map((language) => (
                            <TabPane
                              tab={GetTranslationDescription(language.code)}
                              key={language.code}
                            >
                              <Field
                                component={TextInput}
                                name={`title.${language.code}`}
                                type='text'
                                label={'Título *'}
                                placeholder={'Título'}
                              />
                              <Field
                                component={DraftInput}
                                name={`description.${language.code}`}
                                label={'Descrição'}
                              />
                            </TabPane>
                          ))}
                      </Tabs>
                    </Col>
                  </Row>
                  {user.isCodepointAdmin && (
                    <Row gutter={24}>
                      <Col xs={12} offset={0}>
                        <Field
                          component={TextAreaInput}
                          name='data'
                          type='text'
                          label={'Import'}
                          placeholder={'Import data'}
                        />
                        <BaseButton
                          type={"default"}
                          icon={"save"}
                          text={"Importar"}
                          onClick={this.onImport}
                        />
                      </Col>
                    </Row>
                  )}
                </Row>
              </BaseForm>
            </Tab>
            <Tab
              tab='Capítulos'
              key='chapters'
            >
              <ChaptersList list={chaptersList}/>
            </Tab>
          </TabsContainer>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageSessionsPage = reduxForm({
  form: 'manage_sessions_form',
  validate: validations,
})(ManageSessionsPage);

const selector = formValueSelector('manage_sessions_form');

const mapStateToProps = (state) => ({
  importData: selector(state, 'data'),
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageSessionsPage));