import React, { Component } from 'react';
import { Popconfirm, Icon } from 'antd';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton
} from '../../styles/BasicStyles';
import {
  GetThemes,
  DeleteTheme,
  ToggleThemes,
  UpdateThemesOrder,
  GetCategoriesList
} from '../../infra/requests/AboutThemesRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import ThemesFilters from './ThemesFilters';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import OrderThemes from './OrderThemes';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';

class ThemesPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Título',
        dataIndex: 'title',
        render: (value) => TranslateValue(value),
        width: '50%',
      },
      {
        title: 'Categoria',
        dataIndex: 'category',
        render: (value) => TranslateValue(value?.name),
        width: '20%',
      },
      {
        title: 'Ações',
        render: (data) => (
          <React.Fragment>
            <TableButton onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement='topRight'
                title={`Quer ${
                  data.active ? 'desativar' : 'publicar'
                } este Tema?`}
                onConfirm={() => this.toggleActive(data)}
              >
                <Icon type={data.active ? 'close-circle' : 'check-circle'} />
                {data.active ? ' Desativar' : ' Publicar'}
              </Popconfirm>
            </TableButton>
            <TableButton error onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement='topRight'
                title='Tem a certeza que quer remover o Tema?'
                onConfirm={() => this.removeTheme(data)}
              >
                <Icon style={{ marginRight: 10 }} type='delete' />
                {'Remover'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        ),
      },
    ],
    rows: [],
    total: 0,
    orderModal: false
  };

  componentDidMount = async () => {
    const { user, history } = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 
    
    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/website/about/themes/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  removeTheme = async (data) => {
    try {
      await DeleteTheme(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetThemes(currentPage, pageSize, filters);
      const categories = await GetCategoriesList();

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
        categories: categories?.data || []
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  toggleActive = async (record) => {
    this.setState({ loading: true });
    await ToggleThemes(record._id, { active: !record.active });
    await this.updateDataTable();
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };


  closeOrderModal = () => {
    this.setState({ orderModal: false });
  };

  openOrderModal = (themes) => {
    const { dispatch } = this.props;
    dispatch(
      initialize(
        'manage_order_themes_form',
        { themes: [...themes] },
        false
      )
    );

    this.setState({ orderModal: true });
  };

  onSubmitOrder = async (values) => {
    try {
      this.setState({ loading: true });

      if (values.themes.length > 0) {
        let orderedList = [];
        Object.keys(values.themes).forEach(function (key) {
          let obj = { _id: values.themes[key]._id, order: key };
          orderedList.push(obj);
        });

        if (orderedList.length > 0) {
          const res = await UpdateThemesOrder({ order: orderedList });

          if (res.success) {
            this.updateDataTable();
          }
        }
      }

      this.setState({ loading: false });
      this.closeOrderModal();
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
      orderModal,
      categories
    } = this.state;
    const { history } = this.props;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Lista de Temas</PageTitle>
            {`Sobre > Temas`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/website/about/themes/add')}
            />
            <BaseButton
              type='default'
              icon='retweet'
              text='Editar Ordem'
              onClick={() => this.openOrderModal(rows)}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <ThemesFilters queryChange={this.handleFilterChange} categories={categories}/>
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Temas inseridos!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
        <OrderThemes
          open={orderModal}
          loading={loading}
          onSubmit={this.onSubmitOrder}
          closeModal={this.closeOrderModal}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(ThemesPage);
