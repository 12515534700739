import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import { Row, Col, Tabs, notification } from 'antd';
import TextInput from '../../components/generic/inputs/TextInput';
import { 
  FormContainer, 
  BaseForm, 
  SpinLoading,
  DefaultLanguageTab,
  TabsContainer,
  Tab
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import { SaveUser } from '../../redux/User/user.actions';
import { GetExtraSession, UpdateExtraSession } from '../../infra/requests/ExtraSessionRequests';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import DraftInput from '../../components/generic/inputs/DraftInput';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import { GetClass, GetClasses } from '../../infra/requests/SchoolsRequests';
import { GetApplicatorParentsList, GetParentsList } from '../../infra/requests/UsersRequests';
import Table from '../../components/generic/table/Table';
import { Title } from '../forms/FormsStyles';
import BlocksPage from './components/BlocksPage';
import BaseButton from '../../components/generic/buttons/BaseButton';
import AddDateStudent from './components/AddDateStudent';
import ManageAvailabilityDate from './components/ManageAvailabilityDate';
import { TextTitle } from '../users/UserStyles';
import { getFormUserType } from '../../infra/utils/FormUserTypeTypes';

const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  name: 'required',
  email: 'required|email'
});

class ManageExtraSessionPage extends Component {
  state = {
    loading: true,
    pageSize: 30,
    currentPage: 1,
    columns: [],
    rows: [],
    total: 0,
    filters: '',
    columnsUser: [],
    rowsUser: [],
    defaultTab: 'details',
    expandedRowKeys: '',
    openModal: false,
    classId: '',
    className: '',
    showStudentTable: false,
    showParentTable: false,
  };

  componentDidMount = async () => {
    await this.loadDetail();
  };

  loadDetail = async () => {
    const { match: { params }, dispatch, CloseMenu, history } = this.props;

    CloseMenu();

    const result = await GetExtraSession(params.id);

    if(result?.data?.user_type === 4) {
      this.dataTableStudent();
    } else if(result?.data?.user_type === 3) {
      this.dataTableParent();
    }

    dispatch(
      initialize('manage_extra_session_form', {
        ...result.data,
      })
    );

    this.setState({ 
      defaultTab: history?.location?.search?.includes('blocks=true') ? 'blocks' : 'details',
      showStudentTable: result?.data?.user_type === 4 ? true : false,
        showParentTable: result?.data?.user_type === 3 ? true : false,
      info: result?.data,
      loading: false 
    });
  }

  dataTableStudent = async () => {
    const {match: { params }} = this.props;
    let { currentPage, pageSize, filters } = this.state;

    const classes = await GetClasses(currentPage, pageSize, filters);
    
    this.setState({
      columns: [
        {
          title: 'Nome',
          dataIndex: 'name',
          width: '33%'
        },
        {
          title: 'Escola',
          dataIndex: 'school',
          render: (value) => value?.name,
          width: '33%'
        },
        {
          title: 'Data de Disponibilidade',
          render: (data) =>  (
            <React.Fragment>
              <BaseButton 
                type={'default'}
                icon={'calendar'}
                text={'Definir Data'}
                onClick={() => this.setState({openModal: true, classId: data._id, className: data.name})}
              />
            </React.Fragment>
          ),
        },
      ],
      columnsUser: [
        {
          title: 'Nome',
          dataIndex: 'name'
        },
        {
          title: 'Data de Disponibilidade',
          render: (data) =>  {
            let availability_date;
            if(data?.extra_session_availability.length > 0) {
              availability_date = data.extra_session_availability.find(x => x.extra_session === params.id)?.availability_date;
            }
            return (
              <AddDateStudent 
                availability_date={availability_date}
                student={data}
                sessionId={params.id}
              />
          )},
          width: '32%'
        },
      ]
    })

    this.setState({
      rows: classes?.data?.items || [],
      total: classes?.data?.total || 0, 
    })
  }

  dataTableParent = async() => {
    const { match: { params }, user } = this.props;

    let parents = [];

    if(user.type === 2) {
      parents = await GetApplicatorParentsList();
    } else {
      parents = await GetParentsList();
    }

    this.setState({
      columns: [
        {
          title: 'Nome',
          dataIndex: 'name'
        },
        {
          title: 'Data de Disponibilidade',
          render: (data) =>  {
            let availability_date;
            if(data?.extra_session_availability?.length > 0) {
              availability_date = data.extra_session_availability.find(x => x.extra_session === params.id)?.availability_date;
            }
            return (
              <AddDateStudent 
                availability_date={availability_date}
                student={data}
                sessionId={params.id}
              />
          )},
          width: '32%'
        },
      ]
    })
    this.setState({
      rows: parents?.data || []
    })
  }

  onSubmit = async (values) => {
    try {
      const { dispatch, match: { params } } = this.props;

      this.setState({ loading: true });
      
      const payload = FlattenToFormData(values);
      const { data, success } = await UpdateExtraSession(params.id, payload);

      if(success) {
        dispatch(
          initialize('manage_banner_form', {
            ...data,
          })
        );

        notification.success({
          message: "Registo alterado com sucesso!"
        });
      }
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/extra-session');
  };

  getClassStudents = async(exp, data) => {
    var keys = [];

    if(exp){
        keys.push(data._id); // I have set my record.id as row key.
    }

    const result = await GetClass(data._id); 

    this.setState({
      rowsUser: result?.data, 
      expandedRowKeys: keys
    });
  }

  closeModal = () => {
    this.setState({ openModal: false })
    window.location.reload(false);
  }

  renderExpandedRowRender = (data, index) => {
    const { rowsUser, columnsUser, loading } = this.state; 

    return(
      <Table
        columns={columnsUser}
        loading={loading}
        rows={rowsUser?.students || []}
        showHeader={true}
        emptyIcon='warning'
        emptyText={'Não existem Utilizadores inseridos!'}
        rowKey={'_id'}
        secondary={true}
      />
    )
  }

  render() {
    const { handleSubmit, pristine, activeLanguage, languages, match: { params }, user } = this.props;
    const { 
      loading,
      columns,
      rows,
      total,
      currentPage,
      pageSize,
      defaultTab, 
      info,
      expandedRowKeys,
      openModal,
      classId,
      className,
      showStudentTable,
      showParentTable,
    } = this.state;

    if(loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={[info?.title?.pt, 'Editar']}
          buttons={
            user.type === 2 ? [
              {
                type: 'default',
                icon: 'close',
                text: 'Cancelar',
                onClick: this.handleOnCancel,
              }
            ] : [
              {
                type: 'primary',
                icon: 'save',
                text: 'Gravar',
                onClick: handleSubmit((values) => this.onSubmit(values)),
                disabled: pristine,
              },
              {
                type: 'default',
                icon: 'close',
                text: 'Cancelar',
                onClick: this.handleOnCancel,
              }
            ]
          }
        />
        {user.type === 2 ? (
          <FormContainer>
            <Row gutter={24}>
              <Col xs={12}>
                <TextTitle>Tipo: <span>{getFormUserType(info?.user_type)}</span></TextTitle>
              </Col>
              <Col xs={12}>
                <TextTitle>Título: <span>{info?.title.pt}</span></TextTitle>
              </Col>
            </Row>
            {showStudentTable && (
              <Row gutter={24} style={{marginTop: 16}}>
                <Col xs={24} offset={0}>
                  <Title style={{marginBottom: 8}}>Data de Disponibilidade</Title>
                  <Table
                      columns={columns}
                      currentPage={currentPage}
                      pageSize={pageSize}
                      loading={loading}
                      rows={rows}
                      showHeader={true}
                      emptyIcon='warning'
                      emptyText={'Não existem Turmas inseridas!'}
                      total={total}
                      rowKey={'_id'}
                      hasPagination={false}
                      expandedRowRender={((data, index) => this.renderExpandedRowRender(data, index))}
                      expandedRowKeys={expandedRowKeys}
                      onExpand={(exp, data) => this.getClassStudents(exp, data)}
                    />
                </Col>
            </Row>
            )}
            {showParentTable && (
              <Row gutter={24} style={{marginTop: 16}}>
                <Col xs={24} offset={0}>
                  <Title style={{marginBottom: 8}}>Data de Disponibilidade</Title>
                  <Table
                    columns={columns}
                    loading={loading}
                    rows={rows}
                    showHeader={true}
                    hasPagination={false}
                    emptyIcon='warning'
                    emptyText={'Não existem Encarregados de Educação inseridas!'}
                    rowKey={'_id'}
                  />
                </Col>
              </Row>
            )}
          </FormContainer>
        ):(
          <FormContainer>
            <TabsContainer defaultActiveKey={defaultTab}>
              <Tab
                tab='Detalhes'
                key='details'
              >
                <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
                  <Row>
                    <Row gutter={24}>
                      <Col xs={12} offset={0}>
                        <DefaultLanguageTab>
                          {GetTranslationDescription(activeLanguage?.code)}
                        </DefaultLanguageTab>
                        <Field
                          component={TextInput}
                          name={`title.${activeLanguage?.code}`}
                          type='text'
                          label={'Título *'}
                          placeholder={'Título'}
                        />
                        <Field
                          component={DraftInput}
                          name={`description.${activeLanguage?.code}`}
                          label={'Descrição *'}
                          placeholder={'Descrição'}
                          toolbar='basic'
                        />
                      </Col>
                      <Col xs={12} offset={0}>
                        <Tabs>
                          {languages
                            .filter((x) => !x.active)
                            .map((language) => (
                              <TabPane
                                tab={GetTranslationDescription(language.code)}
                                key={language.code}
                              >
                                <Field
                                  component={TextInput}
                                  name={`title.${language.code}`}
                                  type='text'
                                  label={'Título *'}
                                  placeholder={'Título'}
                                />
                                <Field
                                  component={DraftInput}
                                  name={`description.${language.code}`}
                                  label={'Descrição *'}
                                  placeholder={'Descrição'}
                                  toolbar='basic'
                                />
                              </TabPane>
                          ))}
                        </Tabs>
                      </Col>
                    </Row>
                  </Row>
                  {showStudentTable && (
                    <Row gutter={24} style={{marginTop: 16}}>
                      <Col xs={24} offset={0}>
                        <Title style={{marginBottom: 8}}>Data de Disponibilidade</Title>
                        <Table
                            columns={columns}
                            currentPage={currentPage}
                            pageSize={pageSize}
                            loading={loading}
                            rows={rows}
                            showHeader={true}
                            emptyIcon='warning'
                            emptyText={'Não existem Turmas inseridas!'}
                            total={total}
                            rowKey={'_id'}
                            hasPagination={true}
                            expandedRowRender={((data, index) => this.renderExpandedRowRender(data, index))}
                            expandedRowKeys={expandedRowKeys}
                            onExpand={(exp, data) => this.getClassStudents(exp, data)}
                          />
                      </Col>
                  </Row>
                  )}
                  {showParentTable && (
                    <Row gutter={24} style={{marginTop: 16}}>
                      <Col xs={24} offset={0}>
                        <Title style={{marginBottom: 8}}>Data de Disponibilidade</Title>
                        <Table
                          columns={columns}
                          loading={loading}
                          rows={rows}
                          showHeader={true}
                          emptyIcon='warning'
                          emptyText={'Não existem Encarregados de Educação inseridas!'}
                          rowKey={'_id'}
                        />
                      </Col>
                    </Row>
                  )}
                </BaseForm>
              </Tab>
              <Tab
                tab='Blocos'
                key='blocks'
              >
                <BlocksPage
                  info={info}
                />
              </Tab>
            </TabsContainer>
          </FormContainer>
        )}
        <ManageAvailabilityDate 
          isOpen={openModal}
          classId={classId}
          className={className}
          sessionId={params.id}
          onClose={() => this.closeModal()}
        />
      </React.Fragment>
    );
  }
}

ManageExtraSessionPage = reduxForm({
  form: 'manage_extra_session_form',
  validate: validations,
})(ManageExtraSessionPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu, SaveUser }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageExtraSessionPage));