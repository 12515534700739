import React, { Component } from 'react';
import Table from '../../../components/generic/table/Table';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Popconfirm, Icon } from 'antd';
import {
  TableButton
} from '../../../styles/BasicStyles';
import {
  StateLabel
} from '../Styles';
import {
  GetThreadComments,
  DeleteComment,
  ValidateComment
} from '../../../infra/requests/ForumRequests';
import {
  GetUsersList
} from '../../../infra/requests/UsersRequests';
import CommentsFilters from './CommentsFilters';
import { connect } from 'react-redux';

class CommentsList extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Data de Criação',
        dataIndex: 'createdAt',
        render: (value) => `${moment.utc(value).format('DD-MM-YYYY, HH:mm')}h`,
        width: '15%'
      },
      {
        title: 'Autor',
        dataIndex: 'author',
        render: (value) => value?.name,
        width: '15%'
      },
      {
        title: 'Comentário',
        dataIndex: 'description',
        render: (value) => value.length > 100 ? `${value.substr(0, 100)}...` : value,
        width: '30%'
      },
      {
        title: 'Nº Respostas',
        key: 'total_comments_bo',
        render: (data) => data?.comments?.length || 0,
        width: '10%'
      },
      {
        title: 'Estado (Autor)',
        dataIndex: 'pendingUser',
        render: (value) => this.renderState(value),
        width: '10%'
      },
      {
        title: 'Estado (Admin)',
        dataIndex: 'pending',
        render: (value) => this.renderState(value),
        width: '10%'
      },
      {
        title: 'Ações',
        render: (data) => (
          <React.Fragment>
            {
              data.pending &&
              <TableButton onClick={(e) => e.stopPropagation()}>
                <Popconfirm
                  placement='topRight'
                  title='Tem a certeza que pretende validar este Comentário?'
                  onConfirm={() => this.validateComment(data)}
                >
                  <Icon style={{ marginRight: 5 }} type='close-circle'/>
                  Validar
                </Popconfirm>
              </TableButton>
            }
            <TableButton error onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement='topRight'
                title='Tem a certeza que quer remover este Comentário?'
                onConfirm={() => this.removeComment(data)}
              >
                <Icon style={{ marginRight: 10 }} type='delete' />
                {'Remover'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        ),
      }
    ],
    rows: [],
    total: 0,
    usersList: []
  };

  componentDidMount = async () => {
    const {user, history} = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 
    
     await this.updateDataTable();
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { threadID } = this.props;
      let { currentPage, pageSize, filters } = this.state;

      const result = await GetThreadComments(threadID, currentPage, pageSize, filters);
      const usersList = await GetUsersList();

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
        usersList: usersList?.data || []
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleOnPressRow = (record) => {
    const { history, forumID, threadID } = this.props;
    history.push(`/forum/${forumID}/${threadID}/${record._id}`);
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  removeComment = async (data) => {
    try {
      await DeleteComment(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  validateComment = async (data) => {
    try {
      await ValidateComment(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  renderState = (value) => {
    return (
      <StateLabel
        bgColor={value ? '#cf1322' : '#389e0d'}
      >
        {value ? 'Não Validado' : 'Validado'}
      </StateLabel>
    );
  }

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
      usersList
    } = this.state;

    return (
      <React.Fragment>
        <CommentsFilters queryChange={this.handleFilterChange} usersList={usersList}/>
        <Table
          columns={columns}
          currentPage={currentPage}
          pageSize={pageSize}
          loading={loading}
          rows={rows}
          showHeader={true}
          emptyIcon='warning'
          emptyText={'Não existem Comentários inseridos!'}
          total={total}
          rowKey={'_id'}
          hasPagination={true}
          onPressRow={this.handleOnPressRow}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default withRouter(connect(mapStateToProps, null)(CommentsList));