import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetBlock,
  UpdateBlock,
  CreateBlock,
  GetInstrument
} from '../../../infra/requests/AboutInstrumentsRequests';
import ImageInput from '../../../components/generic/inputs/ImageInput';
import FilesInput from '../../../components/generic/inputs/FilesInput';
import SelectInput from '../../../components/generic/inputs/SelectInput';
import VideoInput from '../../../components/generic/inputs/VideoInput';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab,
  Separator
} from '../../../styles/BasicStyles';
import FormValidator from '../../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../../infra/services/formdata/TransformToFormData';
import DraftInput from '../../../components/generic/inputs/DraftInput';
import { GetTranslationDescription } from '../../../infra/services/translations/AvailableTranslations';
import { connect } from 'react-redux';
import { Types } from '../../../infra/utils/ThemesBlocks.js';
const TabPane = Tabs.TabPane;

const FormValidations = values => {
  let errors = {};

  if(parseInt(values?.type) === 1) {
    errors = FormValidator.make({
      type: 'required',
      description: 'required|languages'
    })(values);
  }
  else if(parseInt(values?.type) === 2) {
    errors = FormValidator.make({
      type: 'required',
      image: 'required'
    })(values);
  }
  else if(parseInt(values?.type) === 3) {
    errors = FormValidator.make({
      type: 'required',
      video: 'required'
    })(values);
  }
  else if(parseInt(values?.type) === 4) {
    errors = FormValidator.make({
      type: 'required',
      file: 'required'
    })(values);
  }

  return errors;
};

class ManageBlocksPage extends Component {
  state = { 
    isNew: false, 
    loading: true,
    instrument: undefined,
    showDescription: false,
    showImage: false,
    showVideo: false,
    showFile: false
  };

  componentDidMount = async () => {
    const { match: { params }, dispatch, user, history } = this.props;

    if(user.type === 2) {
      return history.push('/');
    } 

    if(params.id) {
      const result = await GetBlock(params.id);
      dispatch(
        initialize('manage_blocks_form', {
          ...result.data,
        })
      );

      this.setState({ 
        loading: false,
        instrument: result?.data?.instrument,
        showDescription: result?.data?.type === 1 ? true : false,
        showImage: result?.data?.type === 2 ? true : false,
        showVideo: result?.data?.type === 3 ? true : false,
        showFile: result?.data?.type === 4 ? true : false
      });
    } 
    else {
      const result = await GetInstrument(params.instrument);

      this.setState({
        isNew: true,
        loading: false,
        instrument: result?.data
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });

      const { match: { params }, history } = this.props;
      const { instrument } = this.state;

      if(values.image && !values.image.blob) {
        delete values.image;
      }

      const payload = FlattenToFormData({ ...values, instrument: instrument?._id });
      const { success } = params.id ? await UpdateBlock(params.id, payload) : await CreateBlock(payload);

      if(success) return history.push(`/website/about/instruments/${instrument?._id}?blocks=true`);
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    const { instrument } = this.state;

    return history.push(`/website/about/instruments/${instrument?._id}?blocks=true`);
  };

  checkType = (value) => {
    if(parseInt(value) === 1) {
      this.setState({ 
        showDescription: true,
        showImage: false,
        showVideo: false,
        showFile: false
      });
    }
    else if(parseInt(value) === 2) {
      this.setState({ 
        showDescription: false,
        showImage: true,
        showVideo: false,
        showFile: false
      });
    }
    else if(parseInt(value) === 3) {
      this.setState({ 
        showDescription: false,
        showImage: false,
        showVideo: true,
        showFile: false
      });
    }
    else if(parseInt(value) === 4) {
      this.setState({ 
        showDescription: false,
        showImage: false,
        showVideo: false,
        showFile: true
      });
    }
  }

  render() {
    const { handleSubmit, pristine, activeLanguage, languages } = this.props;
    const { instrument, isNew, loading, showDescription, showImage, showVideo, showFile } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Sobre', 'Instrumentos', instrument?.title?.pt, 'Blocos', title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row>
              <Row gutter={24}>
                <Col xs={12} offset={0}>
                  <Field
                    component={SelectInput}
                    name={'type'}
                    label={'Tipo *'}
                    placeholder={'Escolha o tipo de bloco'}
                    type='select'
                    data={Types}
                    dataKey={'_id'}
                    dataLabel={'name'}
                    execAfterChange={this.checkType}
                  />
                </Col>
              </Row>
              <Separator/>
              {
                showDescription &&
                <Row gutter={24} style={{ marginTop: '20px' }}>
                  <Col xs={12} offset={0}>
                    <DefaultLanguageTab>
                      {GetTranslationDescription(activeLanguage?.code)}
                    </DefaultLanguageTab>
                    <Field
                      component={DraftInput}
                      name={`description.${activeLanguage?.code}`}
                      label={'Descrição *'}
                      placeholder={'Descrição do Bloco'}
                    />
                  </Col>
                  <Col xs={12} offset={0}>
                    <Tabs>
                      {languages
                        .filter((x) => !x.active)
                        .map((language) => (
                          <TabPane
                            tab={GetTranslationDescription(language.code)}
                            key={language.code}
                          >
                            <Field
                              component={DraftInput}
                              name={`description.${language.code}`}
                              label={'Descrição *'}
                              placeholder={'Descrição do Bloco'}
                            />
                          </TabPane>
                        ))}
                    </Tabs>
                  </Col>
                </Row>
              }
              {
                showImage &&
                <Row gutter={24}>
                  <Col xs={12} offset={0}>
                    <Field
                      component={ImageInput}
                      name='image'
                      label='Imagem *'
                      ratio={0.8}
                      noCrop={true}
                    />
                  </Col>
                </Row>
              }
              {
                showVideo &&
                <Row gutter={24}>
                  <Col xs={12}>
                    <Field
                      component={VideoInput}
                      name='video'
                      type='text'
                      label='URL do Vídeo *'
                      sublabel='Pré-visualizar'
                      placeholder='Link para Youtube ou Vimeo'
                    />
                  </Col>
                </Row>
              }
              {
                showFile &&
                <Row gutter={24}>
                  <Col xs={24} offset={0}>
                    <Field
                      component={FilesInput}
                      name='file'
                      label='Ficheiro Áudio *'
                      accept={'audio/mpeg, audio/wav'}
                      acceptText='Formato(s) permitido(s): .mp3, .wav'
                    />
                  </Col>
                </Row>
              }
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageBlocksPage = reduxForm({
  form: 'manage_blocks_form',
  validate: FormValidations,
})(ManageBlocksPage);

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withLocalize(connect(mapStateToProps, null)(ManageBlocksPage));