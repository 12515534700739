import React, { Component } from 'react';
import Table from '../../../components/generic/table/Table';
import { withRouter } from 'react-router-dom';
import { Popconfirm, Icon } from 'antd';
import {
  HeaderTitle,
  HeaderContainer,
  HeaderButtonsContainer,
  TableButton
} from '../../../styles/BasicStyles';
import {
  GetIntro,
  DeleteMotto
} from '../../../infra/requests/AboutRequests';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import BaseButton from '../../../components/generic/buttons/BaseButton';

const id = '604b7460bf4da172c732689c';

class MottosList extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Título',
        dataIndex: 'title',
        render: (value) => TranslateValue(value),
        width: '70%'
      },
      {
        title: 'Ações',
        render: (data) => (
          <React.Fragment>
            <TableButton error onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement='topRight'
                title='Tem a certeza que quer remover este Lema?'
                onConfirm={() => this.removeMotto(data)}
              >
                <Icon style={{ marginRight: 10 }} type='delete' />
                {'Remover'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        ),
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const result = await GetIntro(id);
      const rows = result?.data?.mottos || [];
      const total = result?.data?.mottos?.length || 0;

      this.setState({
        rows,
        total
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/website/about/programs/mottos/${record._id}`);
  };

  removeMotto = async (data) => {
    try {
      await DeleteMotto(id, data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { history } = this.props;
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize
    } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>&nbsp;</HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/website/about/programs/mottos/add')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <Table
          columns={columns}
          currentPage={currentPage}
          pageSize={pageSize}
          loading={loading}
          rows={rows}
          showHeader={true}
          emptyIcon='warning'
          emptyText={'Não existem Lemas inseridos!'}
          total={total}
          rowKey={'_id'}
          hasPagination={false}
          onPressRow={this.handleOnPressRow}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(MottosList);